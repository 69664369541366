import { createSlice } from "@reduxjs/toolkit";

const initialState = {};

const helperSlice = createSlice({
  name: "helper",
  initialState,
  reducers: {
    updateHelper: (state, { payload }) => {
      console.log(payload);
      return {
        ...state,
        ...payload,
      };
    },
  },
});

export const { updateHelper } = helperSlice.actions;
export default helperSlice.reducer;
