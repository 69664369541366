import React from "react";
import Sidebar from "../components/Sidebar";
import { MdOutlineVerified } from "react-icons/md";

const Getverify = () => {
  return (
    <div className="relative text-white z-10  min-h-[100vh] backdrop-brightness-50">
      <div>
        <Sidebar />
      </div>
      <div className="ml-[50px] md:ml-[90px] p-2 md:p-5 px-2 lg:px-10 text-lg md:text-2xl space-y-5 text-justify">

        <div className="flex flex-row justify-between items-center ">
          <h3 className="text-[#f66b12] text-2xl font-semibld">
            Get UB Verified
          </h3>
          <MdOutlineVerified fill="#f66b12" size={50} />
        </div>

        <div className=" px-2 md:px-10">
          <ul className="list-disc">
            <li className="">
              At Unknown Bazaar, we are committed to providing our users with a
              curated selection of high-quality brands. To ensure the
              authenticity and excellence of the brands listed on our platform,
              we have implemented a thorough verification process.{" "}
            </li>
            <li>
              The verification process aims to establish trust, credibility, and
              reliability between the brand, our platform, and our customers.
            </li>
          </ul>
        </div>
        <p>To be listed on Unknown Bazaar and receive our verification badge, brand owners must fulfill the below:</p>
        <div className="px-2 md:px-10">
          <ol className="list-decimal ">
            <li>The brand must send one of its product to UB.</li>
            <li>Our team will conduct a basic review of the product's quality, craftsmanship, materials, packaging, etc. from customers POV.</li>
            <li>Post passing the review a brand will be provided by UB Verified badge.</li>
          </ol>
        </div>
        <p>We aim to provide customers with the best brands that focus on customer satisfaction. Hence delivering an exceptional customer experience.</p>
      </div>
      
    </div>
  );
};

export default Getverify;
