import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

// export const data = {
//   labels: ["Male", "Female", "Others"],
//   datasets: [
//     {
//       label: "% of user",
//       data: [25, 19, 30],
//       backgroundColor: ["#FF1616", "#F66B12", "#D44000"],
//       borderColor: ["#D44000", "#D44000", "#D44000"],
//       borderWidth: 1,
//     },
//   ],
// };

const GenderPieChart = ({ graphData }) => {
  let obj = graphData?.find((o) => o?.male || o?.female);
  const data = {
    labels: ["Male", "Female"],
    datasets: [
      {
        label: "% of user",
        data: [obj?.male, obj?.female],
        backgroundColor: ["#FF1616", "#F66B12", "#D44000"],
        borderColor: ["#D44000", "#D44000", "#D44000"],
        borderWidth: 1,
      },
    ],
  };
  return (
    <>
      <Pie data={data} />
    </>
  );
};

export default GenderPieChart;
