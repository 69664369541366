import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./css/main.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store, { persistor } from "./store";
import { PersistGate } from "redux-persist/integration/react";
import { ThemeProvider, createTheme } from "@mui/material";
import { injectStore } from "./helper";
injectStore(store);
const root = ReactDOM.createRoot(document.getElementById("root"));
const theme = createTheme({
  typography: {
    fontFamily: "Gotham", // Replace with your desired font family
  },
  palette: {
    mode: "dark",
    primary: {
      main: "#f66b12",
    },
    // text: {
    //   primary: "#FFFFFF", // Set the text color to white
    // },
  },
  components: {
    MuiDialog: {
      styleOverrides: {
        root: {
          borderRadius: 32,
        },
      },
    },
    // MuiPaper: {
    //   styleOverrides: {
    //     root: {
    //       borderRadius: 32, // Adjust the border radius as needed
    //     },
    //   },
    // },
    MuiButton: {
      styleOverrides: {
        // root: {
        //   textTransform: "none",
        //   borderRadius: 18,
        // },
        root: ({ ownerState }) => ({
          textTransform: "none",
          borderRadius: 8,
          ...(ownerState.variant === "contained" && {
            color: "#fff",
          }),
        }),
      },
    },
  },
});
// console.log(theme);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <App />
          </PersistGate>
        </Provider>
      </ThemeProvider>
    </BrowserRouter>
  </React.StrictMode>
);
reportWebVitals();
