import { createSlice } from "@reduxjs/toolkit";
import { login, registerUser, verifyOtp } from "../auth/authAction";

const initialState = {
  // loading: false,
  // OTP: {},
  // error: null,
  // success: false,
  // otpSuccess: false,
  // phone: null,
  user: {},
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout: (state) => {
      // Cookies.remove('OTPStatus') // deletes token from storage
      state.loading = false;
      state.userInfo = null;
      state.OTP = null;
      state.error = null;
    },

    setCredentials: (state, { payload }) => {
      state.userInfo = payload;
    },
    spreadUserData: (state, { payload }) => {
      return { ...state, ...payload };
    },
  },
  extraReducers: (builder) => {
    builder
      // login - otp verify
      // .addCase(verifyOtp.pending, (state) => {
      //   state.loading = true;
      //   state.error = null;
      // })
      // .addCase(verifyOtp.fulfilled, (state, action) => {
      //   console.log(action.payload);
      //   state.loading = false;
      //   state.otpSuccess = true;
      //   state.user = action?.payload;
      // })
      // .addCase(verifyOtp.rejected, (state, action) => {
      //   state.loading = false;
      //   state.error = action.payload;
      // })

      //phone number login page function
      .addCase(login.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true; //registration
      })
      .addCase(login.rejected, (state, action) => {
        console.log(action?.payload);
        state.loading = false;
        state.error = action.payload;
      })

      // register
      .addCase(registerUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(registerUser.fulfilled, (state, action) => {
        console.log(action?.payload);
        state.loading = false;
        state.success = true; //registration
        state.user = action?.payload;
      })
      .addCase(registerUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});
const { actions, reducer } = authSlice;
export const { spreadUserData } = actions;
export default reducer;
