import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Logo from "../images/logo.png";
import Offer from "../images/offer.png";
import { Link } from "react-router-dom";
import { BsCaretRight } from "react-icons/bs";
import { IconButton, Stack } from "@mui/material";
import { Close } from "@mui/icons-material";

const ReloadModal = ({ open, onRedirect, onClose, link }) => {
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState("md");
  return (
    <Dialog
      open={open}
      disableBackdropClick={true}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      sx={{
        // Custom styles for the dialog
        width: "100%", // Adjust the width as needed
        maxHeight: "90%", // Adjust the maximum height as needed
      }}
      className=""
      onClose={() => onClose && onClose()}
    >
      <DialogContent className="bgi text-white backdrop-brightness-40  border-solid border-[3px] border-orange-500 ">
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <img src={Logo} alt="" width={100} height={100} />
          <IconButton onClick={() => onClose && onClose()}>
            <Close />
          </IconButton>
        </Stack>

        <div className="flex max-md:flex-col-reverse">
          <div className=" flex-1 justify-start ">
            <h3 className="font-bold text-3xl mt-3">
              The only homegrown brand directory in the country.
            </h3>
            <ul className="mt-6 text-2xl font-medium">
              <li className="flex items-center ">
                <BsCaretRight color="#f4680b" size={30} /> More Brand visibility
              </li>
              <li className="flex items-center">
                <BsCaretRight color="#f4680b" size={30} />
                Zero commission
              </li>
              <li className="flex items-center">
                <BsCaretRight color="#f4680b" size={30} />
                Limited competition
              </li>
              <li className="flex items-center">
                <BsCaretRight color="#f4680b" size={30} />
                More earning
              </li>
              <li className="flex items-center">
                <BsCaretRight color="#f4680b" size={30} />
                Improves SEO ranking
              </li>
              <li className="flex items-center">
                <BsCaretRight color="#f4680b" size={30} />
                And many more....
              </li>
            </ul>
            <Link to={link || "/facts"} onClick={onClose}>
              <div
                className="ml-[40px] flex justify-center
                                 border-solid border-[3px]
                                 border-orange-500 w-[200px] leading-3 mt-[40px] 
                                 rounded-lg hover:bg-orange-600 hover:shadow-inner max-md:m-[10px] "
              >
                <button className="p-2 font-medium text-xl text-center">
                  {" "}
                  Sign up{" "}
                </button>
              </div>
            </Link>
          </div>
          <div className="">
            <img
              className="mt-[40px] ml-4"
              src={Offer}
              alt=""
              width={500}
              height={500}
            />
          </div>
        </div>
        <h5 className="flex-1 text-sm">
          *Free for 3 months. Can be extended/discountinued after 3 months.{" "}
        </h5>
      </DialogContent>
    </Dialog>
  );
};

export default ReloadModal;
