import React from "react";
import ub3 from "../../images/ub3.png";

const Reason = () => {
  return (
    <>
      <section className="py-[50px] flex mx-[40px] max-lg:gap-[20px] max-lg:flex-col-reverse max-md:mx-[20px] ">
        {/* <div className=""> */}
        <img
          src={ub3}
          className=" max-lg:max-w-[80%] max-xl:max-w-[100%] max-xl:h-[100%] max-xl:mr-[20px]"
          alt=""
          style={{ maxHeight: 512 }}
        />
        {/* </div> */}
        <div>
          <h3 className="hero-text1 w-fit">
            Why Unknown Bazaar?
            {/* <br />  */}
          </h3>
          <p className="text-white text-[18px] font-medium text-justify w-[90%] max-md:w-full ">
            Unknown Bazaar is on a mission to promote homegrown businesses and
            entrepreneur.
            <br />
            <br />
            Unknown Bazaar helps consumers find unique alternatives to
            mass-produced goods under one roof hassle free. It cultivates a
            sense of community by connecting consumers with passionate
            entrepreneurs & artisans who tend to get overlooked in the digital
            congestion.
            <br />
            <br />
            Embrace the freshness & break the barrier of algorithms. Reach the
            customers directly & showcase your brand one on one.
          </p>
        </div>
      </section>
    </>
  );
};

export default Reason;
