import React, { useState, useEffect } from "react";
import Sidebar from "../components/Sidebar";
import ImageUploader from "../components/Imageupload";
import { MdOutlineVerified } from "react-icons/md";
import { BsGlobe, BsThreeDots } from "react-icons/bs";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import Cookies from "js-cookie";
import dummyimage from "../images/Dummyplaceholder.png";
import "../css/imageupload.css";
import { AiOutlinePlus } from "react-icons/ai";
import { RxCross1 } from "react-icons/rx";
import axios from "axios";
import { toast } from "react-toastify";
import Verified from "../images/verified.png";
import {
  apiCall,
  formatDate,
  getSellerId,
  getToken,
  getUrl,
  imageUrl,
  stripHtmlTags,
} from "../helper";
import { useDispatch, useSelector } from "react-redux";
import { updateHelper } from "../features/helper/helperSlice";
import { spreadUserData } from "../features/auth/authSlice";
import HeroImage from "./HeroImage";
import ImagePicker from "../components/ImagePicker";
import { updateUser } from "../features/auth/userSlice";

const Dashboardhome = () => {
  // const cookieData = JSON.parse(Cookies.get("cookieData"));
  const [logo, setLogo] = useState("");
  const [selectedFile, setSelectedFile] = useState("");
  const navigate = useNavigate();
  const cookieData = useSelector((state) => {
    if (!state?.auth?.user) return {};
    return state?.auth?.user;
  });
  const [textArea, setTextArea] = useState(cookieData?.aboutBrand || "");

  const [accountData, setAccountData] = useState({
    name: "",
    emailId: "",
    phoneNo: "",
    subscription: "3 Months",
  });
  const dispatch = useDispatch();
  let getLogin = async () => {
    try {
      let res = await apiCall({
        endpoint: "auth/loginSeller",
        method: "POST",
        data: {
          phoneNumber: cookieData?.phoneNumber,
        },
      });
      console.log(res);
      if (!res?.error) {
        dispatch(
          spreadUserData({
            user: res?.data?.data || {},
          })
        );
        const inputDate = new Date(res?.data?.data?.activeDate);
        const currentDate = new Date();
        if (inputDate < currentDate) {
          dispatch(updateUser({ user: {} }));
          dispatch(spreadUserData({ user: {} }));
          navigate("/join-us");

          dispatch(
            updateHelper({
              globalToast: {
                show: true,
                message: "Your plan is expire!",
                type: "error",
              },
            })
          );
        }
      }
    } catch (error) {}
  };
  useEffect(() => {
    const token = getToken();
    const id = getSellerId();
    getLogo(token, id);
    getLogin();
  }, []);
  useEffect(() => {
    setTextArea(cookieData?.aboutBrand);

    return () => {};
  }, [cookieData?.aboutBrand]);

  const getLogo = async (token, id) => {
    let url = getUrl();
    var result = await axios.post(
      `${url}/seller/getLogoBySellerId`,
      { sellerId: id },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    var result = await result?.data;
    if (result?.data?.[0]?.logoImage !== null) {
      const imageBlob = `${imageUrl()}${result.data[0].logoImage} `;
      setLogo(imageBlob);
    } else {
      setLogo(
        "https://icon-library.com/images/no-image-icon/no-image-icon-0.jpg"
      );
    }
  };

  const postLogo = async (e) => {
    e.preventDefault();
    const token = getToken();
    const id = getSellerId();

    if (selectedFile) {
      const formData = new FormData();
      // console.log(logo , cookieData.id,c)
      formData.append("image", selectedFile);
      formData.append("sellerId", id);
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`, // Add any authorization headers if needed
        },
      };
      const API = "seller/addLogo";
      const HOST = getUrl();
      const url = `${HOST}/${API}`;
      console.log(selectedFile, id, token, formData);
      const { data: res } = await axios.post(url, formData, config);
      if (!res?.error) {
        dispatch(
          updateHelper({
            globalToast: {
              show: true,
              message: "Logo update Successfully!",
              type: "success",
            },
          })
        );
      } else {
        dispatch(
          updateHelper({
            globalToast: {
              show: true,
              message: "Please Select Image!",
              type: "error",
            },
          })
        );
      }
      console.log(res);
      var result = await res.data;
    } else {
      dispatch(
        updateHelper({
          globalToast: {
            show: true,
            message: "Please Select Image!",
            type: "error",
          },
        })
      );
    }
  };

  function changeHandler(event) {
    const { name, value, checked, type } = event.target;
    setAccountData((prevFormData) => {
      return {
        ...prevFormData,
        [name]: type === "checkbox" ? checked : value,
        // [event.target.name]:event.target.value
      };
    });
  }

  const onFileChange = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);
    setLogo(URL.createObjectURL(file));
  };

  const handleabout = async () => {
    const formData = new FormData();
    const token = getToken();
    const id = getSellerId();
    formData.append("sellerId", id);
    formData.append("about", stripHtmlTags(textArea));
    try {
      const response = await axios.post(
        `${getUrl()}/seller/addAboutBrand`,
        formData,
        {
          headers: {
            "Content-Type": "application/json ",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data.error) {
        console.error("Error uploading video:", response.data.message);
      } else {
        dispatch(
          updateHelper({
            globalToast: {
              show: true,
              message: "About Brand Updated Successfully!",
              type: "success",
            },
          })
        );
        // Video uploaded successfully, update the video list
      }
    } catch (error) {
      console.error("Error uploading video:", error);
      dispatch(
        updateHelper({
          globalToast: {
            show: true,
            message: "Internal Server Error",
            type: "error",
          },
        })
      );
    }
  };

  return (
    <>
      <div className=" relative text-white z-10  min-h-[100vh] backdrop-brightness-50">
        <div className="">
          <Sidebar page="" />
        </div>
        <div className=" ml-[50px] md:ml-[90px] p-2 md:p-5 flex flex-col md:flex-row gap-2 px-auto ">
          {/* left parrt */}
          <div className="w-full md:w-[70%] gap-5 flex flex-col">
            {/* Brand Name */}
            <h3 className="p-1">
              Hello,{" "}
              <span className="underline font-semibold">
                {/* Brand Name! */}
                {cookieData?.brandName}
              </span>{" "}
            </h3>

            {/* Brand Logo */}
            <div>
              <div className="p-1 rounded-[100%] w-[180px] h-[150px] add-image-label">
                <label className="add-image-label">
                  <input
                    type="file"
                    accept="image/*"
                    multiple
                    onChange={onFileChange}
                    style={{ display: "none" }}
                  />

                  <span className="add-image-icon ">
                    <img
                      className="add-image-icon p-2"
                      src={
                        logo
                          ? logo
                          : "https://icon-library.com/images/no-image-icon/no-image-icon-0.jpg"
                      }
                    />
                  </span>
                </label>
              </div>
              <div className="p-3">
                <button
                  className="p-3 bg-[#f66b12] text-white uppercase"
                  onClick={postLogo}
                >
                  upload Logo
                </button>
              </div>
            </div>

            {/* {Brand Verification and website} */}
            <div className="mt-6 grid md:grid-cols-2 max-lg:grid-cols-1 gap-10 px-2">
              <div className="bg-[#4B5563] rounded-[14px] p-5 md:p-8 space-y-6 ">
                <div
                  className="flex flex-row justify-between "
                  style={{ alignItems: "center" }}
                >
                  <h3 className="text-lg font-semibold">Get UB Verified</h3>
                  {cookieData.isVerified ? (
                    <img
                      src={Verified}
                      // height={40}
                      width={80}
                      style={{
                        objectFit: "cover",
                        // height: "40px",
                        // width: "82px",
                      }}
                    ></img>
                  ) : (
                    ""
                  )}
                </div>
                <div>
                  <i className="underline">
                    <Link to="/getverify">
                      {cookieData.isVerified
                        ? " VERIFIED  "
                        : "click here to verify"}
                    </Link>
                  </i>
                </div>
              </div>
              <div className="bg-[#4B5563]  rounded-[14px] p-5 md:p-8 space-y-4">
                <div className="flex flex-row justify-between">
                  <h3 className="text-lg font-semibold">Webiste:</h3>
                  <BsGlobe size={28} />
                </div>
                <div>
                  <p className="underline break-all">
                    <a
                      href={`https://${cookieData.websiteLink}`}
                      target="_blank"
                      className="text-blue-400"
                    >
                      {cookieData.websiteLink
                        ? `https://${cookieData.websiteLink}`
                        : "null"}
                    </a>
                  </p>
                </div>
              </div>
            </div>

            {/* Image Uploader */}
            <div className="p-2">
              <p>
                Upload Images:{" "}
                <span className="opacity-40">
                  Max 10 images or 8 images and 2 videos can be uploaded
                </span>
              </p>
              <div className=" ">
                <ImageUploader />
              </div>
            </div>
            <div className="p-2">
              <p>
                Upload Hero Image:{" "}
                <span className="opacity-40">Add One Hero Image</span>
              </p>
              <div className=" ">
                <HeroImage heroImage={true} />
              </div>
            </div>
          </div>

          {/* right part */}
          <div className=" w-full md:w-[30%] flex flex-col gap-5 px-2">
            {/* ?button to save */}

            {/* Account Info */}
            <div className="flex flex-col border-2 border-[#f66b12] p-3 rounded-md">
              <form action="">
                <div className="flex flex-row justify-between">
                  <h3 className="text-lg font-semibold">Account Info</h3>
                  <BsThreeDots
                    size={24}
                    className="cursor-pointer"
                    onClick={() => navigate("/setting/company-information")}
                  />
                </div>
                <div className="space-y-4">
                  <div className="flex flex-col md:flex-row justify-center md:items-center gap-2 px-2">
                    <label htmlFor="name" className="text-lg mr-1">
                      Name:{" "}
                    </label>
                    <input
                      type="text"
                      className="bg-transparent	 hover:bg-[#4B5563] outline-1 border-1 border-white rounded-lg py-1 px-2  cursor-pointer"
                      value={cookieData.sellerName}
                      onChange={changeHandler}
                      name="name"
                    />
                  </div>

                  <div className="flex flex-col md:flex-row justify-center md:items-center gap-2 px-2">
                    <label htmlFor="phoneNo" className="text-lg mr-1 ">
                      Phone_No:
                    </label>
                    <input
                      type="text"
                      className=" bg-transparent	 hover:bg-[#4B5563] outline-1 border-1 border-white rounded-lg py-1 px-2 cursor-pointer"
                      value={cookieData.phoneNumber}
                      onChange={changeHandler}
                      name="phoneNo"
                    />
                  </div>

                  <div className="flex flex-col md:flex-row justify-center md:items-center gap-2 px-2">
                    <label htmlFor="emailId" className="text-lg mr-1">
                      Email_ID:{" "}
                    </label>
                    <input
                      type="email"
                      className="bg-transparent	 hover:bg-[#4B5563] outline-1 border-1 border-white rounded-lg py-1 px-2 cursor-pointer"
                      value={cookieData.email}
                      onChange={changeHandler}
                      name="emailId"
                    />
                  </div>

                  <div className="flex flex-col md:flex-row justify-center md:items-center gap-2 px-2">
                    <label htmlFor="subscription" className="text-lg mr-1">
                      Subscription:{" "}
                    </label>
                    <input
                      type="text"
                      className="bg-transparent	 hover:bg-[#4B5563] outline-1 border-1 border-white rounded-lg py-1 px-2 cursor-pointer"
                      // value={accountData.subscription}
                      value={
                        cookieData?.activeDate &&
                        formatDate(
                          new Date(cookieData?.activeDate),
                          "DD/MMM/YYYY"
                        )
                      }
                      name="subscription"
                      onChange={changeHandler}
                    />
                  </div>
                </div>
              </form>
            </div>

            {/* Write about Something */}
            <div className="flex flex-col border-2 border-[#f66b12] p-3 rounded-md">
              <form action="">
                <div className="flex flex-col ">
                  <h3 className="text-lg font-semibold">
                    Write About Your Brand
                  </h3>
                  <p className="opacity-40">Maximum 450-500 characters </p>
                </div>
                <div className="mt-2">
                  <textarea
                    className="bg-transparent	 hover:bg-[#4B5563] outline-1 border-1 border-white rounded-lg py-1 px-2  cursor-pointer w-full h-[160px]"
                    value={textArea}
                    // value={cookieData.aboutBrand}
                    placeholder="Write here"
                    onChange={(e) => {
                      setTextArea(e.target.value);
                    }}
                  />{" "}
                  <br />
                </div>
              </form>
            </div>
            <div className=" text-end  mr-2">
              <button
                className="bg-[#f66b12] p-[10px] px-[48px] rounded-lg font-semibold"
                onClick={handleabout}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboardhome;
