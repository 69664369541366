import { React, useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import "../css/navbar.css";
import "../css/login.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import CircularProgress from "@mui/material/CircularProgress";
import { login, registerUser } from "../features/auth/authAction";

const Register = () => {
  const { loading, success, user } = useSelector((state) => state.auth);
  const otpData = useSelector((state) => state?.helper?.otpData);
  const [error, setError] = useState("");
  const dispatch = useDispatch();
  const { register, handleSubmit, control } = useForm();
  const navigate = useNavigate();

  // useEffect(() => {
  //   if (user?.id) {
  //     navigate("/company-information");
  //   }
  // }, [navigate, user]);
  console.log(user);
  const submitForm = (data) => {
    // dispatch(login(data))?.then((data) => {
    //   console.log(data?.payload);
    // });
    dispatch(registerUser(data))?.then((data) => {
      console.log(data);
      if (data?.error) setError(data?.payload);
      if (data?.payload?.id) {
        navigate("/company-information");
      }
    });
  };
  return (
    <div className="min-h-[100vh]  backdrop-brightness-50">
      <Navbar pages="login" navText="Sign Up" />
      <div className="login-body ">
        <div className="login-conatiner1">
          <div className="login-container">
            <div className="login-box">
              <form onSubmit={handleSubmit(submitForm)}>
                {error && (
                  <Alert severity="error">
                    <AlertTitle>Error</AlertTitle>
                    {error} <br />
                  </Alert>
                )}
                <label
                  htmlFor="name"
                  className=" text-lg text-white font-montserrat pb-2"
                >
                  Full Name
                </label>
                <input
                  type="text"
                  placeholder="Full Name"
                  className="input-field"
                  name="name"
                  {...register("name")}
                  autoFocus
                  required
                />
                {/* <input
                  type="text"
                  placeholder="Mobile Number"
                  className="input-field"
                  {...register("phoneNumber")}
                  autoFocus
                  required
                  disabled={otpData?.phoneNumber || ""}
                  value={otpData?.phoneNumber || ""}
                  maxLength={10}
                /> */}
                <label
                  htmlFor="name"
                  className=" text-lg text-white font-montserrat pb-2"
                >
                  Phone Number
                </label>
                <Controller
                  name="phoneNumber"
                  control={control}
                  defaultValue={otpData?.phoneNumber || ""}
                  disabled={otpData?.phoneNumber || ""} // Set your default value here
                  render={({ field }) => (
                    <input
                      {...field}
                      type="text"
                      placeholder="Mobile Number"
                      className="input-field"
                      // {...register("phoneNumber")}
                      // autoFocus
                      // required
                      // disabled={otpData?.phoneNumber || ""}
                      // value={otpData?.phoneNumber || ""}
                      // maxLength={10}
                    />
                  )}
                />
                <label
                  htmlFor="name"
                  className=" text-lg text-white font-montserrat pb-2"
                >
                  Email id
                </label>
                <input
                  type="email"
                  placeholder="Email id "
                  className="input-field input-email"
                  {...register("email")}
                  autoFocus
                  required
                />
                <button type="submit" className="login-button">
                  {loading ? <CircularProgress color="success" /> : "Submit"}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
