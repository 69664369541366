import React, { useState, useEffect } from "react";
import Navbar from "../components/Navbar";
import "../css/navbar.css";
import "../css/forms.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { basicInfo } from "../features/profile/profileActions";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import CircularProgress from "@mui/material/CircularProgress";
import Cookies from "js-cookie";
import { getSellerId } from "../helper";
import { category } from "../static";
import {
  Grid,
  MenuItem,
  Select,
  Stack,
  Typography,
  Tooltip,
} from "@mui/material";
import { updateHelper } from "../features/helper/helperSlice";
import Info from "../images/info.png";
// import Select from "react-select";

const BasicInformation = () => {
  let { info } = useParams();
  const { handleSubmit, control, getValues, setValue, watch } = useForm();

  // const categories = [
  //   "Clothing",
  //   "Footwear",
  //   "Accessories",
  //   "Active wear",
  //   "Makeup",
  //   "Skincare",
  //   "Haircare",
  //   "Bath & Body",
  //   "Intimatecare",
  //   "Fragrance",
  //   "Health & Wellness",
  //   "kitchen & dining",
  //   "Furniture",
  //   "Home Décor",
  //   "Home Furnishing",
  //   "Bath",
  //   "Pet Care",
  //   "Baby Care",
  //   "Gifting / customization",
  //   "Food & beverage",
  //   "Service",
  //   "Plus Size Fashion",
  // ];

  // const [selectedCat1, setSelectedCat1] = useState("");
  // const [selectedCat2, setSelectedCat2] = useState("");
  const user = useSelector((state) => state?.auth?.user);
  const [selectedOptions, setSelectedOptions] = useState([]);
  // const formState =

  const handleSelectChange = (event) => {
    const options = event.target.options;
    const selectedValues = [];
    for (let i = 0; i < options.length; i++) {
      if (options[i].selected) {
        selectedValues.push(options[i].value);
      }
    }
    setSelectedOptions(selectedValues);
  };
  useEffect(() => {
    let feildsArray = [
      "serviceType",
      "websiteUrl",
      "instagramUrl",
      "whatsappUrl",
      "filterCategory",
      "onOtherPlatform",
      "otherPlatform",
      "category1Id",
      "category2Id",
    ];
    if (info) {
      for (const key of feildsArray) {
        if (key === "filterCategory") setValue(key, user?.[key]?.split(","));
        else if (key?.includes("Url")) {
          setValue(key, user?.[key?.replace("Url", "Link")]?.split(","));
        } else if (key === "category1Id") setValue("category1", user?.[key]);
        else if (key === "category2Id") setValue("category2", user?.[key]);
        else setValue(key, user?.[key]);
      }
    }

    return () => {};
  }, []);

  // category 1
  // const handleCatChange = (event) => {
  //   const selecteSetCat1 = event.target.value;
  //   setSelectedCat1(event.target.value);
  //   // Dynamically update the selected option's style
  //   const options = event.target.options;
  //   for (let i = 0; i < options.length; i++) {
  //     if (options[i].value === selecteSetCat1) {
  //       options[i].classList.add("selected-year");
  //     } else {
  //       options[i].classList.remove("selected-year");
  //     }
  //   }
  // };
  // category2
  // const handleSubCatChange = (event) => {
  //   const selecteSetCat2 = event.target.value;
  //   setSelectedCat2(event.target.value);
  //   // Dynamically update the selected option's style
  //   const options = event.target.options;
  //   for (let i = 0; i < options.length; i++) {
  //     if (options[i].value === selecteSetCat2) {
  //       options[i].classList.add("selected-year");
  //     } else {
  //       options[i].classList.remove("selected-year");
  //     }
  //   }
  // };

  // basic info function

  const sellerId = getSellerId();
  const [error, setError] = useState("");
  const { loading } = useSelector((state) => state.profile) || {};
  const navigate = useNavigate();
  // useEffect(() => {
  //   if (bsuccess) {
  //     navigate("/select-plan");
  //   }
  // }, [navigate, bsuccess]);
  const dispatch = useDispatch();
  // Initialize react-hook-form
  // console.log(formState, getValues());
  const currentState = getValues();

  const fieldValue = watch("serviceType");

  const onSubmit = (data) => {
    // Include sellerId in formData
    console.log(data);
    // return;
    data.sellerId = sellerId;
    data.filterCategory = data.filterCategory?.join(",");
    if (data.serviceType === "Service") data.category1 = 18;
    // Dispatch the fetchCompanyInfo action with form data
    console.log(data);
    let { whatsappUrl, instagramUrl, websiteUrl } = data;
    if (whatsappUrl || instagramUrl || websiteUrl) {
    } else {
      console.log("error");
      setError("Please enter at one least platform url");
      return;
    }
    // return;
    dispatch(basicInfo({ data, navigate: info ? null : navigate }))?.then(
      (_d) => {
        if (info)
          dispatch(
            updateHelper({
              globalToast: {
                show: true,
                type: "success",
                message: "Basic Info Updated Successfully!",
              },
            })
          );
        console.log(_d);
      }
    );
  };

  return (
    <div className="min-h-[100vh]   ">
      {/* backdrop-brightness-50 */}
      <Navbar pages="information" navText={info ? "" : "List with Us. "} />
      <div className="info-body">
        <div className="info-container">
          <div className="child ">
            <div className="radio-body">
              <div className="radio-container">
                <label
                  className="radio-label"
                  onClick={() =>
                    info && navigate("/setting/company-information")
                  }
                >
                  <input
                    type="radio"
                    name="option"
                    className="custom-radio-btn"
                    disabled
                  />
                  Company <br /> Information
                </label>
                <div className="vertical-line"></div>
                <label className="radio-label">
                  <input
                    type="radio"
                    name="option"
                    className="custom-radio-btn"
                    checked
                    style={{ accentColor: "#f66b12" }}
                  />
                  Basic Information
                </label>
                <div className="vertical-line"></div>
                <label
                  className="radio-label"
                  onClick={() => info && navigate("/setting/select-plan")}
                >
                  <input
                    type="radio"
                    name="option"
                    className="custom-radio-btn"
                    disabled
                  />
                  {info ? "Active" : "Select"} Plan
                </label>
                <div className="vertical-line"></div>
                <label
                  className="radio-label"
                  onClick={() => info && navigate("/setting/facts")}
                >
                  <input
                    type="radio"
                    name="option"
                    className="custom-radio-btn"
                    disabled
                  />
                  Facts
                </label>
              </div>
            </div>
          </div>
          <div className="child form-child">
            <h2 className="form-heading">
              {info ? null : "Help us with basic details about your brand"}
            </h2>
            <div className="child-form" style={{ marginLeft: "20px" }}>
              <form onSubmit={handleSubmit(onSubmit)}>
                {error && (
                  <Alert severity="error">
                    <AlertTitle>Error</AlertTitle>
                    {error} <br />
                  </Alert>
                )}
                <div className="form-row">
                  <Grid container spacing={4}>
                    <Grid item xs={12} md={4} lg={4}>
                      <div className="input-data-basic-info ">
                        <label htmlFor="serviceType" className="input-label">
                          Bussiness Type
                        </label>
                        <Controller
                          name="serviceType"
                          control={control}
                          defaultValue="" // Set your default value here
                          render={({ field }) => (
                            <select
                              {...field}
                              className="dropdown-list"
                              required
                            >
                              <option
                                value=""
                                disabled
                                className="disbaled-option-form"
                              >
                                {" "}
                                Choose one : Service / Product
                              </option>
                              <option value="Service">Service</option>
                              <option value="Product">Product</option>
                            </select>
                          )}
                        />
                      </div>
                    </Grid>
                    {currentState?.serviceType &&
                    currentState?.serviceType === "Product" ? (
                      <>
                        <Grid item xs={12} md={4} lg={4}>
                          <div className="input-data-basic-info ">
                            <label htmlFor="category1" className="input-label">
                              Category 1
                            </label>
                            <Controller
                              name="category1"
                              control={control}
                              defaultValue="" // Set your default value here
                              render={({ field }) => (
                                <select
                                  {...field}
                                  className="dropdown-list"
                                  // value={selectedCat2}
                                  // onChange={handleSubCatChange}
                                  required={!getValues()?.category2}
                                >
                                  <option
                                    value=""
                                    disabled
                                    className="disbaled-option-form"
                                  >
                                    Select a Category 1
                                  </option>
                                  {/* {categories.map((catname1, index) => (
                            <option key={index} value={catname1}>
                              {catname1}
                            </option>
                          ))} */}
                                  {Object.values(category || {})
                                    ?.map((obj) => obj?.options)
                                    ?.flat()
                                    // ?.filter((o) => !o?.isDisabled)
                                    ?.map((obj) => {
                                      let { id, label } = obj;
                                      if (getValues()?.category2 == id)
                                        return null;
                                      // console.log(control?._formState);
                                      return (
                                        <option key={id} value={id}>
                                          {label}
                                        </option>
                                      );
                                    })}
                                </select>
                              )}
                            />
                          </div>
                        </Grid>
                        <Grid item xs={12} md={4} lg={4}>
                          <div className="input-data-basic-info ">
                            <label htmlFor="category2" className="input-label">
                              Category 2
                            </label>
                            <Controller
                              name="category2"
                              control={control}
                              defaultValue="" // Set your default value here
                              render={({ field }) => (
                                <select
                                  {...field}
                                  className="dropdown-list"
                                  // value={selectedCat1}
                                  // onChange={handleCatChange}
                                  // required={!getValues()?.category1}
                                >
                                  <option
                                    value=""
                                    disabled
                                    className="disbaled-option-form"
                                  >
                                    Select a Category 2
                                  </option>
                                  {/* {categories.map((catname2, index) => (
                            <option key={index} value={catname2}>
                              {catname2}
                            </option>
                          ))} */}
                                  {Object.values(category || {})
                                    ?.map((obj) => obj?.options)
                                    ?.flat()
                                    // ?.filter((o) => !o?.isDisabled)
                                    ?.map((obj) => {
                                      let { id, label } = obj;
                                      if (getValues()?.category1 == id)
                                        return null;
                                      return (
                                        <option key={id} value={id}>
                                          {label}
                                        </option>
                                      );
                                    })}
                                </select>
                              )}
                            />
                          </div>
                        </Grid>
                      </>
                    ) : null}
                  </Grid>
                </div>
                <div className="form-row">
                  <Grid container spacing={4}>
                    <Grid item xs={12} md={4} lg={4}>
                      <div className="input-data-basic-info">
                        <label htmlFor="websiteUrl" className="input-label">
                          Website URL
                        </label>
                        <Controller
                          name="websiteUrl"
                          control={control}
                          defaultValue=""
                          render={({ field: { onChange, onBlur, value } }) => (
                            <input
                              // {...field}

                              onBlur={onBlur}
                              onChange={(e) => {
                                setError("");
                                onChange(e);
                              }}
                              value={value}
                              className="input-field-style"
                              type="text"
                            />
                          )}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} md={4} lg={4}>
                      <div className="input-data-basic-info ">
                        <label htmlFor="instagramUrl" className="input-label">
                          Insta Page URL
                        </label>
                        <Controller
                          name="instagramUrl"
                          control={control}
                          defaultValue=""
                          // render={({ field }) => (
                          //   <input
                          //     {...field}

                          //     className="input-field-style"
                          //     type="url"
                          //   />
                          // )}
                          render={({ field: { onChange, onBlur, value } }) => (
                            <input
                              // {...field}

                              onBlur={onBlur}
                              onChange={(e) => {
                                setError("");
                                onChange(e);
                              }}
                              value={value}
                              className="input-field-style"
                              type="text"
                            />
                          )}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} md={4} lg={4}>
                      <div className="input-data-basic-info">
                        <label
                          htmlFor="whatsappUrl"
                          className="input-label flex"
                        >
                          WhatsApp URL
                          <Tooltip
                            title="http://wa.me/your number"
                            placement="top"
                            className="text-[16px]"
                          >
                            <img
                              src={Info}
                              className="ml-2"
                              alt="info"
                              width={18}
                              height={20}
                            />
                          </Tooltip>
                        </label>
                        <Controller
                          name="whatsappUrl"
                          control={control}
                          defaultValue=""
                          // render={({ field }) => (
                          //   <input
                          //     {...field}

                          //     className="input-field-style"
                          //     type="url"
                          //   />
                          // )}
                          render={({ field: { onChange, onBlur, value } }) => (
                            <input
                              // {...field}

                              onBlur={onBlur}
                              onChange={(e) => {
                                setError("");
                                onChange(e);
                              }}
                              value={value}
                              className="input-field-style"
                              type="text"
                            />
                          )}
                        />
                      </div>
                    </Grid>
                  </Grid>
                </div>
                <div className="form-row">
                  <Grid container spacing={4}>
                    <Grid item xs={12} md={4} lg={4}>
                      <div className="input-data-basic-info pt-7 max-lg:pt-3 ">
                        <label htmlFor="filterCategory" className="input-label">
                          Products are fit for
                          {/* <Typography
                            variant="caption"
                            sx={{ display: "inline" }}
                          > */}
                          (Select multiple if applicable)
                          {/* </Typography> */}
                        </label>
                        <Controller
                          name="filterCategory"
                          control={control}
                          defaultValue={[]} // Set your default value here
                          render={({ field }) => (
                            // <select
                            //   {...field}
                            //
                            //   multiple
                            //   className="dropdown-list"
                            //   required
                            // >
                            //   <option
                            //     value=""
                            //     disabled
                            //     className="disbaled-option-form"
                            //   >
                            //     {/* Choose one: Yes/No */}
                            //   </option>
                            //   <option value="male">Male</option>
                            //   <option value="female">Female</option>
                            //   <option value="kids">Kids</option>
                            // </select>
                            <Select
                              sx={{
                                display: "block",
                                mt: 2,
                                ".MuiSelect-outlined": {
                                  color: "#fff",
                                },
                              }}
                              {...field}
                              multiple
                              variant="standard"
                              // options={}
                            >
                              {[
                                { value: "male", label: "Male" },
                                { value: "female", label: "Female" },
                                { value: "kids", label: "Kids" },
                                { value: "pets", label: "Pets" },
                                { value: "Other", label: "Other" },
                              ]?.map((o) => (
                                <MenuItem value={o?.value} key={o?.value}>
                                  {o?.label}
                                </MenuItem>
                              ))}
                            </Select>
                          )}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} md={4} lg={4}>
                      <div className="input-data-basic-info">
                        <label
                          htmlFor="onOtherPlatform"
                          className="input-label"
                        >
                          Do you sell on any other E-commerce platforms
                        </label>
                        <Controller
                          name="onOtherPlatform"
                          control={control}
                          defaultValue="" // Set your default value here
                          render={({ field }) => (
                            <select
                              {...field}
                              className="dropdown-list"
                              required
                            >
                              <option
                                value=""
                                disabled
                                className="disbaled-option-form"
                              >
                                Choose one: Yes/No
                              </option>
                              <option value="Yes">Yes</option>
                              <option value="No">No</option>
                            </select>
                          )}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} md={4} lg={4}>
                      <div className="input-data-basic-info pt-6 max-lg:pt-3 ">
                        <label for="otherPlatform" className="input-label ">
                          Mention Platform
                        </label>
                        <Controller
                          name="otherPlatform"
                          control={control}
                          defaultValue=""
                          render={({ field }) => (
                            <input
                              {...field}
                              className="input-field-style"
                              type="text"
                              placeholder="Please mention platform names"
                            />
                          )}
                        />
                      </div>
                    </Grid>
                  </Grid>
                </div>
                <div className="form-row">
                  <Grid container spacing={4}>
                    <Grid item xs={12} md={4} lg={4}>
                      <div className="input-data-basic-info pt-7 max-lg:pt-3 ">
                        <label htmlFor="filterCategory" className="input-label">
                          How did you hear about us?
                        </label>
                        <input
                          className="input-field-style"
                          type="text"
                          placeholder="Referral person name"
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} md={4} lg={4}>
                      <div className="input-data-basic-info pt-12 max-lg:pt-3 ">
                        <label
                          htmlFor="filterCategory"
                          className="input-label"
                        ></label>
                        {/* <Stack alignItems={"end"}> */}
                        <input
                          className="input-field-style"
                          type="text"
                          placeholder="Referral person number"
                        />
                        {/* </Stack> */}
                      </div>
                    </Grid>
                    <Grid item xs={12} md={4} lg={4}>
                      <div className="input-data-basic-info pt-12 max-lg:pt-3 ">
                        <label
                          htmlFor="filterCategory"
                          className="input-label"
                        ></label>
                        <input
                          className="input-field-style"
                          type="text"
                          placeholder="other"
                        />
                      </div>
                    </Grid>
                  </Grid>
                </div>

                <div className="form-row" style={{ marginLeft: "12px" }}>
                  <Grid item xs={12}>
                    <Stack direction={"row"} alignItems={"centre"} gap={3}>
                      <input
                        type="checkbox"
                        name="terms"
                        className="input-field-style"
                        required
                      />
                      <label for="terms" className="input-label">
                        By ticking, you are confirming that you have read,
                        understood and agree to{" "}
                        <Link className="underline" to="/terms-and-condition">
                          {" "}
                          Terms & conditions*{" "}
                        </Link>
                      </label>
                    </Stack>
                  </Grid>
                </div>

                <button
                  type="submit"
                  className="login-button1"
                  disabled={loading}
                >
                  {loading ? (
                    <CircularProgress color="success" />
                  ) : info ? (
                    "Update"
                  ) : (
                    "Submit"
                  )}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BasicInformation;
