import {
  Badge,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import React, { useRef, useState } from "react";

import { Close } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";

const ImagePicker = ({ open, handleClose, heading, type, getFile }) => {
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [blob, setBlob] = useState(null);
  const [tempFile, setTempFile] = useState(null);
  const videoRef = useRef(null);
  const [fileType, setFileType] = useState(false);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedImage, setCroppedImage] = useState(null);
  const inputRef = useRef(null);
  const onFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setBlob(selectedFile);
    if (selectedFile) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const dataURL = e.target.result;

        const isImage = /\.(jpe?g|png|gif)$/i.test(selectedFile.name); // Add more extensions if needed for images.

        setFile(dataURL);
        setFileType("image"); // Set the flag indicating if the file is a video.

        if (!isImage) {
          setFileType("video");
          setTempFile(selectedFile);
          // For video, you might extract frames or perform other video-related operations.
          // You may want to use a video library or API for these operations.
        }
      };
      reader.readAsDataURL(selectedFile);
    }
  };

  const handleUpload = async () => {
    setLoading(true);
    if (blob) {
      setInterval(() => {}, 2000);
      getFile && (await getFile(blob));
      handleClose && handleClose();
    }
    setLoading(false);
    // if (croppedImage ) {
    //   getFile && getFile(croppedImage);
    //   handleClose && handleClose();
    //   //   const formData = new FormData();
    //   //   formData.append("croppedFile", croppedImage, "croppedFile.jpg"); // Change the filename as needed
    // }
  };

  return (
    <>
      <input
        alt="upload dailog"
        type="file"
        accept={type === "image" ? "image/*" : "image/*,video/*"}
        onChange={onFileChange}
        multiple={false}
        ref={inputRef}
        style={{ display: "none" }}
      />

      <Dialog
        open={open}
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        maxWidth={"xs"}
        fullWidth
      >
        <DialogTitle> Upload You Media Here</DialogTitle>
        <DialogContent>
          {file ? (
            <Stack justifyContent={"center"} alignItems={"center"}>
              <Badge
                badgeContent={
                  <IconButton onClick={() => setFile(null)}>
                    <Close />
                  </IconButton>
                }
                sx={{
                  ".MuiBadge-badge": {
                    top: "10px",
                    right: "10px",
                  },
                }}
              >
                <Stack sx={{ height: 200, width: 200 }}>
                  {/* <Cropper
                    image={fileType === "image" ? file : null}
                    video={fileType === "video" ? file : null}
                    crop={crop}
                    zoom={zoom}
                    aspect={1} // Set the aspect ratio as needed
                    onCropChange={setCrop}
                    onCropComplete={onCropComplete}
                    onZoomChange={setZoom}
                    onImageLoaded={(res) => {
                      console.log(res);
                    }}
                    onMediaLoaded={(re) => {
                      console.log(re);
                    }}
                  /> */}
                  {fileType === "image" ? (
                    <img
                      src={file}
                      style={{
                        objectFit: "contain",
                      }}
                    />
                  ) : fileType === "video" ? (
                    <video controls>
                      <source
                        src={file}
                        type="video/mp4"
                        style={{
                          objectFit: "contain",
                        }}
                      />
                      Your browser does not support the video tag.
                    </video>
                  ) : null}
                </Stack>
              </Badge>
            </Stack>
          ) : (
            <Stack
              sx={{
                minHeight: 200,
                border: `2px dashed`,
                borderRadius: 4,
                cursor: "pointer",
              }}
              justifyContent={"center"}
              alignItems={"center"}
              onClick={() => {
                console.log("clicl");
                inputRef.current.click();
              }}
            >
              <Typography>{heading || "Select Image"}</Typography>
            </Stack>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
          <LoadingButton
            loading={loading}
            onClick={handleUpload}
            variant="contained"
            disabled={!file}
          >
            Upload
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ImagePicker;
