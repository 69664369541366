import React from "react";
import Sidebar from "../components/Sidebar";
import FAQ from "../components/FAQ";
import Download from "../components/Download";

// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const Questions = () => {


  return (
    <div className="relative  z-10  min-h-[100vh] backdrop-brightness-50">
      <div>
        <Sidebar page="question" />
      </div>
      <div className="backdrop-brightness-50  ml-[50px] md:ml-[90px] p-2 md:p-5 space-y-10 px-2 md:px-10">
        <div className="w-full flex justify-center items-center">
          <img
            src={require("../images/logo.png")}
            alt=""
            className=" h-[90px] "
          />
        </div>

        <div className="px-2 md:px-10 text-white">
          <h3 >Contact Us</h3>
          <ul className="type-disc">
            <li>Sales Person Name: </li>
            <li>Sales Person Contact Number:</li>
            <li>Email Add : connect@unknownbazaar.com</li>
          </ul>
        </div>
        <div className="px-2 md:px-10">
          <h2 className="text-[30px] font-bold underline text-[#F46A11]" >FAQ</h2>

          <div className="my-[20px]" >
            <FAQ />
          </div>
          <div className="my-[10px]">
            <Download />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Questions;
