import React, { useRef } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";

import emailjs from "@emailjs/browser";
import { Stack } from "@mui/material";
import { updateHelper } from "../../features/helper/helperSlice";
const YOUR_PUBLIC_KEY = "_dj5PHeFub0QdyVKm";
const YOUR_SERVICE_ID = "service_47ij41y";
const YOUR_TEMPLATE_ID = "template_x0rmbm2";
const ContactForm = () => {
  const { handleSubmit, control, reset } = useForm();
  const form = useRef();
  const dispatch = useDispatch();
  //   const sendEmail = (e) => {
  //     e.preventDefault();
  //     console.log("abc");
  //     emailjs
  //       .sendForm(
  //         YOUR_SERVICE_ID,
  //         YOUR_TEMPLATE_ID,
  //         form.current,
  //         YOUR_PUBLIC_KEY
  //       )
  //       .then(
  //         (result) => {
  //           console.log(result.text);
  //         },
  //         (error) => {
  //           console.log(error.text);
  //         }
  //       );
  //   };

  const sendEmail = async (data) => {
    console.log(data);
    // Use the form data for sending the email
    emailjs
      .sendForm(
        YOUR_SERVICE_ID,
        YOUR_TEMPLATE_ID,
        form.current,
        YOUR_PUBLIC_KEY
      )
      .then(
        (result) => {
          console.log(result.text);
          dispatch(
            updateHelper({
              globalToast: {
                show: true,
                message:
                  "Your Question has been submitted we will revert you back!",
                type: "success",
              },
            })
          );
          // editHelper({
          //   globalSnackbar: {
          //     type: "success",
          //     message:
          //       "Message Send successfully We will revert you back soon!",
          //     open: true,
          //   },
          // })
          reset({}, { errors: true });
        },
        (error) => {
          console.log(error.text);
        }
      );
    // ...
  };

  return (
    <>
      <div className="py-[30px] w-[80%] m-auto ">
        <h3 className="pricing-heading-text py-6 text-center ">
          Have a question?
        </h3>
        <p className="text-white text-[20px]">TELL US ABOUT YOU</p>
        <form ref={form} onSubmit={handleSubmit(sendEmail)}>
          <div className="form-landing  max-md:mx-0">
            <div className="input-data-landing">
              <label htmlFor="websiteUrl" className="text-white text-[18px]">
                Name
              </label>
              <Controller
                name="user_name"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <input
                    type="text"
                    className="input-field-style"
                    placeholder="Fill your name"
                    required
                    {...field}
                  />
                )}
              />
            </div>
            <div className="input-data-landing">
              <label htmlFor="websiteUrl" className="text-white text-[18px]">
                Contact Number
              </label>
              <Controller
                name="contact_number"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <input
                    type="text"
                    className="input-field-style"
                    placeholder="Enter your contact number"
                    required
                    {...field}
                  />
                )}
              />
            </div>
            <div className="input-data-landing">
              <label htmlFor="websiteUrl" className="text-white text-[18px]">
                Email ID
              </label>
              <Controller
                name="user_email"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <input
                    type="text"
                    className="input-field-style"
                    placeholder="Enter your email address"
                    required
                    {...field}
                  />
                )}
              />
            </div>
          </div>
          <div className="my-[100px] flex flex-col max-md:my-[40px] ">
            <label htmlFor="websiteUrl" className="text-white text-[18px]">
              Write your message
            </label>
            <Controller
              name="message"
              control={control}
              defaultValue=""
              render={({ field }) => (
                // <textarea
                //   className="review-text"
                //   rows="7"
                //   cols="120"
                //   placeholder="Enter your message"
                //   required
                //   {...field}
                // />
                <textarea
                  className=" mt-4 bg-transparent text-white hover:bg-[#4B5563] border "
                  id="review"
                  name="review"
                  rows="7"
                  cols="20"
                  placeholder="Enter your message"
                  required
                  {...field}
                ></textarea>
              )}
            />
            {/* <Link>
            {" "}
            <p className="text-white text-[18px] underline font-medium float-right ">
              Get in touch
            </p>{" "}
          </Link> */}
            <Stack
              direction={"row"}
              alignContent={"center"}
              justifyContent={"flex-end"}
              mt={2}
            >
              <button
                type="submit"
                className="get-in"
                style={{
                  color: "#fff",
                  backgroundColor: "transparent",
                  outline: "0",
                  // border: 0,
                }}
              >
                Get in touch
              </button>
            </Stack>
          </div>
        </form>
      </div>
    </>
  );
};

export default ContactForm;
