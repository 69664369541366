import React from "react";
import Navbar from "../components/Navbar";
import "../css/navbar.css";
import "../css/home.css";
import { Link } from "react-router-dom";

const Home = () => {
  return (
    <>
      <Navbar pages="home" navText="" />
      <body className="main-body bgi h-[83vh]">
        <div className="heading">Are you a homegrown* brand?</div>
        <div className="sub-heading">
          *Homegrown - Made in India or assembled in India
        </div>
        <div className="input-button-style">
          <Link to="/join-us">
            {" "}
            <button type="button" className="buttonStyle">
              Yes
            </button>
          </Link>
          <Link to="/no-options">
            {" "}
            <button type="button" className="buttonStyle mobile-button">
              No
            </button>
          </Link>
        </div>
      </body>
    </>
  );
};

export default Home;
