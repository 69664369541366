import React from "react";
import Logo from "../../images/logo.png";
import { Link } from "react-router-dom";
import Apple from "../../images/apple.png";
import Google from "../../images/google.png";
import Website from "../../images/website.png";

const Hero = () => {
  return (
    <>
      <div className="mx-[50px] max-md:mx-[20px] ">
        <div className="image-div">
          <img
            className="pt-7"
            height={150}
            width={150}
            src={Logo}
            alt="unknown-bazaar"
          />
        </div>
        <h2 className="hero-text pb-7 ">Showcase the power of homegrown.</h2>
        <div className="flex flex-row max-lg:flex-col max-lg:gap-[10px]">
          <div className="mt-[20px] flex-1">
            <p className="text-[24px] text-white">
              Expand your customer base and grow your brand. <br />
              An exclusive platform just for Homegrown brands.
            </p>
            <p className="text-[#F66B12] text-[24px] font-bold">
              No Commission | Limited Competition and more...
            </p>
            <Link to="/">
              <button className="bg-[#F66B12] max-md:w-full max-md:my-7 mt-6 px-[60px] py-[10px] rounded-full text-white text-[20px] font-semibold">
                {/* Know More */}
                Register Now
              </button>
            </Link>
          </div>
          <div className="">
            <p className="text-white text-[18px] underline font-bold">
              {/* We are on: */}
              Coming Soon
            </p>
            <div className="mt-[10px]">
              <p className="bg-white flex p-3 rounded-[10px] my-[10px]">
                <img src={Apple} height={50} width={50} alt="" />
                <span className="text-black font-semibold"> App Store</span>
              </p>
              <p className="bg-white flex p-2 rounded-[10px] my-[10px]">
                <img src={Google} height={20} width={30} alt="" />
                <span className="text-black font-semibold pl-1">
                  {" "}
                  Google Play
                </span>
              </p>
              <p className="bg-white flex p-2 rounded-[10px] my-[10px]">
                <img src={Website} height={30} width={30} alt="" />
                <span className="text-black font-semibold pl-2"> Website</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Hero;
