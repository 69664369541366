import React from "react";
import Logo from "../images/logo.png";

const Welcome = () => {
  return (
    <div className="min-h-[100vh]">
      <div className="welcome">
        <div className="image-div">
          <img className="image-style" src={Logo} alt="" />
        </div>
        <div className="welcome-style">
          <h3 className="welcome-text">Welcome to our homegrown family.</h3>
          <h3 className="welcome-text">We value your faith in us.</h3>
          <h3 className="welcome-text1">
            Your brand details are under review with our QC team.
          </h3>
          <p className="end-object">
            Upon any discrepancy with the submission, we will refund your entire
            amount back to the source, if any.**
          </p>
        </div>
      </div>
    </div>
  );
};

export default Welcome;
