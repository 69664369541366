import React from "react";
import { useParams } from "react-router-dom";
import BasicInformation from "./BasicInformation";
import CompanyInformation from "./CompanyInformation";
import Sidebar from "../components/Sidebar";
import Facts from "./Facts";
import SelectPlan from "./SelectPlan";

const Settings = () => {
  let { info } = useParams();
  console.log(info);
  return (
    <div>
      <Sidebar />
      <div style={{ marginLeft: "50px" }}>
        {info === "company-information" && <CompanyInformation />}
        {info === "basic-information" && <BasicInformation />}
        {info === "facts" && <Facts />}
        {info === "select-plan" && <SelectPlan />}
      </div>
    </div>
  );
};

export default Settings;
