import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FiEdit } from "react-icons/fi";
import { AiOutlinePlus } from "react-icons/ai";
import Offer from "../images/offer1.png";
import { fetchBannerImages } from "../features/phone/banner/bannerAction";
import Cookies from "js-cookie";
import { Link } from "react-router-dom";
import { apiCall, getSellerId, imageUrl, isArrayWithValues } from "../helper";
import { Badge, IconButton, Stack } from "@mui/material";
import { updateHelper } from "../features/helper/helperSlice";
import { IoClose } from "react-icons/io5";

const Banner = () => {
  const dispatch = useDispatch();

  const sellerId = getSellerId();
  const [data, setData] = useState([]);
  //   const sellerId = cookieData.id;

  const bannerImages = useSelector(
    (state) => state.bannerImageOffer.bannerImages
  );
  const loading = useSelector((state) => state.bannerImageOffer.loading);
  const error = useSelector((state) => state.bannerImageOffer.error);
  console.log(bannerImages);
  useEffect(() => {
    dispatch(fetchBannerImages(sellerId));
  }, [dispatch, sellerId]);
  useEffect(() => {
    if (isArrayWithValues(bannerImages)) setData(bannerImages);
    return () => {};
  }, [bannerImages]);
  const deleteBanner = async (id) => {
    dispatch(
      updateHelper({
        globalToast: {
          show: true,
          message: "Banner Removed Successfully!",
          type: "success",
        },
      })
    );
    try {
      let res = await apiCall({
        endpoint: "banner/deleteBanner",
        method: "POST",
        data: {
          bannerId: id,
        },
      });
    } catch (error) {
      dispatch(
        updateHelper({
          globalToast: {
            show: true,
            message: "Can't able to delete Banner",
            type: "error",
          },
        })
      );
    }
  };

  if (loading) {
    return <div className="text-white">Loading...</div>;
  }

  if (error) {
    return <div className="text-white">Error: {error}</div>;
  }

  return (
    <>
      <div className="banner-body">
        <h4 className="heading-review"> Upload offer Banner: </h4>
        <div className="new-blog-add-style ">
          <Link to="/banner/new-banner">
            <label className="add-image-label flex-col ">
              <span className="add-image-icon ">
                <AiOutlinePlus />
              </span>
              <p className="text-sm text-[#F66B12] font-bold">Add Banner</p>
            </label>
          </Link>
        </div>

        {/* linking to actual blog -update and write page  */}

        <div className="blogs-read relative ">
          <h4 className="heading-review py-5  "> BLOG list </h4>
          {/* <div className="">
                        <img src={Offer} alt=" banner-image" className='absolute w-full h-full object-contain' />
                    </div> */}
          {data.length === 0 ? (
            <div className="text-slate-400 font-semibold text-xl">
              No Image Available
            </div>
          ) : (
            <Stack direction={"row"} alignItems={"center"} gap={3}>
              {data.map((banner, index) => {
                console.log(banner?.id);
                return (
                  <Badge
                    sx={{
                      ".MuiBadge-badge": {
                        top: "16px",
                        right: "16px",
                      },
                    }}
                    badgeContent={
                      <IconButton
                        onClick={() => {
                          //   setVideosReel((pre) =>
                          //     pre?.filter((o) => o != videoUrl)
                          //   );
                          setData((pre) =>
                            pre?.filter((o) => o?.id != banner?.id)
                          );

                          deleteBanner(banner?.id);
                        }}
                      >
                        <IoClose />
                      </IconButton>
                    }
                    key={index}
                  >
                    <div key={index}>
                      <img
                        src={`${imageUrl()}${banner.bannerImage}`}
                        alt="banner-image"
                        className=" w-full h-full object-contain"
                        style={{ maxWidth: "150px" }}
                      />
                    </div>
                  </Badge>
                );
              })}
            </Stack>
          )}
        </div>
      </div>
    </>
  );
};

export default Banner;
