import React from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

export const options = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top',
        },
        title: {
            display: true,
            text: 'Age Group',
        },
    },
    maintainAspectRatio: false, // Set this to false to allow custom dimensions
    width: 500, // Set the desired width
    height: 250, // Set the desired height

};

const labels = ['18-24', '25-34', '35-44', '45-54', '55-64']; export const data = {
    labels,
    datasets: [
        {
            label: 'Data',
            data: [12, 56, 7, 12, 135],
            borderColor: '#F66B12',
            backgroundColor: 'rgb(246, 107, 18,0.5)',
            fill: false
        },

    ],
};

const LineChart = () => {
    return (
        <>
          
                <Line options={options} data={data} />

        </>
    )
}

export default LineChart
