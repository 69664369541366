import { combineReducers, configureStore } from "@reduxjs/toolkit";
// import otpReducer from './otpSlice';
import authReducer from "./features/auth/authSlice";
import sellerReducer from "./features/phone/review/reviewSlice";
import blogReducer from "./features/phone/blog/blogSlice";
import storage from "redux-persist/lib/storage";
import thunk from "redux-thunk";
import { persistStore } from "redux-persist";
import persistReducer from "redux-persist/es/persistReducer";
import blogTitleReducer from "./features/phone/blog/blogTitleSlice";

import bannerImageOfferReducer from "./features/phone/banner/bannerSlice";
import bannerReducer from "./features/phone/banner/bannerAction";
import helperSlice from "./features/helper/helperSlice";
import profileSlice from "./features/profile/profileSlice";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["auth"],
};
const rootReducer = combineReducers({
  auth: authReducer,
  seller: sellerReducer,
  blog: blogReducer,
  blogTitle: blogTitleReducer,
  bannerImageOffer: bannerImageOfferReducer,
  banner: bannerReducer,
  helper: helperSlice,
  profile: profileSlice,
});
const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk],
});
export const persistor = persistStore(store);
export default store;
