import { createSlice } from "@reduxjs/toolkit";
import { companyInfo, basicInfo } from "./profileActions";

const initialState = {};

const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    updateUser: (state, action) => {
      console.log(action);
      return { ...state, ...(action?.payload || {}) };
    },
  },
  extraReducers: (builder) => {
    builder
      //  company info
      .addCase(companyInfo.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(companyInfo.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
      })
      .addCase(companyInfo.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // basic info
      .addCase(basicInfo.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(basicInfo.fulfilled, (state, action) => {
        state.loading = false;
        state.bsuccess = true;
      })
      .addCase(basicInfo.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});
export default profileSlice.reducer;
export const { updateUser } = profileSlice.actions;
