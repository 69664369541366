import React from 'react';
import '../css/navbar.css';
import Logo from '../images/logo.png'

const Navbar = ({ pages, navText }) => {

    return (
        <>
            <div className="bgi backdrop-brightness-65 ">
                <div className={`navbar ${pages}`}>
                    <img className='logo' src={Logo} alt="unknown-bazzar" />
                    <span className={`${pages} nav-style`} >{navText}</span>
                </div>
            </div>

        </>

    )
}

export default Navbar
