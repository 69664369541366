import React from 'react';
import Logo from '../images/logo.png'

const Loading = () => {
    return (
        <div className='min-h-[100vh]'>
            <div className="loading">
                <div className="image-div">
                    <img className='image-style' src={Logo} alt="" />
                </div>
                <div className="loading-style">
                    <h3 className="loading-text">Great! You are almost there.....</h3>
                </div>
            </div>
        </div>
    )
}

export default Loading
