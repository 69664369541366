import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchSellerReview,
  postReview,
} from "../features/phone/review/reviewAction";
import Cookies from "js-cookie";
import { apiCall, getSellerId, stripHtmlTags } from "../helper";
import { updateHelper } from "../features/helper/helperSlice";
import { IconButton } from "@mui/material";
import { MdDelete } from "react-icons/md";

const Review = () => {
  // const sellerId = cookieData.id;
  const sellerId = getSellerId();
  const dispatch = useDispatch();
  const sellerData = useSelector((state) => state.seller.data);
  const loading = useSelector((state) => state.seller.loading);
  const error = useSelector((state) => state.seller.error);
  const uploading = useSelector((state) => state.seller.uploading);
  const uploadError = useSelector((state) => state.seller.uploadError);
  const [reviewArray, setReviewArray] = useState([]);

  const [reviewText, setReviewText] = useState("");
  const [isTextExceeded, setIsTextExceeded] = useState(false);
  useEffect(() => {
    setReviewArray(sellerData?.data || []);

    return () => {};
  }, [sellerData?.data]);

  // post review

  const handleTextChange = (e) => {
    const newText = e.target.value;
    setReviewText(newText);

    // Check if the text exceeds 200 characters
    if (newText.length > 200) {
      setIsTextExceeded(true);
    } else {
      setIsTextExceeded(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Prepare the review data
    const reviewData = {
      description: stripHtmlTags(reviewText),
      userId: null, // Replace with the actual user ID
      sellerId: sellerId, // Replace with the actual seller ID
    };

    // Dispatch the postReview action to submit the review data
    dispatch(postReview(reviewData)).then((data) => {
      console.log(data);
      setReviewArray(data?.payload || []);
      setReviewText("");
      dispatch(
        updateHelper({
          globalToast: {
            show: true,
            message: "Review Added Successfully",
            type: "success",
          },
        })
      );
      // Reload the page after posting a review
      // window.location.reload();
    });
  };

  // const sellerReview = sellerData.data;
  // console.log(sellerData.data)
  useEffect(() => {
    // Dispatch the action to fetch seller data
    dispatch(fetchSellerReview(sellerId));
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!sellerData) {
    return <div> No data available </div>; // Handle the case when data is not available
  }

  // Now you can safely access sellerData.data
  const deleteReview = async (id) => {
    setReviewArray((pre) => pre?.filter((o) => o?.id != id));
    dispatch(
      updateHelper({
        globalToast: {
          show: true,
          message: "Review Removed Successfully!",
          type: "success",
        },
      })
    );
    try {
      let res = await apiCall({
        endpoint: "review/deleteReview",
        method: "POST",
        data: {
          reviewId: id,
        },
      });
    } catch (error) {
      dispatch(
        updateHelper({
          globalToast: {
            show: true,
            message: "Can't able to delete Review",
            type: "error",
          },
        })
      );
    }
  };
  // Map over the data array and create JSX elements
  const reviews = reviewArray.map((review) => {
    return (
      <p
        key={review.id}
        className="review-style-textarea1"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        {review.description}
        {/* <IconButton
          onClick={() => {
            deleteReview(review?.id);
          }}
        > */}
        <MdDelete
          className="edit-button-style"
          size={"30px"}
          onClick={() => {
            deleteReview(review?.id);
          }}
        />
        {/* </IconButton> */}
      </p>
    );
  });
  console.log(reviews);

  return (
    <>
      <div className="review-body ml-[50px] md:ml-[90px] p-2 md:p-5 flex  gap-2 px-auto ">
        <div className="upload-review-write">
          {uploadError && <div>Error: {uploadError}</div>}
          <h4 className="heading-review">
            Upload Reviews{" "}
            <span className="review-span-style">Maximum - 10 reviews</span>
          </h4>
          <form className="form-style-review" onSubmit={handleSubmit}>
            <div className="child   ">
              <textarea
                className="review-style-textarea hover:bg-[#4B5563] "
                id="review"
                name="review"
                rows="7"
                cols="20"
                placeholder="Write your review...."
                value={reviewText}
                onChange={handleTextChange}
                required
              ></textarea>
              {isTextExceeded && (
                <div className="text-exceeded">
                  Text exceeded 200 characters
                </div>
              )}
            </div>
            <div className="child button-style">
              <button
                className="bg-[#f66b12] px-9 py-3 rounded-lg font-semibold"
                disabled={uploading || isTextExceeded || reviews.length >= 10}
              >
                Upload
              </button>
            </div>
          </form>
        </div>
        <div className="update-review">
          <div>
            <h4 className="heading-review1">Maximum 150 - 200 characters</h4>
            <div className="all-reviews-list">
              {reviews.length == 0 ? (
                <div className="text-slate-400 font-semibold">
                  No review available
                </div>
              ) : (
                reviews
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Review;
