import React, { useEffect, useState } from "react";
import Sidebar from "../components/Sidebar";
import { AiFillHeart } from "react-icons/ai";
import { BsFillLightningChargeFill } from "react-icons/bs";
import { IoIosPeople } from "react-icons/io";
import LineChart from "../components/LineChart";
import CategoryChart from "../components/CategoryChart";
import GenderPieChart from "../components/GenderPieChart";
import { useSelector } from "react-redux";
import {
  apiCall,
  formatSecondsToMinutesSeconds,
  getToken,
  isArrayWithValues,
} from "../helper";
import { category } from "../static";
import CategoryGraph from "../components/CategoryGraph";

const Graph = () => {
  const user = useSelector((state) => state?.auth?.user);
  console.log(user);
  const [graphData, setGraphData] = useState([]);
  const [topCards, setTopCards] = useState({});
  const [categoryTraffic, setCategoryTraffic] = useState([]);

  const setUpData = async () => {
    try {
      let res = await apiCall({
        endpoint: "brand/getChartData",
      });
      if (!res?.error && res?.data) {
        setGraphData(res?.data);
      }

      console.log(res);
    } catch (error) {
      console.log(error);
    }
  };

  const getCategoryAna = async () => {
    const res = await apiCall({
      endpoint: `googleAnalytics`,
      method: "POST",
      data: {
        dimensions: [{ name: "pagePath" }],
        metrics: [{ name: "screenPageViews" }, { name: "totalUsers" }],
        dateRanges: [
          {
            startDate: "2020-03-31",
            endDate: "today",
          },
        ],
        dimensionFilter: {
          filter: {
            stringFilter: { matchType: "BEGINS_WITH", value: "/ca" },
            fieldName: "pagePath",
          },
        },
        metricAggregations: ["TOTAL"],
      },
    });
    if (isArrayWithValues(res)) {
      console.log(res);
      let catTraffic = 0;
      let cats = Object.values(category)
        ?.map((o) => o?.options)
        ?.flat();
      let catArr = res?.map((o) => {
        let id = o?.dimensionValues?.[0]?.value
          ?.split("/")?.[2]
          ?.split("_")?.[0];

        let name = cats?.find((o) => o?.id == id)?.label;
        let trafficCount = o?.metricValues?.[0]?.value;
        catTraffic += Number(trafficCount);
        return { name, trafficCount };
      });
      // setGraphData(catArr?.slice(0, 9));
      setTopCards((pre) => ({ ...pre, catTraffic }));
      setCategoryTraffic(catArr?.slice(0, 9));
      console.log(catArr, catTraffic);
    }
  };
  const getTotalViewPage = async () => {
    let res = await apiCall({
      endpoint: `googleAnalytics`,
      method: "POST",
      data: {
        dateRanges: [
          {
            startDate: "2020-03-31",
            endDate: "today",
          },
        ],
        metrics: [
          { name: "screenPageViews" },
          {
            name: "totalUsers",
          },
        ],
      },
    });
    if (isArrayWithValues(res)) {
      setTopCards((pre) => ({
        ...pre,
        totalTraffic: res?.[0]?.metricValues?.[0]?.value,
        // total
        totalUsers: res?.[0]?.metricValues?.[1]?.value,
        // timeSpent: formatSecondsToMinutesSeconds(
        //   Number(res?.[0]?.metricValues?.[1]?.value)
        // ),
      }));
    }
    console.log(res);
  };
  const getBrandClick = async () => {
    let res = await apiCall({
      endpoint: `googleAnalytics`,
      method: "POST",
      data: {
        dimensions: [{ name: "pagePath" }],
        metrics: [{ name: "screenPageViews" }],
        dateRanges: [
          {
            startDate: "2020-03-31",
            endDate: "today",
          },
        ],
        dimensionFilter: {
          filter: {
            stringFilter: {
              matchType: "BEGINS_WITH",
              value: `/profile/${user?.id}`,
            },
            fieldName: "pagePath",
          },
        },
        metricAggregations: ["TOTAL"],
      },
    });
    console.log(res);
    if (isArrayWithValues(res)) {
      let count = 0;
      let arr = res?.map((o) => {
        let trafficCount = o?.metricValues?.[0]?.value;
        count += Number(trafficCount);
      });
      setTopCards((pre) => ({
        ...pre,
        brandTraffic: count,
      }));
    }
  };
  useEffect(() => {
    setUpData();
    getTotalViewPage();
    getCategoryAna();
    getBrandClick();
    return () => {};
  }, []);

  return (
    <div className=" relative text-white z-10 min-h-[100vh]  backdrop-brightness-50 ">
      <div>
        <Sidebar page="graph" />
      </div>
      <div className="ml-[50px] md:ml-[90px] p-2 md:p-5 px-2 lg:px-10 text-lg md:text-2xl space-y-5 text-justify">
        <div>
          <h3 className="p-1">
            Hello,{" "}
            <span className="underline font-semibold">
              {/* Brand Name */}
              {user?.brandName}!
            </span>{" "}
          </h3>
        </div>
        <div className="flex gap-[40px] max-lg:flex-col ">
          {/* total user  */}
          <div className="bg-[#F66B12] block max-w-sm p-6 border-gray-200 rounded-lg shadow gap-5 ">
            <div className="flex flex-row justify-between ">
              <h5 className="text-white font-semibold text-sm">
                Total Traffic on UB
              </h5>
              <AiFillHeart />
            </div>
            <h2 className="font-semibold py-4 text-[50px] ">
              {/* {graphData?.find((o) => o?.totalUser)?.totalUser} */}
              {topCards?.totalTraffic || "-"}
            </h2>
            {/* <div className="flex gap-1 mt-2">
              <BsFillLightningChargeFill className="fill-[#4FD3C4]" />
              <h5 className="text-white text-[14px] ">
                <span className="text-[#4FD3C4] font-semibold">Up to 5%</span>{" "}
                from Last Week
              </h5>
            </div> */}
          </div>

          {/* total click  */}

          {/* total click  */}
          <div className="bg-[#3B3B3B] block max-w-sm p-6 border-gray-200 rounded-lg shadow gap-5 ">
            <div className="flex flex-row justify-between ">
              <h5 className="text-white font-semibold text-sm">
                Total Traffic on brand Page{" "}
              </h5>
              <IoIosPeople />
            </div>
            <h2 className="font-semibold py-4 text-[50px] ">
              {/* {graphData?.find((o) => o?.totalClick)?.totalClick} */}
              {topCards?.brandTraffic || "-"}
            </h2>
            {/* <div className="flex gap-1 mt-2">
              <BsFillLightningChargeFill className="fill-[#4FD3C4]" />
              <h5 className="text-white text-[14px] ">
                <span className="text-[#4FD3C4] font-semibold">Up to 5%</span>{" "}
                from Last Week
              </h5>
            </div> */}
          </div>
          <div className="bg-[#3B3B3B] block max-w-sm p-6 border-gray-200 rounded-lg shadow gap-5 ">
            <div className="flex flex-row justify-between ">
              <h5 className="text-white font-semibold text-sm">
                {/* Total Clicks on brand link */}
                Total Users on UB
              </h5>
              <IoIosPeople />
            </div>
            <h2 className="font-semibold py-4 text-[50px] ">
              {/* {graphData?.find((o) => o?.totalClick)?.totalClick} */}
              {topCards?.totalUsers || "-"}
            </h2>
            {/* <div className="flex gap-1 mt-2">
              <BsFillLightningChargeFill className="fill-[#4FD3C4]" />
              <h5 className="text-white text-[14px] ">
                <span className="text-[#4FD3C4] font-semibold">Up to 5%</span>{" "}
                from Last Week
              </h5>
            </div> */}
          </div>
          {/* line chart  */}

          {false && (
            <div style={{ width: "450px", height: "200px" }}>
              <LineChart />
            </div>
          )}
        </div>

        {/* // stacked and pie chart  */}

        <div className="flex flex-row max-lg:flex-col justify-around ">
          <div className="mx-lg:h-[70vh] max-lg:w-[75vw] w-[450px] h-[400px]">
            {/* <CategoryChart graphData={graphData} /> */}
            <CategoryGraph data={categoryTraffic} />
          </div>
          <div className="max-lg:h-[75vh] max-lg:w-[75vw] w-[400px] h-[350px]">
            <h5 className="text-white font-semibold text-[20px] text-center max-lg:pt-10">
              Gender
            </h5>
            <GenderPieChart graphData={graphData} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Graph;
