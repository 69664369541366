import { createSlice } from "@reduxjs/toolkit";
import { fetchBlogTitles } from "./blogAction";
import Cookies from "js-cookie";

const cookieData = JSON.parse(Cookies.get("userData") || null);
const sellerId = cookieData?.id;

const initialState = {
  // Initialize your initial state here
  // For example, you can have fields like title, description, sellerId, and image.
  title: "",
  description: "",
  sellerId: sellerId, // You can get this from your cookie
  image: null,
  data: null,
  status: "idle",
  error: null,
  loading: false,
  error: null,
};

const blogSlice = createSlice({
  name: "blog",
  initialState,
  reducers: {
    setBlogData: (state, action) => {
      // Update the state with the data received from the form
      state.title = action.payload.title;
      state.description = action.payload.description;
      state.image = action.payload.image;
      // No need to update sellerId here since it comes from the cookie
    },
    updateStateWithPayload: (state, action) => {
      // Spread the payload into the state
      return {
        ...state,
        ...action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchBlogTitles.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchBlogTitles.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
        state.error = null;
        state.success = true;
      })
      .addCase(fetchBlogTitles.rejected, (state, action) => {
        state.loading = false;
        state.data = null;
        state.error = action.error.message;
      });
  },
});

export const { setBlogData, updateStateWithPayload } = blogSlice.actions;
export default blogSlice.reducer;
