import { createSlice } from "@reduxjs/toolkit";
import { fetchBlogTitles } from "./blogAction";

// Define an initial state for the blog titles
const initialState = {
  blogTitles: [],
  status: "idle",
  error: null,
};

// Create a slice for the blog titles
const blogTitleSlice = createSlice({
  name: "blogTitle",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchBlogTitles.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchBlogTitles.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.blogTitles = action.payload;
      })
      .addCase(fetchBlogTitles.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default blogTitleSlice.reducer;
