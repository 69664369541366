import React from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Sidebar from "../components/Sidebar";
import { AiOutlinePlus } from "react-icons/ai";
import Cookies from "js-cookie";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { getUrl } from "../helper";
import { updateHelper } from "../features/helper/helperSlice";

const NewBanner = () => {
  // const cookieData = JSON.parse(Cookies.get("userData"));
  const dispatch = useDispatch();
  const cookieData = useSelector((state) => state?.auth?.user);
  const sellerId = cookieData.id;
  const [selectedFile, setSelectedFile] = useState();
  const [preview, setPreview] = useState();
  const [uploading, setUploading] = useState(false);
  const [message, setMessage] = useState(null);
  const [severity, setSeverity] = useState("success"); // or 'error'
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const navigate = useNavigate();

  const baseURL = getUrl();
  // "https://unknownbazaar.in/temp/api";

  // console.log(cookieData.token);
  const token = cookieData.token;

  // create a preview as a side effect, whenever selected file is changed
  useEffect(() => {
    if (!selectedFile) {
      setPreview(undefined);
      return;
    }

    const objectUrl = URL.createObjectURL(selectedFile);
    setPreview(objectUrl);

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile]);

  const onSelectFile = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined);
      return;
    }
    //  first image instead of multiple
    setSelectedFile(e.target.files[0]);
  };

  // create banner function
  const handleUpload = async (e) => {
    e.preventDefault();

    if (!selectedFile) {
      setMessage("Please select an image.");
      setSeverity("error");
      setOpenSnackbar(true);
      return;
    }

    const formData = new FormData();
    formData.append("image", selectedFile);
    formData.append("sellerId", sellerId);

    try {
      setUploading(true);

      // Make the API call using Axios
      const response = await axios.post(
        `${baseURL}/banner/addBanner`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      console.log(response);

      // setMessage("Image uploaded successfully.");
      // setSeverity("success");
      // setOpenSnackbar(true);
      dispatch(
        updateHelper({
          globalToast: {
            show: true,
            type: "success",
            message: "Banner uploaded successfully.",
          },
        })
      );
      // Redirect to another page
      setTimeout(() => {
        navigate("/phone");
      }, 3000);
    } catch (error) {
      // setMessage("An error occurred while uploading the image.");
      // setSeverity("error");
      // setOpenSnackbar(true);
      dispatch(
        updateHelper({
          globalToast: {
            show: true,
            type: "success",
            message: "An error occurred while uploading the image.",
          },
        })
      );
      console.error(error);
    } finally {
      setUploading(false);
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <>
      <div className="relative text-white z-10   min-h-[100vh] backdrop-brightness-50">
        <div>
          <Sidebar page="phone" />
        </div>
        <div className="phone-blog-banners ml-[50px] md:ml-[90px] p-2 md:p-5 gap-2 px-auto  ">
          <div className="w-screen flex items-center justify-center flex-col ">
            <h1 className="font-black text-3xl py-5 ">Add New Banner</h1>
            <form onSubmit={handleUpload}>
              <p className="text-xl">Image For Banner</p>
              <div className="add-blog-image">
                <label className="add-image-label1">
                  <input
                    type="file"
                    accept="image/*"
                    style={{ display: "none" }}
                    onChange={onSelectFile}
                    required
                  />
                  {/* {selectedFile && <img src={preview} style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'fit' }} />} */}

                  {selectedFile ? (
                    <>
                      <img
                        src={preview}
                        style={{
                          maxWidth: "100%",
                          maxHeight: "100%",
                          objectFit: "cover",
                        }}
                        alt="banner-image"
                      />
                    </>
                  ) : (
                    <span className="add-image-icon ">
                      <AiOutlinePlus />
                    </span>
                  )}
                </label>
              </div>
              <div className="flex justify-center items-center my-5 ">
                <button className="bg-[#f66b12] px-9 py-3 rounded-lg font-semibold">
                  {uploading ? "Uploading..." : "Upload Banner"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000} // Adjust as needed
        onClose={handleCloseSnackbar}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={handleCloseSnackbar}
          severity={severity}
        >
          {message}
        </MuiAlert>
      </Snackbar>
    </>
  );
};

export default NewBanner;
