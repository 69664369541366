import axios from "axios";
import { spreadUserData } from "./features/auth/authSlice";

let store;

export const injectStore = (_store) => {
  store = _store;
};
const getToken = () => {
  const state = store.getState();

  return state?.auth?.user?.token || false;
};
const getUrl = () => {
  // if (isDev()) return "http://unknownbazaar.in/temp/api";
  return "https://api.unknownbazaar.com/api";
};

const imageUrl = () => {
  // if (isDev()) return "http://unknownbazaar.in/temp/";
  return "https://api.unknownbazaar.com/";
};
// const getUrl = () => {
//   if (isDev()) return "http://unknownbazaar.in/temp/api";
//   return "https://unknownbazaar.in/temp/api";
// };
// const imageUrl = () => {
//   if (isDev()) return "http://unknownbazaar.in/temp/";
//   return "https://unknownbazaar.in/temp/";
// };
function isDev() {
  return process.env.NODE_ENV === "development";
}
const getSellerId = () => {
  const state = store.getState();
  console.log(state);

  return state?.auth?.user?.id || false;
};
const getPhoneNumber = () => {
  const state = store.getState();
  console.log(state);

  return state?.auth?.user?.getPhoneNumber || false;
};

const setUpNavigate = async ({ dispatch, navigate }) => {
  let token = getToken();
  // console.log("run app js");
  let navigateUrl = "/landing-page";

  if (getSellerId()) {
    let baseURL = getUrl();
    try {
      let { data: res } = await axios({
        url: `${baseURL}/seller/checkInfoStage`,
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: {
          sellerId: getSellerId(),
        },
      });
      if (!res?.error && res?.data) {
        let { lastFilledPage, isVerified } = res?.data || {};
        let routes = {
          0: "/company-information",
          1: "/basic-information",
          2: "/select-plan",
          3: "/facts",
          // 1 :"/company-information" ,
          // 2 :"/basic-information" ,
          // 3 :"/select-plan" ,
          // 4 : "/facts"
        };
        if (routes?.[lastFilledPage]) {
          navigateUrl = routes?.[lastFilledPage];
        } else if (!isVerified) {
          navigateUrl = isDev() ? "/home" : "/welcome"; // welcome
        } else if (isVerified) {
          navigateUrl = "/home";
        }
      }
      // let { data: loginData } = await axios({
      //   url: `${baseURL}/auth/loginSeller`,
      //   method: "POST",
      //   headers: {
      //     Authorization: `Bearer ${token}`,
      //   },
      //   data: {
      //     phoneNumber: getPhoneNumber(),
      //   },
      // });
      // console.log(loginData);
      // if (!loginData?.error)
      //   dispatch(spreadUserData({ user: loginData?.data?.data }));
    } catch (error) {
      dispatch(spreadUserData({ user: {} }));
      console.log(error);
    }
  }
  // if (isDev()) navigateUrl = "/basic-information";
  navigate(navigateUrl);
};

const apiCall = async ({ endpoint, method = "GET", ...other }) => {
  const baseURL = getUrl();
  let token = getToken();
  let { data: res } = await axios({
    url: `${baseURL}/${endpoint}`,
    method,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    ...other,
  });
  return res;
};

function isArrayWithValues(value) {
  // Check if the value is an array
  if (Array.isArray(value)) {
    // Check if the array has non-empty elements
    return value.length > 0;
  }
  return false; // Return false for non-array values
}
function isObjectWithProperties(value) {
  if (typeof value === "object" && value !== null && !Array.isArray(value)) {
    // Check if the object has at least one property
    return Object.keys(value).length > 0;
  }
  return false; // Return false for non-object values
}
function getFileType(url) {
  // Extract the file extension from the URL
  const fileExtension = url.split(".").pop().toLowerCase();

  // Define an array of common video file extensions
  const videoExtensions = [
    "mp4",
    "avi",
    "mov",
    "mkv",
    "webm",
    "flv",
    "wmv",
    "mpeg",
    "3gp",
  ];

  // Define an array of common image file extensions
  const imageExtensions = [
    "jpg",
    "jpeg",
    "png",
    "gif",
    "bmp",
    "webp",
    "svg",
    "tiff",
    "ico",
  ];

  // Check if the file extension is in the video extensions array
  if (videoExtensions.includes(fileExtension)) {
    return "video";
  }

  // Check if the file extension is in the image extensions array
  if (imageExtensions.includes(fileExtension)) {
    return "image";
  }

  // If the extension doesn't match any known video or image extensions, you can
  // also check the MIME type, but that requires an asynchronous request to the server.
  // You can use the fetch API to do that.

  // If none of the checks match, return 'unknown' or handle it as needed.
  return "unknown";
}
function formatDate(date, format) {
  const monthFullNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const monthsNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const hours24 = date.getHours();
  const hours12 = hours24 % 12 || 12; // Convert to 12-hour format
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();
  const ampm = hours24 < 12 ? "AM" : "PM"; // Determine AM/PM

  const formatObj = {
    YYYY: year,
    MM: month < 10 ? "0" + month : month,
    M: month,
    MMMM: monthFullNames[month - 1],
    MMM: monthsNames[month - 1],
    DD: day < 10 ? "0" + day : day,
    D: day,
    HH: hours24 < 10 ? "0" + hours24 : hours24,
    H: hours24,
    hh: hours12 < 10 ? "0" + hours12 : hours12,
    h: hours12,
    mm: minutes < 10 ? "0" + minutes : minutes,
    m: minutes,
    ss: seconds < 10 ? "0" + seconds : seconds,
    s: seconds,
    A: ampm,
    P: ampm.toLowerCase(),
  };

  return format.replace(
    /YYYY|MMMM|MMM|MM|M|DD|D|HH|H|hh|h|mm|m|ss|s|A|P/g,
    (match) => formatObj[match]
  );
}
const getUserSite = () => {
  return "https://unknownbazaar.com/";
};
function stripHtmlTags(html) {
  const div = document.createElement("div");
  div.innerHTML = html;
  return div.innerText;
}
function formatSecondsToMinutesSeconds(seconds) {
  let mins = Math.floor(seconds / 60);
  let secs = Math.round(seconds % 60);
  return `${mins}m ${secs}s`;
}
export {
  getToken,
  getSellerId,
  isDev,
  apiCall,
  getUrl,
  imageUrl,
  setUpNavigate,
  isArrayWithValues,
  isObjectWithProperties,
  getFileType,
  getUserSite,
  stripHtmlTags,
  formatSecondsToMinutesSeconds,
  formatDate,
};
