export const category = {
  fashion: {
    heading: "Fashion",
    options: [
      { label: "Clothing", id: 1 },
      { label: "Footwear", id: 2 },
      { label: "Accessories", id: 3 },
      { label: "Active wear", id: 4, isDisabled: true },
    ],
  },
  Others: {
    heading: "Others",
    options: [
      { label: "Pet Care", id: 14 },
      { label: "Food & beverage", id: 17 },
      { label: "Services", id: 18 },
      { label: "Beauty & Wellness", id: 21 },
      { label: "Baby Care", id: 15, isDisabled: true },
      { label: "Gifting / customization", id: 16, isDisabled: true },
    ],
  },
  beauty: {
    heading: "Beauty & Wellness",
    options: [
      { label: "Makeup", id: 5, isDisabled: true },
      { label: "Skincare", id: 6, isDisabled: true },
      { label: "Haircare", id: 7, isDisabled: true },
      { label: "Bath & Body", id: 8, isDisabled: true },
      { label: "Health & Wellness", id: 9, isDisabled: true },
    ],
  },
  living: {
    heading: "Home & living",
    options: [
      { label: "Furniture", id: 10, isDisabled: true },
      { label: "Home Décor", id: 11, isDisabled: true },
      { label: "Home Furnishing", id: 12, isDisabled: true },
      { label: "Bath", id: 13, isDisabled: true },
      { label: "Kitchen", id: 19, isDisabled: true },
      { label: "Dining", id: 20, isDisabled: true },
    ],
  },
};
