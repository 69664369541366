import React, { useState, useEffect } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { AiOutlinePlus } from "react-icons/ai";
import { RxCross1 } from "react-icons/rx";
import { apiCall, getSellerId, getUrl, imageUrl } from "../helper";
import { useDispatch, useSelector } from "react-redux";
import { updateHelper } from "../features/helper/helperSlice";
import { Badge, CircularProgress, IconButton, Typography } from "@mui/material";
import { IoClose } from "react-icons/io5";

const Reel = () => {
  const [videosReel, setVideosReel] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedVideoReel, setSelectedVideoReel] = useState(null);
  const [carouselStartIndexReel, setCarouselStartIndexReel] = useState(0);
  const [isModalOpenReel, setIsModalOpenReel] = useState(false); // State to track the modal
  const cookieData = useSelector((state) => state?.auth?.user);
  // const cookieData = JSON.parse(Cookies.get("userData"));
  const dispatch = useDispatch();
  const handleOpenModalReel = (videoUrl) => {
    setSelectedVideoReel(videoUrl);
    setIsModalOpenReel(true);
    window.scrollTo(0, 0);
  };

  const handleCloseModalReel = () => {
    setSelectedVideoReel(null);
    setIsModalOpenReel(false);
  };

  useEffect(() => {
    getReel(cookieData.token, cookieData.id);
  }, []);

  const getReel = async (token, id) => {
    console.log(id);
    let url = getUrl();
    var result = await axios.post(
      `${url}/reel/getReelBySellerId`,
      { sellerId: id },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    let response = await result?.data?.data;
    const videoUrls = response.map((video) => video.file);
    console.log(response);
    setVideosReel(response);
  };

  const handleFileChangeReel = (event) => {
    const file = event.target.files[0];
    console.log(file);
    if (file) {
      // Check if the file is a video (you can add more checks)
      if (file.type.startsWith("video/")) {
        setSelectedVideoReel(file);
        // setVideos([...videos, URL.createObjectURL(file)]);
      } else {
        alert("Please select a valid video file.");
      }
    }
  };

  const handleNextClickReel = () => {
    // Calculate the index of the next set of videos to display
    const nextIndex = carouselStartIndexReel + 3;

    if (nextIndex < videosReel.length) {
      setCarouselStartIndexReel(nextIndex);
    }
  };

  const handlePrevClickReel = () => {
    // Calculate the index of the previous set of videos to display
    const prevIndex = carouselStartIndexReel - 3;

    if (prevIndex >= 0) {
      setCarouselStartIndexReel(prevIndex);
    }
  };

  const handleUploadHighlightsReel = async () => {
    console.log(selectedVideoReel);
    let baseURL = getUrl();
    if (!selectedVideoReel || !cookieData.id) {
      alert("Please select a video file and enter a seller ID.");
      return;
    }

    const formData = new FormData();
    formData.append("sellerId", cookieData.id);
    // formData.append("sellerId", cookieData.id);
    formData.append("video", selectedVideoReel);
    setLoading(true);
    try {
      const response = await axios.post(`${baseURL}/reel/addReel`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${cookieData.token}`,
        },
      });
      console.log(response);

      if (response.data.error) {
        console.error("Error uploading video:", response.data.message);
      } else {
        // Video uploaded successfully, update the video list
        getReel(cookieData.token, cookieData.id);
        dispatch(
          updateHelper({
            globalToast: {
              show: true,
              type: "success",
              message: "Reel uploaded Successfully!",
            },
          })
        );
        // Clear the selected video
        setSelectedVideoReel(null);
      }
    } catch (error) {
      console.error("Error uploading video:", error);
    } finally {
      setLoading(false);
    }
  };
  const deleteReel = async (id) => {
    dispatch(
      updateHelper({
        globalToast: {
          show: true,
          message: "Reel Removed Successfully!",
          type: "success",
        },
      })
    );
    try {
      let res = await apiCall({
        endpoint: "reel/deleteReel",
        method: "POST",
        data: {
          reelId: id,
        },
      });
    } catch (error) {
      dispatch(
        updateHelper({
          globalToast: {
            show: true,
            message: "Can't able to delete Reel",
            type: "error",
          },
        })
      );
    }
  };

  return (
    <div className="p-4 flex flex-col">
      <div className="flex flex-row gap-20 max-md:flex-col max-md:gap-3">
        <div className="w-40 h-40  flex items-center justify-center border-2 border-[#f66b12] ">
          {selectedVideoReel ? (
            <video
              src={selectedVideoReel}
              controls
              className="max-h-full max-w-full"
            />
          ) : (
            <div className="text-center p-3 w-50 h-50 bg-gray-200 rounded-lg">
              {/* <label className="block mb-2">Upload Video</label> */}
              <input
                type="file"
                accept="video/*"
                onChange={handleFileChangeReel}
                className="hidden"
                id="videoInput2"
              />
              <label
                htmlFor="videoInput2"
                className="cursor-pointer  text-white  rounded-lg flex justify-center items-center"
              >
                <AiOutlinePlus color="black " size={80} className="" />
              </label>
            </div>
          )}
        </div>
        <div className="p-3">
          <button
            className="bg-[#f66b12] p-[10px] px-[36px] rounded-lg font-semibold "
            onClick={handleUploadHighlightsReel}
          >
            {loading ? <CircularProgress color="success" /> : "Upload"}
            {/* Upload */}
          </button>
        </div>
      </div>

      {videosReel.length > 0 ? (
        <div className="mt-4">
          <h2 className="text-lg font-semibold">Uploaded Highlights</h2>
          <div className="flex items-center justify-between">
            {/* Previous button */}
            <button
              onClick={handlePrevClickReel}
              disabled={carouselStartIndexReel === 0}
            >
              {"<"}
            </button>
            <div className="flex space-x-4 overflow-hidden">
              {videosReel
                .slice(carouselStartIndexReel, carouselStartIndexReel + 3)
                .map((obj, index) => {
                  let { file: videoUrl, id } = obj;
                  return (
                    <Badge
                      sx={{
                        ".MuiBadge-badge": {
                          top: "10px",
                          right: "10px",
                        },
                      }}
                      badgeContent={
                        <IconButton
                          onClick={() => {
                            setVideosReel((pre) =>
                              pre?.filter((o) => o?.file != videoUrl)
                            );
                            deleteReel(id);
                          }}
                        >
                          <IoClose />
                        </IconButton>
                      }
                      key={index}
                    >
                      <div
                        key={index}
                        className="w-[150px] h-[150px] border-2 border-gray-400 p-1 flex-shrink-0 cursor-pointer"
                        onClick={() => handleOpenModalReel(videoUrl)} // Open modal when clicked
                      >
                        <video
                          src={`${imageUrl()}${videoUrl}`}
                          className="w-full h-full"
                        />
                      </div>
                    </Badge>
                  );
                })}
            </div>
            {/* Next button */}
            <button
              onClick={handleNextClickReel}
              disabled={carouselStartIndexReel + 3 >= videosReel.length}
            >
              {">"}
            </button>
          </div>
        </div>
      ) : (
        <p className="text-slate-400 font-semibold text-xl pt-[10px]">
          No Reels added yet!
        </p>
      )}
      {/* Modal */}
      {isModalOpenReel && (
        <div className="fixed inset-0 flex items-center justify-center z-50 h-[100vh]">
          <div className="absolute inset-0 bg-black opacity-60" />
          <div className="relative bg-white p-4 rounded-lg z-10">
            <video
              src={`${imageUrl()}${selectedVideoReel}`}
              className="w-full h-[80vh]"
              controls // Display video controls (play, pause, etc.)
            />
            <button
              onClick={handleCloseModalReel}
              className="absolute top-10 right-2 bg-gray-300 text-gray-600 rounded-full p-2 "
            >
              <RxCross1 size={40} className="" fill="red" />
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Reel;
