import "./App.css";
import { Routes, Route, useNavigate } from "react-router-dom";
import Home from "./pages/home";
import Introduction from "./pages/Introduction";
import Login from "./pages/Login";
import Register from "./pages/Register";
import Loading from "./components/Loading";
import Welcome from "./components/Welcome";
import CompanyInformation from "./pages/CompanyInformation";
import BasicInformation from "./pages/BasicInformation";
import Facts from "./pages/Facts";
import SelectPlan from "./pages/SelectPlan";
import Dashboardhome from "./pages/Dashboard";
import Graph from "./pages/Graph";
import Phone from "./pages/Phone";
import Getverify from "./pages/Getverify";
import Questions from "./pages/Questions";
import Dashboard2 from "./pages/Dashboard2";
import NewBlog from "./pages/NewBlog";
import Notfound from "./components/Notfound";
import OtpVerify from "./pages/OtpVerify";
import Term from "./pages/Term";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
// import { baseURL } from "./features/profile/profileActions";
import { getSellerId, getToken, getUrl, isDev, setUpNavigate } from "./helper";
import NewBanner from "./pages/NewBanner";
import { setUser } from "./features/auth/userSlice";
import { spreadUserData } from "./features/auth/authSlice";
import { Alert, Snackbar } from "@mui/material";
import { updateHelper } from "./features/helper/helperSlice";
import Settings from "./pages/Settings";
import NoOptions from "./components/NoOptions";
import LandingPage from "./pages/LandingPage";
import { getSellerInfo } from "./features/profile/profileActions";

function App() {
  const user = useSelector((state) => state?.auth?.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const globalToast = useSelector((state) => state.helper?.globalToast);
  useEffect(() => {
    // if (!isDev())
    setUpNavigate({
      dispatch,
      navigate,
    });
    dispatch(getSellerInfo());
    return () => {};
  }, []);

  return (
    <div className="bgi ">
      <div className="bgi-overlay"></div>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/landing" element={<Introduction />} />
        <Route path="/join-us" element={<Introduction />} />
        <Route path="/home" element={<Dashboardhome />} />
        <Route path="/dashboard" element={<Dashboard2 />} />
        <Route path="/login" element={<Login />} />
        <Route path="/otp-verify" element={<OtpVerify />} />
        <Route path="/register" element={<Register />} />
        <Route path="/loading" element={<Loading />} />
        <Route path="/welcome" element={<Welcome />} />
        <Route path="/company-information" element={<CompanyInformation />} />
        <Route path="/basic-information" element={<BasicInformation />} />
        <Route path="/facts" element={<Facts />} />
        <Route path="/select-plan" element={<SelectPlan />} />
        <Route path="/phone" element={<Phone />} />
        <Route path="/graph" element={<Graph />} />
        <Route path="/getverify" element={<Getverify />} />
        <Route path="/question" element={<Questions />} />
        <Route path="/new-blog" element={<NewBlog />} />
        <Route path="/blog/:id" element={<NewBlog />} />
        <Route path="/banner/new-banner" element={<NewBanner />} />
        <Route path="/setting/:info" element={<Settings />} />
        <Route path="/no-options" element={<NoOptions />} />
        <Route path="/landing-page" element={<LandingPage />} />
        <Route path="/terms-and-condition" element={<Term />} />
        <Route path="*" element={<Notfound />} />
      </Routes>
      <Snackbar
        open={globalToast?.show}
        autoHideDuration={2000}
        onClose={() => {
          dispatch(
            updateHelper({
              globalToast: {},
            })
          );
        }}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          // onClose={dispatch(
          //   updateHelper({
          //     globalToast: {},
          //   })
          // )}
          severity={globalToast?.type}
        >
          {globalToast?.message}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default App;
