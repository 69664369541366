import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);
const CategoryGraph = ({ data }) => {
  const options = {
    labels: data?.map((o) => o?.name),
    datasets: [
      {
        label: "Page View",
        data: data?.map((o) => o?.trafficCount),
        backgroundColor: [
          "#FF6B6B", // Red
          "#8CE8E8", // Cyan
          "#FFD166", // Yellow
          "#A0D2DB", // Blue
          "#EF476F", // Pink
          "#06D6A0", // Green
          "#FF9F1C", // Orange
          "#7B77E9", // Purple
          "#FF7D00", // Dark Orange
          "#56CFE1", // Sky Blue
        ],
        // ["#FF1616", "#F66B12", "#D44000"]
        borderColor: ["#D44000", "#D44000", "#D44000"],
        borderWidth: 1,
      },
    ],
  };
  return (
    <div>
      <h5 className="text-white font-semibold text-[20px] text-center max-lg:pt-10">
        Category
      </h5>
      {/* <Pie data={options} /> */}
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Category</TableCell>
              <TableCell align="right">Page Views</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row) => (
              <TableRow
                key={row.name}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row.name}
                </TableCell>
                <TableCell align="right">{row.trafficCount}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default CategoryGraph;
