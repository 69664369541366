import React, { useState, useEffect } from "react";
import Navbar from "../components/Navbar";
import "../css/navbar.css";
import "../css/login.css";
import { useDispatch, useSelector } from "react-redux";
// import { fetchOTP, verifyOTP } from '../features/auth/authAction';
import { useNavigate, Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import CircularProgress from "@mui/material/CircularProgress";
import { login } from "../features/auth/authAction";
import { updateHelper } from "../features/helper/helperSlice";
import { isDev, isObjectWithProperties } from "../helper";
import { ImFilePdf } from "react-icons/im";

const Login = () => {
  const { loading, success } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const { register, handleSubmit } = useForm();
  const navigate = useNavigate();
  const [error, setError] = useState("");

  // useEffect(() => {
  //     // console.log(success)

  //     // redirect user to login/OTP page if registration was successful
  //     if (success) {
  //         navigate('/otp-verify');
  //     }
  //     // console.log(success)

  // }, [navigate, success])

  const submitForm = (data) => {
    dispatch(login(data))?.then((returnValue) => {
      console.log(returnValue);
      if (returnValue?.payload) {
        dispatch(
          updateHelper({
            otpData: {
              otp: returnValue?.payload,
              phoneNumber: data?.phoneNumber,
            },
          })
        );
        navigate("/otp-verify");
      } else {
        setError(returnValue?.payload);
        if (isDev()) navigate("/otp-verify");
      }
    });
    // navigate('/login/otp');
  };

  return (
    <div className="min-h-[100vh] backdrop-brightness-65">
      <Navbar pages="login" navText="Log In" />
      <div className="login-body ">
        <div className="login-conatiner1 ">
          <div className="login-container">
            <div className="login-box">
              <form onSubmit={handleSubmit(submitForm)}>
                {error && (
                  <Alert severity="error">
                    <AlertTitle>Error</AlertTitle>
                    {error} <br />
                  </Alert>
                )}
                <label
                  htmlFor="name"
                  className=" text-lg text-white font-montserrat pb-2"
                >
                  Enter Phone Number
                </label>
                <input
                  type="text"
                  placeholder="Mobile Number"
                  className="input-field"
                  name="phoneNumber"
                  {...register("phoneNumber")}
                  maxLength="10"
                  autoFocus
                  required
                />
                <button
                  type="submit"
                  className="login-button"
                  disabled={loading}
                >
                  {loading ? <CircularProgress color="success" /> : "Send OTP"}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
