import React, { useState, useEffect } from "react";
import Navbar from "../components/Navbar";
import "../css/navbar.css";
import "../css/forms.css";
import { useNavigate, useParams } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { companyInfo } from "../features/profile/profileActions";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import CircularProgress from "@mui/material/CircularProgress";
import Cookies from "js-cookie";
const stateNames = [
  "Andhra Pradesh",
  "Arunachal Pradesh",
  "Assam",
  "Bihar",
  "Chhattisgarh",
  "Goa",
  "Gujarat",
  "Haryana",
  "Himachal Pradesh",
  "Jharkhand",
  "Karnataka",
  "Kerala",
  "Madhya Pradesh",
  "Maharashtra",
  "Manipur",
  "Meghalaya",
  "Mizoram",
  "Nagaland",
  "Odisha",
  "Punjab",
  "Rajasthan",
  "Sikkim",
  "Tamil Nadu",
  "Telangana",
  "Tripura",
  "Uttar Pradesh",
  "Uttarakhand",
  "West Bengal",
];
const CompanyInformation = () => {
  const user = useSelector((state) => state?.auth?.user);
  let { info } = useParams();
  const dispatch = useDispatch();
  const { handleSubmit, control, setValue } = useForm(); // Initialize react-hook-form
  // const user = useSelector(state => state?.auth?.user)

  const [selectedYear, setSelectedYear] = useState("");
  const [selectedState, setSelectedState] = useState("");

  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: 101 }, (_, index) => currentYear - index);

  useEffect(() => {
    let feildsArray = [
      "isHomeGrown",
      "rawMaterialSource",
      "companyName",
      "brandName",
      "concernedPersonName",
      // "concernPersonName",
      "address",
      // "phone",
      "companyPhone",
      "city",
      "state",
      "pincode",
      "yoe",
      // "gstNo",
      // "panNo",
      // "aadharNo",
      "gstNumber",
      "aadharCardNumber",
      "panNumber",
    ];
    if (info) {
      for (const key of feildsArray) {
        if (key === "concernedPersonName")
          setValue("concernPersonName", user?.[key]);
        else if (key === "gstNumber") setValue("gstNo", user?.[key]);
        else if (key === "aadharCardNumber") setValue("aadharNo", user?.[key]);
        else if (key === "panNumber") setValue("panNo", user?.[key]);
        else if (key === "companyPhone") setValue("phone", user?.[key]);
        else setValue(key, user?.[key]);
      }
    }
    return () => {};
  }, [info]);

  const handleYearChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedYear(selectedValue);

    // Dynamically update the selected option's style
    const options = event.target.options;
    for (let i = 0; i < options.length; i++) {
      if (options[i].value === selectedValue) {
        options[i].classList.add("selected-year");
      } else {
        options[i].classList.remove("selected-year");
      }
    }
  };

  const handleStateChange = (event) => {
    const selecteSetState = event.target.value;
    setSelectedState(event.target.value);

    // Dynamically update the selected option's style
    const options = event.target.options;
    for (let i = 0; i < options.length; i++) {
      if (options[i].value === selecteSetState) {
        options[i].classList.add("selected-year");
      } else {
        options[i].classList.remove("selected-year");
      }
    }
  };

  // function for sending form data to companyInfo function
  const cookieData = JSON.parse(Cookies.get("userData") || null);
  const sellerId = user?.id;
  const { loading, error, success } =
    useSelector((state) => state.profile) || {};
  const navigate = useNavigate();
  // useEffect(() => {
  //   if (success) {
  //     navigate("/basic-information");
  //   }
  // }, [navigate, success]);

  const onSubmit = (data) => {
    // Include sellerId in formData
    // data.sellerId = sellerId;
    // Dispatch the fetchCompanyInfo action with form data
    console.log(data);
    // return;
    dispatch(companyInfo({ data, navigate }));
  };

  return (
    <div className="min-h-[100vh] backdrop-brightness-60">
      <Navbar pages="information" navText={info ? "" : "List with Us. "} />
      <div className="info-body  ">
        <div className="info-container ">
          <div className="child ">
            <div className="radio-body">
              <div className="radio-container">
                <label className="radio-label">
                  <input
                    type="radio"
                    name="option"
                    className="custom-radio-btn"
                    checked
                    style={{ accentColor: "#f66b12" }}
                  />
                  Company Information
                </label>
                <div className="vertical-line"></div>
                <label
                  className="radio-label"
                  onClick={() => info && navigate("/setting/basic-information")}
                >
                  <input
                    type="radio"
                    name="option"
                    className="custom-radio-btn"
                    disabled
                  />
                  Basic Information
                </label>
                <div className="vertical-line"></div>
                <label
                  className="radio-label"
                  onClick={() => info && navigate("/setting/select-plan")}
                >
                  <input
                    type="radio"
                    name="option"
                    className="custom-radio-btn"
                    disabled
                  />
                  {info ? "Active" : "Select"} Plan
                </label>
                <div className="vertical-line"></div>
                <label
                  className="radio-label"
                  onClick={() => info && navigate("/setting/facts")}
                >
                  <input
                    type="radio"
                    name="option"
                    className="custom-radio-btn"
                    disabled
                  />
                  Facts
                </label>
              </div>
            </div>
          </div>
          <div className="child form-child ">
            <h2 className="form-heading">
              {info ? (
                <>
                  {" "}
                  Please email us on{" "}
                  <a
                    style={{ color: "#f66b12" }}
                    href={`mailto:${"connect@unknownbazaar.com"}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    // href="connect@unknownbazaar.com"
                  >
                    connect@unknownbazaar.com
                  </a>{" "}
                  to update the below details.{" "}
                </>
              ) : (
                "Help us with basic details about your brand"
              )}
            </h2>
            <div className="child-form">
              <form onSubmit={handleSubmit(onSubmit)}>
                {error && (
                  <Alert severity="error">
                    <AlertTitle>Error</AlertTitle>
                    {error.message} <br />
                  </Alert>
                )}
                <div className="form-row ">
                  <div className="input-data">
                    <label htmlFor="name" className="input-label">
                      Is your brand a homegrown brand?
                      <p className="label1">
                        (made in India or assembled in India)
                      </p>
                    </label>
                    <Controller
                      name="isHomeGrown"
                      control={control}
                      defaultValue="" // Set your default value here
                      render={({ field }) => (
                        <select
                          {...field}
                          disabled={info}
                          className="dropdown-list"
                          required
                        >
                          <option
                            value=""
                            disabled
                            className="disbaled-option-form"
                          >
                            Choose one: Yes/No
                          </option>
                          <option value="Yes">Yes</option>
                          <option value="No">No</option>
                        </select>
                      )}
                    />
                  </div>
                  <div className="input-data pt-[15px] ">
                    <label htmlFor="isHomeGrown" className="input-label">
                      Where do you get your raw materials from?
                    </label>
                    <Controller
                      name="rawMaterialSource"
                      control={control}
                      defaultValue="" // Set your default value here
                      render={({ field }) => (
                        <select
                          {...field}
                          className="dropdown-list"
                          disabled={info}
                          required
                        >
                          <option
                            value=""
                            disabled
                            className="disbaled-option-form"
                          >
                            Choose one : India/Outside India
                          </option>
                          <option value="India">India</option>
                          <option value="Outside India">Outside India</option>
                        </select>
                      )}
                    />
                  </div>
                </div>
                <div className="form-row">
                  <div className="input-data">
                    <label htmlFor="companyName" className="input-label">
                      Company Name
                    </label>
                    <Controller
                      name="companyName"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <input
                          {...field}
                          className="input-field-style"
                          type="text"
                          required
                          disabled={info}
                        />
                      )}
                    />
                  </div>
                  <div className="input-data ">
                    <label htmlFor="concernPersonName" className="input-label">
                      Brand Name
                    </label>
                    <Controller
                      name="brandName"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <input
                          {...field}
                          className="input-field-style"
                          type="text"
                          required
                          disabled={info}
                        />
                      )}
                    />
                  </div>
                  <div className="input-data ">
                    <label htmlFor="concernPersonName" className="input-label">
                      Concern Person Name
                    </label>
                    <Controller
                      name="concernPersonName"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <input
                          {...field}
                          className="input-field-style"
                          type="text"
                          required
                          disabled={info}
                        />
                      )}
                    />
                  </div>
                </div>
                <div className="form-row">
                  <div className="input-data">
                    <label htmlFor="address" className="input-label">
                      Full Address
                    </label>
                    <Controller
                      name="address"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <input
                          {...field}
                          className="input-field-style"
                          type="text"
                          required
                          disabled={info}
                        />
                      )}
                    />
                  </div>
                  <div className="input-data ">
                    <label htmlFor="phone" className="input-label">
                      Mobile / Landline Number
                    </label>
                    <Controller
                      name="phone"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <input
                          {...field}
                          className="input-field-style"
                          type="text"
                          required
                          disabled={info}
                        />
                      )}
                    />
                  </div>
                </div>
                <div className="form-row">
                  <div className="input-data">
                    <label htmlFor="city" className="input-label">
                      City
                    </label>
                    <Controller
                      name="city"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <input
                          {...field}
                          className="input-field-style"
                          type="text"
                          required
                          disabled={info}
                        />
                      )}
                    />
                  </div>
                  <div className="input-data ">
                    <label htmlFor="state" className="input-label">
                      State
                    </label>
                    <Controller
                      name="state"
                      control={control}
                      defaultValue="" // Set your default value here
                      render={({ field }) => (
                        <select
                          {...field}
                          className="dropdown-list"
                          // value={selectedState}
                          // onChange={handleStateChange}
                          required
                          disabled={info}
                        >
                          <option
                            value=""
                            disabled
                            className="disbaled-option-form"
                          >
                            Select a state
                          </option>
                          {stateNames.map((state, index) => (
                            <option key={index} value={state}>
                              {state}
                            </option>
                          ))}
                        </select>
                      )}
                    />
                    {/* <select className='dropdown-list' value={selectedState} onChange={handleStateChange} required>
                                            <option value="" disabled className='disbaled-option-form' >Select a state</option>
                                            {stateNames.map((state, index) => (
                                                <option key={index} value={state}>
                                                    {state}
                                                </option>
                                            ))}
                                        </select> */}
                  </div>
                  <div className="input-data ">
                    <label htmlFor="pincode" className="input-label">
                      Pincode
                    </label>
                    <Controller
                      name="pincode"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <input
                          {...field}
                          className="input-field-style"
                          type="text"
                          required
                          disabled={info}
                        />
                      )}
                    />
                  </div>
                </div>
                <div className="form-row">
                  <div className="input-data">
                    <label htmlFor="yoe" className="input-label">
                      Year of Establishment
                    </label>
                    <Controller
                      name="yoe"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <select
                          {...field}
                          className="dropdown-list"
                          // value={selectedYear}
                          // onChange={handleYearChange}
                          required
                          disabled={info}
                        >
                          <option
                            value=""
                            disabled
                            className="disbaled-option-form"
                          >
                            Select a year
                          </option>
                          {years.map((year, index) => (
                            <option
                              className="drop-option"
                              key={index}
                              value={year}
                            >
                              {year}
                            </option>
                          ))}
                        </select>
                      )}
                    />
                  </div>
                  <div className="input-data ">
                    <label htmlFor="gstNo" className="input-label">
                      GST No
                    </label>
                    <Controller
                      name="gstNo"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <input
                          {...field}
                          className="input-field-style"
                          type="text"
                          disabled={info}
                          // required
                        />
                      )}
                    />
                  </div>
                  <div className="input-data ">
                    <label htmlFor="panNo" className="input-label">
                      PAN Number
                    </label>
                    <Controller
                      name="panNo"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <input
                          {...field}
                          className="input-field-style"
                          type="text"
                          required
                          disabled={info}
                        />
                      )}
                    />
                  </div>
                </div>
                <div className="form-row">
                  <div className="input-data">
                    <label htmlFor="aadharNo" className="input-label">
                      Adhaar Number
                    </label>
                    <Controller
                      name="aadharNo"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <input
                          {...field}
                          className="input-field-style"
                          type="text"
                          required
                          disabled={info}
                        />
                      )}
                    />
                  </div>
                </div>

                {info ? null : (
                  <button
                    type="submit"
                    className="login-button1"
                    disabled={loading}
                  >
                    {loading ? <CircularProgress color="success" /> : " Submit"}
                  </button>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyInformation;
