import React from "react";
import ub2 from "../../images/ub2.png";
import check from "../../images/check.png";

const Benefit = () => {
    return (
        <>
            <div className="flex max-lg:flex-col-reverse">
                <div className="max-xl:pt-[60px] max-lg:pt-3" >
                    <img
                        src={ub2}
                        className=""
                        height={500}
                        width={800}
                        alt="benefit"
                    />
                </div>
                <div className=" pt-[50px] max-lg:w-full max-lg:pt-[10px] max-lg:gap-3 mr-[20px]">
                    <ul>
                        <div className="ml-[-30px] max-lg:ml-0" >
                            <li
                                className="bg-opacity-[0.9] bg-[#242424] 
            backdrop-blur-sm rounded-xl shadow-md py-2 pl-1 mb-[20px]
            pr-[50px] flex text-white text-[16px]
             align-baseline max-lg:pr-2 max-lg:mx-[20px]"
                            >
                                <img
                                    src={check}
                                    className="rounded-[5px] mr-2"
                                    height={40}
                                    width={40}
                                    alt=""
                                />
                                More Brand Visibility
                            </li>
                        </div>
                        <div className="ml-[30px] max-lg:ml-0">
                            <li
                                className="bg-opacity-[0.9] bg-[#242424] 
            backdrop-blur-sm rounded-xl shadow-md py-2 pl-1 mb-[20px]
            pr-[50px] flex text-white text-[16px]
             align-baseline max-lg:pr-2 max-lg:mx-[20px]"
                            >
                                <img
                                    src={check}
                                    className="rounded-[5px] mr-2"
                                    height={40}
                                    width={40}
                                    alt=""
                                />
                                Reach Pan India audience
                            </li>
                        </div>
                        <div className="ml-[75px] max-lg:ml-0">
                            <li
                                className="bg-opacity-[0.9] bg-[#242424] 
            backdrop-blur-sm rounded-xl shadow-md py-2 pl-1 mb-[20px]
            pr-[50px] flex text-white text-[16px] max-lg:pr-2 max-lg:mx-[20px]
             align-baseline"
                            >
                                <img
                                    src={check}
                                    className="rounded-[5px] mr-2"
                                    height={40}
                                    width={40}
                                    alt=""
                                />
                                Zero Commission = High Margins
                            </li>
                        </div>
                        <div className="ml-[95px] max-lg:ml-0">
                            <li
                                className="bg-opacity-[0.9] bg-[#242424] 
            backdrop-blur-sm rounded-xl shadow-md py-2 pl-1 mb-[20px]
            pr-[50px] flex text-white text-[16px] max-lg:pr-2 max-lg:mx-[20px]
             align-baseline"
                            >
                                <img
                                    src={check}
                                    className="rounded-[5px] mr-2"
                                    height={40}
                                    width={40}
                                    alt=""
                                />
                                No Middle Men = <br />
                                Directly reach your customers
                            </li>
                        </div>
                        <div className="ml-[75px] max-lg:ml-0 " >
                            <li
                                className="bg-opacity-[0.9] bg-[#242424] 
            backdrop-blur-sm rounded-xl shadow-md py-2 pl-1 mb-[20px]
            pr-[50px] flex text-white text-[16px] max-lg:pr-2 max-lg:mx-[20px]
             align-baseline"
                            >
                                <img
                                    src={check}
                                    className="rounded-[5px] mr-2"
                                    height={40}
                                    width={40}
                                    alt=""
                                />
                                Limited Competition. <br />
                                You are at the forefront
                            </li>
                        </div>
                        <div className="ml-[30px] max-lg:ml-0">
                            <li
                                className="bg-opacity-[0.9] bg-[#242424] 
            backdrop-blur-sm rounded-xl shadow-md py-2 pl-1 mb-[20px]
            pr-[50px] flex text-white text-[16px] max-lg:pr-2 max-lg:mx-[20px]
             align-baseline"
                            >
                                <img
                                    src={check}
                                    className="rounded-[5px] mr-2"
                                    height={40}
                                    width={40}
                                    alt=""
                                />
                                Improved Ranking on Search Engine.(SEO)
                            </li>
                        </div>



                        <div className="ml-[-40px] max-lg:ml-0">
                            <li
                                className="bg-opacity-[0.9] bg-[#242424] 
            backdrop-blur-sm rounded-xl shadow-md py-2 pl-1 
            pr-[50px] flex text-white text-[16px] max-lg:pr-2 max-lg:mx-[20px]
             align-baseline"
                            >
                                <img
                                    src={check}
                                    className="rounded-[5px] mr-2"
                                    height={40}
                                    width={40}
                                    alt=""
                                />
                                No massive ad spends.
                            </li>
                        </div>

                    </ul>
                </div>
            </div>
        </>
    );
};

export default Benefit;
