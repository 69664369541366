import React from "react";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Sidebar from "../components/Sidebar";
import { AiOutlinePlus } from "react-icons/ai";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { setBlogData } from "../features/phone/blog/blogSlice";
import { createBlog } from "../features/phone/blog/blogAction";
import Cookies from "js-cookie";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Alert from "@mui/material/Alert";
import { updateHelper } from "../features/helper/helperSlice";
import { imageUrl } from "../helper";

const NewBlog = () => {
  const dispatch = useDispatch();
  let { id } = useParams();
  console.log(id);
  // const { title, description, image } = useSelector((state) => state.blog);

  const cookieData = useSelector((state) => state?.auth?.user);

  // JSON.parse(Cookies.get("userData"));
  const sellerId = cookieData.id;

  const [selectedFile, setSelectedFile] = useState();
  const [preview, setPreview] = useState();
  const [title, setTitle] = useState("");
  // const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [editorData, setEditorData] = useState("");
  const blogTitles = useSelector((state) => state?.blogTitle?.blogTitles);

  useEffect(() => {
    if (id) {
      let blog = blogTitles?.find((o) => o?.id == id);

      console.log(blog);
      setEditorData(blog?.description);
      setTitle(blog?.title);
      console.log(`${imageUrl()}${blog?.blogImage}`);
      setPreview(`${imageUrl()}${blog?.blogImage}`);
    }

    return () => {};
  }, [id, blogTitles]);

  // create a preview as a side effect, whenever selected file is changed
  useEffect(() => {
    if (!selectedFile) {
      // setPreview(undefined);
      return;
    }

    const objectUrl = URL.createObjectURL(selectedFile);
    setPreview(objectUrl);

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile]);

  const onSelectFile = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined);
      return;
    }

    // I've kept this example simple by using the first image instead of multiple
    setSelectedFile(e.target.files[0]);
  };
  const clearImage = () => {
    setSelectedFile(null);
  };

  // createBlog function

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Prepare the data to send to the API
    const data = {
      title,
      description: editorData,
      sellerId: sellerId, // Get sellerId from your cookie
      image: selectedFile,
    };

    // Dispatch the action to send data to the API
    try {
      dispatch(createBlog(data))?.then((res) => {
        console.log(res);
        dispatch(
          updateHelper({
            globalToast: {
              show: true,
              type: "success",
              message: "Blog uploaded Successfully ",
            },
          })
        );
      });
      // setShowSuccessPopup(true);

      setTimeout(() => {
        navigate("/phone");
      }, 3000);
    } catch (error) {
      // Handle the error (e.g., show an error message)
      Alert(`Not able to upload your blog ${error.message} `);
    }
  };

  const handleTitleChange = (e) => {
    // dispatch(setBlogData({ title: e.target.value }));
    setTitle(e.target?.value);
  };

  const handleDescriptionChange = (e, editor) => {
    const description = editor.getData();
    dispatch(setBlogData({ description }));
  };

  return (
    <>
      <div className="relative text-white z-10 min-h-[100vh] backdrop-brightness-50">
        <div>
          <Sidebar page="phone" />
        </div>
        <div className="flex flex-wrap ml-[50px] md:ml-[90px] p-2 md:p-5 gap-2 px-auto ">
          <div className="w-full flex items-center justify-center flex-col ">
            <h1 className="font-black text-3xl py-5 ">
              {id ? "Blog" : "Add New Blog"}
            </h1>
            <form onSubmit={handleSubmit}>
              <p className="text-xl">Image For Blog</p>
              <div className="add-blog-image">
                <label className="add-image-label1">
                  <input
                    type="file"
                    accept="image/*"
                    style={{ display: "none" }}
                    onChange={onSelectFile}
                    required
                  />
                  {/* {selectedFile && <img src={preview} style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'fit' }} />} */}

                  {selectedFile || preview ? (
                    <>
                      <img
                        src={preview}
                        style={{
                          maxWidth: "100%",
                          maxHeight: "100%",
                          objectFit: "cover",
                        }}
                        alt="blog-image"
                      />
                    </>
                  ) : (
                    <span className="add-image-icon ">
                      <AiOutlinePlus />
                    </span>
                  )}
                </label>
              </div>
              <div className="title py-5 ">
                <p className="text-xl">Title of Blog</p>
                <input
                  type="text"
                  className="input-field-style hover:bg-[#4B5563] font-xl"
                  placeholder="Enter title of Your Blog...."
                  value={title}
                  onChange={handleTitleChange}
                  required
                />
              </div>
              <div className="blog-desc py-5 ">
                <p className="text-xl">Your Blog</p>
                <div className="editorProd">
                  <CKEditor
                    editor={ClassicEditor}
                    data={editorData}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      setEditorData(data);
                    }}
                    required
                  />
                </div>
              </div>
              <div className="flex justify-center items-center my-5 ">
                {id ? null : (
                  <button className="bg-[#f66b12] px-9 py-3 rounded-lg font-semibold">
                    Upload Blog
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
      {/* Success Popup */}
      {/* <Snackbar
        open={showSuccessPopup}
        autoHideDuration={6000}
        onClose={handleCloseSuccessPopup}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={handleCloseSuccessPopup}
          severity="success"
        >
          Blog created successfully!
        </MuiAlert>
      </Snackbar> */}
    </>
  );
};

export default NewBlog;
