import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  plugins: {
    title: {
      display: true,
      text: "Categories",
      color: "#fff",
      font: {
        size: "20px", // Change the title font size
        weight: "bold", // You can also set the font weight
      },
    },
  },
  legend: {
    labels: {
      color: "#fff", // Change the legend label color
      font: {
        size: 14, // Change the legend label font size
      },
    },
  },

  responsive: true,
  scales: {
    x: {
      stacked: true,
      ticks: {
        color: "#fff", // Change the x-axis label color
        font: {
          size: 14, // Change the x-axis label font size
        },
      },
    },
    y: {
      stacked: true,
      ticks: {
        color: "#fff", // Change the x-axis label color
        font: {
          size: 14, // Change the x-axis label font size
        },
      },
    },
  },
  maintainAspectRatio: false,
};

const labels = ["Footwear", "Apparel", "Petcare", "Healty Bites", "Service"];

// export const data = {
//     labels,
//     datasets: [
//         {
//             label: 'This week',
//             data: [12, 30, 34,20, 50 ],
//             backgroundColor: '#F66B12',
//             color : '#fff'
//         },
//         {
//             label: 'last week',
//             data: [20, 23, 65, 45, 26],
//             backgroundColor: '#FF1616',
//         },

//     ],
// };

const CategoryChart = ({ graphData }) => {
  let graph = graphData?.flat()?.filter((o) => o?.trafficCount || o?.name);
  //   console.log(graph);
  const data = {
    labels: graph?.map((o) => o?.name),
    datasets: [
      {
        label: "This week",
        data: graph?.map((o) => o?.trafficCount),
        backgroundColor: "#F66B12",
        color: "#fff",
      },
      //   {
      //     label: "last week",
      //     data: [20, 23, 65, 45, 26],
      //     backgroundColor: "#FF1616",
      //   },
    ],
  };
  return (
    <>
      <Bar options={options} data={data} />
    </>
  );
};

export default CategoryChart;
