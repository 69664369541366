
import React from "react";
import Logo from "../images/logo.png";
import { Link } from "react-router-dom";


const NoOptions = () => {
  return (
    <div className="min-h-[100vh]">
      <div className="no-page">
        <div className="image-div">
          <Link to='/'><img className="image-style" src={Logo} alt="unknown-bazaar" /></Link>
        </div>
        <div className="welcome-style">
          <h3 className="text-[#F66B12] font-bold text-[28px] ">We Support Homegrown Brands Only</h3>
          <p className="text-center text-white text-[25px] max-md:text-[18px] " >
            <br />
            Welcome to Unknown Bazaar! We're excited that you're interested in
            joining our community of sellers. Our aim is to support homegrown
            brands that have the spirit of innovation, craftsmanship, and local
            entrepreneurship. <br /><br />

            At Unknown Bazaar, we believe in the power of
            homegrown talent and products. We have made a conscious decision to
            feature homegrown brands exclusively
          </p>
        </div>
      </div>
    </div>
  );
};

export default NoOptions;
