// userSlice.js
import { createSlice } from "@reduxjs/toolkit";
import { verifyOtp } from "./authAction";

const initialState = {
  userData: null, // Initial state for user data
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action) => {
      console.log(action.payload, state, "called");
      state.userData = action.payload;
    },
    updateUser: (state, payload) => {
      console.log(payload);
      return {
        ...state,
        ...payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      //  company info
      .addCase(verifyOtp.fulfilled, (state, action) => {
        console.log(action.payload);
        // state.loading = true;
        // state.error = null;
      });
  },
});

export const { setUser, updateUser } = userSlice.actions;
export default userSlice.reducer;
