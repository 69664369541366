import React, { useEffect, useState } from "react";
import "../css/imageupload.css";
import { AiOutlinePlus } from "react-icons/ai";
import { RxCross1 } from "react-icons/rx";
import {
  apiCall,
  getFileType,
  getSellerId,
  imageUrl,
  isArrayWithValues,
} from "../helper";
import { useDispatch, useSelector } from "react-redux";
import { updateHelper } from "../features/helper/helperSlice";
import { Badge, IconButton } from "@mui/material";
import { IoClose } from "react-icons/io5";
import ImagePicker from "./ImagePicker";
const ImageUploader = () => {
  const [open, setOpen] = useState(false);
  const [files, setFiles] = useState([]);
  const images = useSelector((state) => state?.helper?.userImages);
  const dispatch = useDispatch();

  const getUserImage = async () => {
    try {
      let res = await apiCall({
        endpoint: "seller/getImageBySellerId",
        method: "POST",
        data: {
          sellerId: getSellerId(),
        },
      });
      console.log(res);
      if (!res?.error) {
        dispatch(
          updateHelper({
            userImages: res?.data,
          })
        );
      }
    } catch (error) {}
  };
  useEffect(() => {
    if (!isArrayWithValues(images)) getUserImage();
    return () => {};
  }, []);
  useEffect(() => {
    if (Array.isArray(images)) setFiles(images);

    return () => {};
  }, [images]);

  const uploadImage = async (file) => {
    const formData = new FormData();
    formData.append("image", file);
    formData.append("sellerId", getSellerId());

    try {
      let res = await apiCall({
        endpoint: "seller/addImage",
        method: "POST",
        data: formData,
      });
      console.log(res);
      if (!res?.error) {
        dispatch(updateHelper({ userImages: res?.data }));
        dispatch(
          updateHelper({
            globalToast: {
              show: true,
              type: "success",
              message: "Images uploaded successfully",
            },
          })
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleFileChange = async (e) => {
    const selectedFiles = Array.from(e.target.files);
    const totalFiles = [...files, ...selectedFiles].slice(0, 10); // Limit to 10 files
    setFiles(totalFiles);
    // info
    dispatch(
      updateHelper({
        globalToast: {
          show: true,
          type: "info",
          message: "Please wait Images are uploading...",
        },
      })
    );
    for await (const file of selectedFiles) {
      uploadImage(file);
    }
    dispatch(
      updateHelper({
        globalToast: {
          show: true,
          type: "success",
          message: "Images uploaded successfully",
        },
      })
    );
  };

  const handleDeleteImage = (index) => {
    const updatedFiles = [...files];
    updatedFiles.splice(index, 1);
    setFiles(updatedFiles);
  };
  const deleteImage = async (id) => {
    dispatch(
      updateHelper({
        globalToast: {
          show: true,
          message: "Image Removed Successfully!",
          type: "success",
        },
      })
    );
    try {
      let res = await apiCall({
        endpoint: "seller/deleteImage",
        method: "POST",
        data: {
          imageId: id,
          sellerId: getSellerId(),
        },
      });
    } catch (error) {
      dispatch(
        updateHelper({
          globalToast: {
            show: true,
            message: "Can't able to delete Image",
            type: "error",
          },
        })
      );
    }
  };
  const getFile = async (file) => {
    await uploadImage(file);
  };

  return (
    <div>
      {open && (
        <ImagePicker
          open={open}
          handleClose={() => setOpen(false)}
          heading={"Select Media"}
          getFile={getFile}
          type={
            files?.filter((o) => getFileType(o?.imagePath) === "video")
              ?.length >= 2
              ? "image"
              : null
          }
        />
      )}
      <div className="image-container">
        {files.map((file, index) => {
          return (
            <Badge
              sx={{
                ".MuiBadge-badge": {
                  top: "16px",
                  right: "16px",
                },
              }}
              badgeContent={
                <IconButton
                  onClick={() => {
                    //   setVideosReel((pre) =>
                    //     pre?.filter((o) => o != videoUrl)
                    //   );
                    handleDeleteImage(index);
                    deleteImage(file?.id);
                  }}
                >
                  <IoClose />
                </IconButton>
              }
              key={index}
            >
              <div key={index} className="image-preview">
                {/* <button
                  className="delete-button z-10 p-3 hidden"
                  onClick={() => {
                    handleDeleteImage(index);
                    deleteImage(file?.id);
                  }}
                >
                  <RxCross1 color="black" />
                </button> */}
                {getFileType(file?.imagePath) === "image" ? (
                  <img
                    // src={URL.createObjectURL(file)}
                    src={`${imageUrl()}${file?.imagePath}`}
                    alt={`Image ${index}`}
                  />
                ) : (
                  <video controls>
                    <source
                      src={`${imageUrl()}${file?.imagePath}`}
                      type={file.type}
                    />
                    Your browser does not support the video tag.
                  </video>
                )}
              </div>
            </Badge>
          );
        })}
        {files.length < 10 && (
          <label className="add-image-label" onClick={() => setOpen(true)}>
            {/* <input
              type="file"
              accept="image/*, video/*"
              multiple
              onChange={handleFileChange}
              style={{ display: "none" }}
            /> */}
            <span className="add-image-icon ">
              <AiOutlinePlus />
            </span>
          </label>
        )}
      </div>
    </div>
  );
};

export default ImageUploader;
