import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import Cookies from "js-cookie";
import { apiCall, getSellerId, getToken, getUrl } from "../../helper";
import { updateUser } from "./profileSlice";
import { updateHelper } from "../helper/helperSlice";

// Create an async thunk for company-information
export const companyInfo = createAsyncThunk(
  "/company-information",
  async ({ data: formData, navigate }, { rejectWithValue, dispatch }) => {
    console.log(formData);
    const sellerId = getSellerId();
    const token = getToken();
    const baseURL = getUrl();

    try {
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };

      const response = await axios.post(
        `${baseURL}/seller/addCompanyInformation`,
        { ...formData, sellerId },
        {
          headers: headers,
        }
      );
      if (!response?.data?.error) navigate(`/basic-information`);
      console.log(response.data);
      dispatch(
        updateHelper({
          globalToast: {
            show: true,
            message: "Company Information Added Successfully!",
            type: "success",
          },
        })
      );
      if (response.status !== 200) {
        return rejectWithValue("Failed to add company infomation");
      }
      return response.data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error);
      }
    }
  }
);

export const getSellerInfo = createAsyncThunk(
  "/get-Info",
  async (_, { dispatch }) => {
    try {
      let res = await apiCall({
        endpoint: "brand/getBrandByBrandId",
        data: {
          brandId: getSellerId(),
        },
        method: "POST",
      });
      console.log(res, dispatch);
      if (!res?.error && res?.data) {
        dispatch(
          updateUser({
            profileData: res?.data,
          })
        );
      }
    } catch (error) {
      console.log(error);
    }
  }
);

// Create an async thunk for basic-information
export const basicInfo = createAsyncThunk(
  "/basic-information",
  async ({ data: formData, navigate }, { rejectWithValue }) => {
    const token = getToken();
    const sellerId = getSellerId();
    const baseURL = getUrl();

    try {
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };

      const response = await axios.post(
        `${baseURL}/seller/addBasicInformation`,
        { ...formData },
        {
          headers: headers,
        }
      );
      console.log(response.data);
      if (!response?.data?.error && navigate) navigate("/select-plan");
      if (response.status !== 200) {
        return rejectWithValue("Failed to add basic infomation");
      }
      return response.data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error);
      }
    }
  }
);
export const factsInfo = createAsyncThunk(
  "/basic-information",
  async (
    { data: formData, navigate, endpoint = "addFact" },
    { rejectWithValue }
  ) => {
    const token = getToken();
    const sellerId = getSellerId();
    const baseURL = getUrl();

    try {
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };

      const response = await axios.post(
        `${baseURL}/fact/${endpoint}`,
        { ...formData, sellerId },
        {
          headers: headers,
        }
      );
      console.log(response.data);
      if (!response?.data?.error) navigate && navigate("/welcome");
      if (response.status !== 200) {
        return rejectWithValue("Failed to add basic infomation");
      }
      return response.data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error);
      }
    }
  }
);
