import React from "react";
import ub1 from "../../images/ub1.png";

const About = () => {
  return (
    <>
      <section className="py-[60px] flex max-lg:flex-col mx-[50px] max-lg:gap-[20px] max-md:mx-[20px]">
        <div className="">
          <h3 className="hero-text1 w-fit ">
            What is Unknown Bazaar?
            {/* <br /> */}
          </h3>
          <p className="text-white text-[18px] font-medium text-justify w-[90%] max-md:w-full ">
            Discover and support homegrown businesses with Unknown Bazaar!
            <br />
            <br />
            We are a curated platform dedicated to showcasing homegrown brands.
            Find unique and authentic products crafted by local entrepreneurs.
            Connect with the community and make a positive impact.
            <br />
            <br />
            Unknown Bazaar gives a new outlook to the customers. They can easily
            navigate categories, search for brands, and explore their profiles.
            Join us in celebrating the spirit of entrepreneurship and shopping
            homegrown with our brand directory.
          </p>
        </div>
        {/* <div className=" max-md:items-center"> */}
        <img
          src={ub1}
          className="max-lg:max-w-[80%] max-xl:max-w-[110%] max-xl:h-[100%]"
          alt=""
          width={"auto"}
          height={"50%"}
          style={{ maxHeight: 512 }}
        />
        {/* </div> */}
      </section>
    </>
  );
};

export default About;
