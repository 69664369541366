import React, { useState } from "react";
import Navbar from "../components/Navbar";
import "../css/navbar.css";
import "../css/login.css";
import { useDispatch, useSelector } from "react-redux";
// import { fetchOTP, verifyOTP } from '../features/auth/authAction';
import { useNavigate, Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import CircularProgress from "@mui/material/CircularProgress";
import { verifyOtp } from "../features/auth/authAction";
import { updateUser } from "../features/auth/userSlice";
import { spreadUserData } from "../features/auth/authSlice";
import { setUpNavigate } from "../helper";

const OtpVerify = () => {
  const { loading, success } = useSelector((state) => state.auth);
  let otpData = useSelector((state) => state?.helper?.otpData);

  const dispatch = useDispatch();
  const { register, handleSubmit, setValue } = useForm();
  const navigate = useNavigate();
  const [error, setError] = useState("");

  // useEffect(() => {
  //     // console.log(success)

  //     // redirect user to login/OTP page if registration was successful
  //     if (success) {
  //         navigate('/otp-verify');
  //     }
  //     // console.log(success)

  // }, [navigate, success])

  const handleInputChange = (e) => {
    // Additional action when the input changes
    setError("");
    console.log("Input value changed:", e.target.value);

    // Update the value in the form state
    setValue("otp", e.target.value);
  };

  const submitForm = (data) => {
    console.log(otpData, data);
    if (otpData?.otp == data?.otp) {
      dispatch(verifyOtp({ phoneNumber: otpData?.phoneNumber }))?.then(
        (returnValue) => {
          console.log(returnValue);
          if (returnValue?.payload) {
            dispatch(spreadUserData({ user: returnValue?.payload }));
            // navigate("/home");
            setUpNavigate({
              dispatch,
              navigate,
            });
          } else {
            navigate("/register");
          }
        }
      );
    } else {
      setError("Invalid OTP");
    }
    // navigate('/login/otp');
  };

  return (
    <div className="min-h-[100vh] backdrop-brightness-65">
      <Navbar pages="login" navText="Log In" />
      <div className="login-body ">
        <div className="login-conatiner1 ">
          <div className="login-container">
            <div className="login-box">
              <form onSubmit={handleSubmit(submitForm)}>
                {error && (
                  <Alert severity="error" className="text-xl font-semibold ">
                    <AlertTitle>Error</AlertTitle>
                    <br />
                    {error == "You are not registered" ? (
                      <>
                        {error}. Be Unknown Bazaar's Valuable Member.{" "}
                        <Link
                          to="/register"
                          className="font-palanquin underline font-bold text-orange-500"
                        >
                          {" "}
                          Register Here{" "}
                        </Link>{" "}
                      </>
                    ) : (
                      <>{error}</>
                    )}
                  </Alert>
                )}
                <label
                  htmlFor="name"
                  className=" text-lg text-white font-montserrat pb-2"
                >
                  Enter OTP
                </label>
                <input
                  type="text"
                  placeholder="Enter OTP"
                  className="input-field"
                  name="otp"
                  {...register("otp")}
                  onChange={handleInputChange}
                  maxLength="6"
                  required
                />
                <button type="submit" className="login-button">
                  {loading ? (
                    <CircularProgress color="success" />
                  ) : (
                    "Verify OTP"
                  )}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OtpVerify;
