import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import Cookies from "js-cookie";
import { getToken, getUrl } from "../../../helper";

// Create an async thunk for getting review
export const fetchSellerReview = createAsyncThunk(
  "seller/fetchData",
  async (sellerId, { rejectWithValue }) => {
    const baseURL = getUrl();
    const token = getToken();
    try {
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      // Make an API request to fetch data based on the sellerId in the request body
      const response = await axios.post(
        `${baseURL}/review/getReviewBySellerId`,
        { sellerId },
        {
          headers: headers,
        }
      );
      if (response.status !== 200) {
        return rejectWithValue("Failed to fetch reviews");
      }

      return response.data;
    } catch (error) {
      // Handle any error that occurs during the API request
      return rejectWithValue(error.message);
    }
  }
);

// Create an async thunk for getting review
export const postReview = createAsyncThunk(
  "seller/postReview",
  async (reviewData, { rejectWithValue }) => {
    const baseURL = getUrl();
    const token = getToken();
    try {
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      // Use Axios to post the review data to your API endpoint
      const response = await axios.post(
        `${baseURL}/review/addReview`,
        reviewData,
        {
          headers: headers,
        }
      );
      if (response.status !== 200) {
        return rejectWithValue("Failed to upload review reviews");
      }

      // Assuming a successful response has a 'data' property
      console.log(response.data);
      return response.data?.data;
    } catch (error) {
      // Handle any error that occurs during the API request
      return rejectWithValue(error.message);
    }
  }
);
