import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import "../css/navbar.css";
import "../css/forms.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { factsInfo } from "../features/profile/profileActions";
import {
  MenuItem,
  Select,
  Typography,
  Tooltip,
  FormControl,
} from "@mui/material";
import { updateHelper } from "../features/helper/helperSlice";
import Info from "../images/info.png";

const Facts = () => {
  let { info } = useParams();
  const user = useSelector((state) => state?.profile?.profileData);
  console.log(user, "<user");
  // const user = useSelector((state) => state?.auth?.user);
  const { handleSubmit, control, setValue, watch } = useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const refundStatus = watch("refundStatus");
  const [inputValue, setInputValue] = useState("");
  const [moneyInput, setMoneyInput] = useState("");
  const [minValue, setMinValue] = useState("");
  const [maxValue, setMaxValue] = useState("");

  const [minMoney, setMinMoney] = useState("");
  const [maxMoney, setMaxMoney] = useState("");

  const handleInputChange = (event) => {
    const newValue = event.target.value;
    setInputValue(newValue);

    // Extracting min and max values using a colon as separator
    const [min, max] = newValue.split("-").map((value) => value.trim());
    min < max ? setMinValue(min) : setMaxValue(max);
    min < max ? setMaxValue(max) : setMinValue(min);
  };

  const handleMoneyChange = (event) => {
    const newValueMoney = event.target.value;
    setMoneyInput(newValueMoney);

    // Extracting min and max values using a colon as separator
    const [min, max] = newValueMoney.split("-").map((value) => value.trim());
    min < max ? setMinMoney(min) : setMaxMoney(max);
    min < max ? setMaxMoney(max) : setMinMoney(min);
  };
  useEffect(() => {
    let feildsArray = [
      "productSustainable",
      "deliveryDuration",
      "totalCustomerServed",
      "minmaxValue",
      "deliveryPanIndia",
      "returnRate",
      "returnUserCount",
      "shippingPartner",
      "paymentMode",
      "customerServiceOperationalHour",
      "shippingFeeStatus",
      "returnExchangeStatus",
      "customerServiceOperationalDay",
      "shippingFee",
      "refundStatus",
      "deliveryLocation",
      "responseTime",
      "refundMode",
    ];
    if (info) {
      for (const key of feildsArray) {
        if (key === "paymentMode") setValue(key, user?.[key]?.split(","));
        else if (key === "shippingFee") setValue(key, user?.[key]?.split(","));
        else if (key === "returnExchangeStatus")
          setValue(key, user?.[key]?.split(","));
        else setValue(key, user?.[key]);
      }
    }
    return () => {};
  }, []);

  const onSubmit = (data) => {
    data.paymentMode = data?.paymentMode?.join();
    data.returnExchangeStatus = data?.returnExchangeStatus?.join();
    data.shippingFee = data?.shippingFee?.join();
    console.log(data);
    // return;

    dispatch(
      factsInfo({
        data,
        navigate: info ? null : navigate,
        endpoint: info ? "updateFact" : "addFact",
      })
    )?.then((_d) => {
      if (info)
        dispatch(
          updateHelper({
            globalToast: {
              show: true,
              type: "success",
              message: "Facts Updated Successfully!",
            },
          })
        );
      console.log(_d);
    });
  };

  const TooltipText = `
  Sustainable Brands/Products satisfies most of the below points.
  1. Using Resources Wisely
  2. Evaluating Full Product Life
  3. Reducing Pollution and Waste
  4. Caring for People and Communities
  `;
  const tooltipStyle = {
    maxWidth: "300px", // Set your preferred maximum width here
    whiteSpace: "pre-wrap", // Preserve line breaks
  };
  return (
    <>
      <Navbar
        pages="information"
        className="flex justify-start"
        navText="Fact Sheet"
      />
      <body className="info-body bgi">
        <div className="info-container">
          <div className="child ">
            <div className="radio-body">
              <div className="radio-container">
                <label
                  className="radio-label"
                  onClick={() =>
                    info && navigate("/setting/company-information")
                  }
                >
                  <input
                    type="radio"
                    name="option"
                    className="custom-radio-btn"
                    disabled
                  />
                  Company Information
                </label>
                <div className="vertical-line"></div>
                <label
                  className="radio-label"
                  onClick={() => info && navigate("/setting/basic-information")}
                >
                  <input
                    type="radio"
                    name="option"
                    className="custom-radio-btn"
                    disabled
                  />
                  Basic Information
                </label>
                <div className="vertical-line"></div>
                <label
                  className="radio-label"
                  onClick={() => info && navigate("/setting/select-plan")}
                >
                  <input
                    type="radio"
                    name="option"
                    className="custom-radio-btn"
                    disabled
                  />
                  {info ? "Active" : "Select"} Plan
                </label>
                <div className="vertical-line"></div>
                <label
                  className="radio-label"
                  onClick={() => info && navigate("/setting/facts")}
                >
                  <input
                    type="radio"
                    name="option"
                    className="custom-radio-btn"
                    checked
                    style={{ accentColor: "#f66b12" }}
                  />
                  Facts
                </label>
              </div>
            </div>
          </div>
          <div className="child form-child">
            {info ? null : (
              <h2 className="form-heading">
                Help us with basic details about your brand
              </h2>
            )}
            <div className="child-form">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="form-row">
                  <div className="input-data ">
                    <label for="name" className="input-label flex ">
                      Are your products/services sustainable ?
                      <Tooltip
                        title={<div style={tooltipStyle}>{TooltipText}</div>}
                        placement="top"
                        className="text-[16px]"
                        sx={{ m: 1 }}
                      >
                        <img
                          src={Info}
                          className="ml-2"
                          alt="info"

                          // width={7}
                          // height={7}
                        />
                      </Tooltip>
                    </label>
                    <Controller
                      name="productSustainable"
                      control={control}
                      defaultValue={""}
                      render={({ field }) => {
                        return (
                          <select {...field} className="dropdown-list" required>
                            <option value="" disabled>
                              Choose one : Yes / No
                            </option>
                            <option value="yes">Yes</option>
                            <option value="no">No</option>
                          </select>
                        );
                      }}
                    />
                  </div>
                  <div className="input-data ">
                    <label for="name" className="input-label">
                      What is your minimum & Max product value in INR?
                    </label>

                    <Controller
                      name="minmaxValue"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <input
                          {...field}
                          className="input-field-style"
                          type="text"
                          //   value={moneyInput}
                          //   onChange={handleMoneyChange}
                          placeholder="Min-Max (use - in min and max)"
                          required
                        />
                      )}
                    />
                  </div>
                  <div className="input-data">
                    <label for="name" className="input-label">
                      How many return users would you say you have ?
                    </label>
                    <Controller
                      name="returnUserCount"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <input
                          {...field}
                          className="input-field-style"
                          type="text"
                          placeholder="Choose one : 10% -100%"
                          required
                        />
                      )}
                    />
                  </div>
                </div>
                <div className="form-row">
                  <div className="input-data ">
                    <label for="name" className="input-label">
                      Customer service operational hours
                    </label>
                    <Controller
                      name="customerServiceOperationalHour"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <select className="dropdown-list" required {...field}>
                          <option value="" disabled>
                            Choose one{" "}
                          </option>
                          <option value="24 hours">24 Hours</option>
                          <option value="12 Hours">12 Hours</option>
                          <option value="8 Hours">8 Hours</option>
                          {/* <option value="6 Hours">6 Hours</option>
                          <option value="3 Hours">3 Hours</option>
                          <option value="1 Hours">1 Hours</option>
                          <option value="Imidetaliy">Imidetaliy</option>
                          <option value="8 - 20 hour ">8 - 20 hour </option>
                          <option value="10 - 22 hour">10 - 22 hour</option> */}
                        </select>
                      )}
                    />
                  </div>
                  <div className="input-data ">
                    <label for="name" className="input-label">
                      Customer service operational Days
                    </label>
                    <Controller
                      name="customerServiceOperationalDay"
                      control={control}
                      defaultValue="" // Set your default value here
                      render={({ field }) => (
                        <input
                          {...field}
                          className="input-field-style"
                          type="text"
                          placeholder="Write Operational Days"
                          required
                        />
                      )}
                    />
                  </div>
                  <div className="input-data ">
                    <label for="name" className="input-label">
                      Delivery/Service Location? (Use Comma as separator)
                    </label>
                    <Controller
                      name="deliveryLocation"
                      control={control}
                      defaultValue="" // Set your default value here
                      render={({ field }) => (
                        <input
                          {...field}
                          className="input-field-style"
                          placeholder="Write names of Multiple states you deliver in"
                          type="text"
                          required
                        />
                      )}
                    />
                  </div>
                </div>
                <div className="form-row">
                  <div className="input-data">
                    <label for="name" className="input-label">
                      Delivery/Service duration? (Days/Immediate)
                    </label>

                    <Controller
                      name="deliveryDuration"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <input
                          {...field}
                          className="input-field-style"
                          type="text"
                          //   value={inputValue}
                          //   onChange={handleInputChange}
                          placeholder="Min-Max (use - in min and max)"
                        />
                      )}
                    />
                  </div>
                  <div className="input-data">
                    <label for="name" className="input-label">
                      Do you deliver/serve pan India?
                    </label>
                    <Controller
                      name="deliveryPanIndia"
                      control={control}
                      defaultValue="1" // Set your default value here
                      render={({ field }) => (
                        <select {...field} className="dropdown-list" required>
                          <option value="0" disabled>
                            Choose one : Yes / No
                          </option>
                          <option value="1">Yes</option>
                          <option value="2">No</option>
                        </select>
                      )}
                    />
                  </div>
                  <div className="input-data">
                    <label for="name" className="input-label">
                      Which Shipping partners do you use? (Only where
                      applicable)
                    </label>

                    <Controller
                      name="shippingPartner"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <input
                          {...field}
                          className="input-field-style"
                          type="text"
                          placeholder="Write names of your delivery partner"
                          required
                        />
                      )}
                    />
                  </div>
                </div>
                <div className="form-row">
                  <div className="input-data">
                    <label for="name" className="input-label">
                      Do you charge a shipping Fee?
                    </label>
                    <Controller
                      name="shippingFeeStatus"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <select className="dropdown-list" {...field} required>
                          <option value="" disabled>
                            Choose Yes/No/ Others{" "}
                          </option>
                          <option value="yes">Yes</option>
                          <option value="no">No</option>
                          <option value="other">Others</option>
                        </select>
                      )}
                    />
                  </div>
                  <div className="input-data">
                    <label for="name" className="input-label">
                      If yes, mention when do you charge shipping fees?
                    </label>
                    <Controller
                      name="shippingFee"
                      control={control}
                      defaultValue={[]} // Set your default value here
                      render={({ field }) => (
                        <FormControl sx={{ width: 350 }}>
                          <Select
                            sx={{
                              display: "block",
                              mt: 2,
                              ".MuiSelect-outlined": {
                                color: "#fff",
                              },
                            }}
                            {...field}
                            multiple
                            variant="standard"
                            // required
                            // options={}
                          >
                            {/* <MenuItem value="" disabled>
                              Choose One MenuItem Others
                            </MenuItem> */}
                            <MenuItem value="For all orders">
                              For all orders
                            </MenuItem>
                            <MenuItem value="Order Value is too Low">
                              Order Value is too Low
                            </MenuItem>
                            <MenuItem value="Shipping to remote Locations">
                              Shipping to remote Locations
                            </MenuItem>
                            <MenuItem value="Next Day Delivery">
                              Next Day Delivery
                            </MenuItem>
                            <MenuItem value="Distance too far">
                              Distance too far
                            </MenuItem>
                          </Select>
                        </FormControl>
                      )}
                    />
                  </div>
                  <div className="input-data ">
                    <label for="name" className="input-label">
                      Customer Service Response Time?
                    </label>
                    <Controller
                      name="responseTime"
                      control={control}
                      defaultValue="" // Set your default value here
                      render={({ field }) => (
                        <select className="dropdown-list" required {...field}>
                          <option value="" disabled>
                            Choose one{" "}
                          </option>
                          <option value="24 Hours">24 Hours</option>
                          <option value="12 Hours">12 Hours</option>
                          <option value="8 Hours">8 Hours</option>
                          <option value="6 Hours">6 Hours</option>
                          <option value="3 Hours">3 Hours</option>
                          <option value="Immediately">Immediately</option>
                          {/* <option value="8-20">8 - 20 hour </option>
                          <option value="10-22">10 - 22 hour</option> */}
                        </select>
                      )}
                    />
                  </div>
                </div>
                <div className="form-row">
                  <div className="input-data">
                    <label
                      for="name"
                      className="input-label special-style-input"
                    >
                      How many customers have you served/delivered till date?
                    </label>

                    <Controller
                      name="totalCustomerServed"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <input
                          {...field}
                          className="input-field-style"
                          type="text"
                          placeholder="Write the total number of customers "
                          required
                        />
                      )}
                    />
                  </div>
                  <div className="input-data ">
                    <label for="name" className="input-label">
                      How often do the customers return the products? {"  "}
                      <Typography variant="caption">(out of 10)</Typography>
                    </label>

                    <Controller
                      name="returnRate"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        // <input
                        //   {...field} disabled={info}
                        //   className="input-field-style"
                        //   type="text"
                        //   placeholder="Choose one: 1 out of 10 and so on."
                        //   required
                        // />
                        <select {...field} className="dropdown-list" required>
                          {new Array(11)?.fill("")?.map((_, index) => {
                            return (
                              <option key={index} value={index}>
                                {index}
                              </option>
                            );
                          })}
                        </select>
                      )}
                    />
                  </div>
                  <div className="input-data ">
                    <label for="name" className="input-label">
                      Various mode of payments available
                    </label>
                    <Controller
                      name="paymentMode"
                      control={control}
                      defaultValue={[]}
                      render={({ field }) => (
                        // <select className="dropdown-list" {...field} required>
                        //   <option value="" disabled>
                        //     Choose one{" "}
                        //   </option>
                        //   <option value="online">Online</option>
                        //   <option value="cod">Cash on Delievry</option>
                        //   <option value="online,cod">
                        //     Online and cash on Delivery
                        //   </option>
                        // </select>
                        <Select
                          sx={{
                            display: "block",
                            mt: 2,
                            ".MuiSelect-outlined": {
                              color: "#fff",
                            },
                          }}
                          {...field}
                          multiple
                          variant="standard"
                          required
                          // options={}
                        >
                          {[
                            {
                              value: "Debit/credit card",
                              label: "Debit/credit card",
                            },
                            { value: "UPI", label: "UPI" },

                            {
                              value: "Cash On Delivery",
                              label: "Cash On Delivery",
                            },
                            {
                              value: "Bank transfer",
                              label: "Bank transfer",
                            },
                          ]?.map((o) => (
                            <MenuItem value={o?.value} key={o?.value}>
                              {o?.label}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    />
                  </div>
                </div>
                <div className="form-row">
                  <div className="input-data ">
                    <label for="name" className="input-label">
                      Do you accept return or exchange
                    </label>
                    <Controller
                      name="returnExchangeStatus"
                      control={control}
                      defaultValue={[]}
                      render={({ field }) => (
                        <FormControl sx={{ width: 350 }}>
                          <Select
                            sx={{
                              display: "block",
                              mt: 2,
                              ".MuiSelect-outlined": {
                                color: "#fff",
                              },
                            }}
                            {...field}
                            multiple
                            variant="standard"
                            required
                            // options={}
                          >
                            <MenuItem value="Not Applicable">
                              Not Applicable
                            </MenuItem>
                            <MenuItem value="Return">Return</MenuItem>
                            <MenuItem value="Exchange">Exchange</MenuItem>
                            <MenuItem value="Return and Exchange">
                              Both
                            </MenuItem>
                          </Select>
                        </FormControl>
                        // <select {...field} className="dropdown-list" required>
                        //   <option value="">
                        //     {/* Choose One: Return/Exchange/Both */}
                        //     None
                        //   </option>
                        //   <option value="return">Return</option>
                        //   <option value="exchange">Exchange</option>
                        //   <option value="return-exchange">Both</option>
                        // </select>
                      )}
                    />
                  </div>
                  <div className="input-data">
                    <label for="name" className="input-label">
                      Do you process refund to a customer?
                    </label>
                    <Controller
                      name="refundStatus"
                      control={control}
                      defaultValue="" // Set your default value here
                      render={({ field }) => (
                        <select className="dropdown-list" required {...field}>
                          <option value="" disabled>
                            Choose Yes/No
                          </option>
                          <option value="yes">Yes</option>
                          <option value="no">No</option>
                        </select>
                      )}
                    />
                  </div>
                  {/* {refundStatus == "yes" && ( */}
                  <div className="input-data ">
                    {refundStatus == "yes" && (
                      <>
                        <label for="name" className="input-label">
                          If Yes, what is the mode of refund?
                        </label>
                        <Controller
                          name="refundMode"
                          control={control}
                          defaultValue="" // Set your default value here
                          render={({ field }) => (
                            // <input
                            //   {...field}
                            //   className="input-field-style"
                            //   placeholder="Choose one: To the Source / store credits"
                            //   type="text"
                            // />
                            <select
                              {...field}
                              className="dropdown-list"
                              required
                            >
                              {/* <option value="" disabled>
                            Choose one : Yes / No
                          </option> */}
                              <option value="Original Source">
                                Original Source
                              </option>
                              <option value="Store Credit">Store Credit</option>
                            </select>
                          )}
                        />
                      </>
                    )}
                  </div>
                  {/* )} */}
                </div>
                <div className="input-button-style">
                  {/* <Link to="/welcome"> */}
                  <button type="submit" class="login-button2">
                    {info ? "Update" : "Submit"}
                  </button>
                  {/* </Link> */}
                </div>
              </form>
            </div>
          </div>
        </div>
      </body>
    </>
  );
};

export default Facts;
