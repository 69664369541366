import React, { useEffect, useState } from "react";
import Sidebar from "../components/Sidebar";
import Reel from "../components/Reel";
import Cookies from "js-cookie";
import Highlights from "../components/Highlights";
import axios from "axios";
import { getUrl } from "../helper";
import { useDispatch, useSelector } from "react-redux";
import { updateHelper } from "../features/helper/helperSlice";

const Dashboard2 = () => {
  const cookieData = useSelector((state) => state?.auth?.user);
  const dispatch = useDispatch();
  //  JSON.parse(Cookies.get("userData"));
  const [offer, setOffer] = useState("");

  useEffect(() => {
    getOffer(cookieData.token, cookieData.id);
  }, []);

  const getOffer = async (token, id) => {
    let url = getUrl();
    let result = await axios.post(
      `${url}/offer/getOfferBySellerId`,
      { sellerId: cookieData.id },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    let response = await result?.data?.data?.[0]?.offer;
    if (response) {
      setOffer(response);
    }
  };
  const handleOffer = async () => {
    const formData = new FormData();

    formData.append("offer", offer);
    formData.append("sellerId", cookieData.id);

    try {
      const response = await axios.post(
        `${getUrl()}/offer/addOffer`,
        formData,
        {
          headers: {
            "Content-Type": "application/json ",
            Authorization: `Bearer ${cookieData.token}`,
          },
        }
      );

      if (response.data.error) {
        console.error("Error uploading video:", response.data.message);
      } else {
        // Video uploaded successfully, update the video list
        dispatch(
          updateHelper({
            globalToast: {
              show: true,
              type: "success",
              message: "Offer updated Successfully!",
            },
          })
        );
      }
    } catch (error) {
      console.error("Error uploading video:", error);
    }
  };
  return (
    <div className="relative text-white z-10  min-h-[100vh] backdrop-brightness-50">
      <div>
        <Sidebar page="dashboard" />
      </div>
      <div className="ml-[50px] md:ml-[90px] p-2 md:p-5 flex flex-col lg:flex-row gap-2 px-auto max-lg:divide-y  lg:divide-x">
        {/* left side */}

        <div className="flex flex-col lg:w-[50%]">
          <form
            action="/action_page.php"
            className="flex flex-col gap-1 p-2 lg:w-[70%] max-md:flex-col "
          >
            <label htmlFor="Upload Offer" className=" max-md:w-full">
              Upload Offer:
            </label>
            <input
              type="text"
              className="offer-input-style bg-[#d1d1d1]  text-black"
              name="Upload Offer"
              value={offer}
              onChange={(e) => {
                setOffer(e.target.value);
              }}
            />
          </form>
          <button
            // className="bg-[#f66b12] p-[10px] px-[36px] rounded-lg font-semibold "
            className="bg-[#f66b12] p-[10px] px-[36px] rounded-lg font-semibold w-[150px] m-3"
            onClick={handleOffer}
          >
            Upload
          </button>
          <hr className="mt-20 " color="#4B5563" />

          <div className="p-3">
            <h3>
              Upload HIghlights{" "}
              <span className="text-[#4B5563]">Minimum 10 in 24 hours</span>
            </h3>
            <Highlights />
          </div>
        </div>

        {/* right side */}
        <div className="p-10 max-lg:p-3">
          <h3>
            Upload Reels{" "}
            <span className="text-[#4B5563]">Minimum 10 in 24 hours</span>
          </h3>
          <Reel />
        </div>
      </div>
    </div>
  );
};

export default Dashboard2;
