import React from "react";
import Navbar from "../components/Navbar";

export default function Term() {
  return (
    <>
      <div className="min-h-[100vh] backdrop-brightness-65">
        <Navbar pages="information" navText={"Terms and Conditions "} />

        <div className="w-[90%] m-auto">
          <div className="pt-10 mb-10">
            <p className="text-white pb-10 ">
              We are VKG AND RS SOLUTION TECH PVT LTD ("Unknown Bazaar," "we,"
              "us," "our"). We operate the website www.unknownbazaar.com (the
              ‘Site’) the mobile application Unknown Bazaar( the ‘App’) , as
              well as any other related products and services that refer or link
              to these legal terms (the "Legal Terms") (collectively, the
              "Services"). <br /> <br />
              You can contact us by email at <span></span>
              <a
                className="text-[#F46A11] font-semibold"
                href="mailto:connect@unknownbazaar.com"
              >
                connect@unknownbazaar.com{" "}
              </a>{" "}
              <br />
              <br /> These Legal Terms constitute a legally binding agreement
              made between you, whether personally or on behalf of an entity
              ("you"), or VKG AND RS SOLUTION TECH PVT LTD, concerning your
              access to and use of the Services. You agree that by accessing the
              Services, you have read, understood, and agreed to be bound by all
              of these Legal Terms. IF YOU DO NOT AGREE WITH ALL OF THESE LEGAL
              TERMS, THEN YOU ARE EXPRESSLY PROHIBITED FROM USING THE SERVICES
              AND YOU MUST DISCONTINUE USE IMMEDIATELY. <br /> <br />
              We will provide you with prior notice of any scheduled changes to
              the Services you are using. The modified Legal Terms will become
              effective upon posting or notifying you by <span></span>
              <a
                className="text-[#F46A11] font-semibold"
                href="mailto:connect@unknownbazaar.com"
              >
                connect@unknownbazaar.com{" "}
              </a>
              , as stated in the email message. By continuing to use the
              Services after the effective date of any changes, you agree to be
              bound by the modified terms. <br /> <br /> Definitions <br />
              Seller(s)": Merchants, retailers, or individuals who use the
              Platform to list and sell products or services to Users. <br />
              <br /> Account Creation and Eligibility <br /> Seller Accounts:
              Sellers must register and create an account to list products or
              services on the Platform. By registering as a Seller, you
              represent that you have the authority to sell the listed products
              or services and agree to comply with our Seller Terms and
              Conditions. <br />
              <br /> Seller Responsibilities <br /> a. Product Listings: Sellers
              are responsible for the accuracy and completeness of product or
              service listings. All listed products or services must comply with
              applicable laws and regulations. <br /> b. Pricing and Payment:
              Sellers are responsible for setting accurate prices for their
              products or services and for providing precise payment and
              shipping information. <br /> c. Order Fulfilment: Sellers are
              responsible for fulfilling orders promptly and providing accurate
              shipping and delivery information. <br /> <br />
              Intellectual Property <br /> Seller Product Images: Sellers grant
              Unknown Bazaar a non-exclusive, royalty-free, perpetual,
              irrevocable, and fully sublicensable right to use, reproduce,
              modify, adapt, publish, translate, create derivative works from,
              distribute, and display product images worldwide for promotional
              purposes on the Platform. <br /> <br /> Privacy <br /> Seller
              Privacy: The collection and use of personal information of Sellers
              are governed by our Privacy Policy. By registering as a Seller on
              the Platform, you consent to the practices described in the
              Privacy Policy. <br /> Payment details: When you purchase a
              subscription, you must provide us with complete and accurate
              payment information. By submitting payment details you promise
              that you are entitled to purchase a subscription using those
              payment details. If we do not receive payment authorization or any
              authorization is subsequently cancelled, we may immediately
              terminate or suspend your access to your subscription. In
              suspicious circumstances, we may contact the issuing bank/payment
              provider and/or law enforcement authorities or other appropriate
              third parties. If you are entitled to a refund under these terms
              and conditions we will credit that refund to the card or other
              payment method you used to submit payment, unless it has expired
              in which case we will contact you. Business Standard will use the
              services of quality third-party payment service providers to
              process your payment. <br /> Business Standard may offer other
              payment mechanisms from time to time. <br /> <br /> Subscription &
              Pricing: For Digital subscriptions by placing your order, you
              agree that we may start your subscription immediately upon our
              accepting your order. This means that you are not entitled to a
              refund if you change your mind after we have provided you with
              access to your subscription. <br /> Annual Subscription: By
              purchasing an Annual Subscription, you agree to an initial
              pre-payment for one full year of service. After one year and
              annually thereafter, you will be billed a recurring Annual
              Subscription renewal fee at the end-current Annual Subscription
              rate. We will notify you before the renewal fee is billed. You may
              cancel your Annual Subscription anytime before the next billing
              cycle, subject to the terms of our cancellation policy. <br />{" "}
              <br />
              AUTOMATIC ANNUAL RENEWAL TERMS: We bill your credit card for the
              Annual Subscription renewal fee in the first month of your next
              Annual Subscription. <br /> For example, let's say you purchased
              an Annual Subscription beginning in October 2023 and ending in
              September 2024. We will bill you for the second year (October 2024
              to September 2025) in October 2024, unless you cancel your Annual
              Subscription before October 2024. You accept responsibility for
              all recurring charges prior to cancellation. Once you subscribe,
              Razor Pay or other payment gateways will automatically process
              your Annual Subscription fee at the then-current Annual
              Subscription rate. We explain how to cancel your Annual
              Subscription below in the Section "Cancellation Policy". <br />{" "}
              You do not have any right to cancel your subscription or any part
              of it until the end of your then-current subscription period.{" "}
              <br /> Although you may notify us of your intention to cancel at
              any time, such notice will only take effect at the end of your
              then-current subscription period, and you will not receive a
              refund <br /> <br /> Cancellation Policy - How to cancel? You may
              notify us of your wish to cancel your subscription by contacting
              our Customer Services team at <span></span>{" "}
              <a
                className="text-[#F46A11] font-semibold"
                href="mailto:connect@unknownbazaar.com"
              >
                connect@unknownbazaar.com
              </a>
              To enable us to cancel your subscription please provide us with
              your complete name (as was in the subscription order form), Email
              address, and Contact number clearly written out. <br /> <br />{" "}
              Legal Policy <br /> All matters arising out of or relating to this
              Agreement are governed by and construed in accordance with the
              internal laws of the District of Mumbai, State of Maharashtra
              without giving effect to any choice or conflict of law provision
              or rule (whether of the District of Mumbai, State of Maharashtra
              or any other jurisdiction) that would cause the application of the
              laws of any jurisdiction other than those of the District of
              Mumbai, State of Maharashtra.
              <br />
              <br />
              Refund Policy
              <br />
              Subscription fees are non-refundable; except that if you are not a
              homegrown brand and have still applied for a listing your account
              will be closed post-QC and money will be refunded within 7-10
              working days.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
