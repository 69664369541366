// bannerSlice.js
import { createSlice } from "@reduxjs/toolkit";
import { fetchBannerImages } from "./bannerAction";

const bannerImageOfferSlice = createSlice({
  name: "bannerImageOffer",
  initialState: {
    bannerImages: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchBannerImages.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchBannerImages.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.bannerImages = action.payload;
      })
      .addCase(fetchBannerImages.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default bannerImageOfferSlice.reducer;
