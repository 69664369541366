import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FiEdit } from "react-icons/fi";
import { AiOutlinePlus } from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";
import { fetchBlogTitles } from "../features/phone/blog/blogAction";
import Cookies from "js-cookie";
import { apiCall, getSellerId, getToken, isArrayWithValues } from "../helper";
import { MdDelete } from "react-icons/md";
import { IconButton, Stack } from "@mui/material";
import { updateHelper } from "../features/helper/helperSlice";
import { updateStateWithPayload } from "../features/phone/blog/blogSlice";

const Blog = () => {
  const [datas, setDatas] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const sellerId = getSellerId();

  useEffect(() => {
    dispatch(fetchBlogTitles(sellerId)); // Send sellerId in the request body
  }, [dispatch, sellerId]);

  const blogTitles = useSelector((state) => state?.blogTitle?.blogTitles);

  console.log(blogTitles);
  const deleteBlog = async (id) => {
    dispatch(
      updateHelper({
        globalToast: {
          show: true,
          message: "Blog Removed Successfully!",
          type: "success",
        },
      })
    );
    setDatas((pre) => pre?.filter((o) => o?.id != id));
    try {
      let res = await apiCall({
        endpoint: "blog/deleteBlog",
        method: "POST",
        data: {
          blogId: id,
        },
      });
    } catch (error) {
      dispatch(
        updateHelper({
          globalToast: {
            show: true,
            message: "Can't able to delete Blog",
            type: "error",
          },
        })
      );
    }
  };
  useEffect(() => {
    if (isArrayWithValues(blogTitles)) setDatas(blogTitles || []);

    return () => {};
  }, [blogTitles]);

  return (
    <>
      <div className="blogs-body">
        <h4 className="heading-review"> Upload Blogs: </h4>

        {/* linking to actual blog -update and write page  */}
        <div className="new-blog-add-style">
          <Link to="/new-blog">
            <label className="add-image-label flex-col">
              <span className="add-image-icon ">
                <AiOutlinePlus />
              </span>
              <p className="text-sm text-[#F66B12] font-bold">Add Blog</p>
            </label>
          </Link>
        </div>

        {/* linking to actual blog -update and write page  */}

        <div className="blogs-read">
          <h4 className="heading-review py-5 "> BLOG list </h4>
          <div className="blogs-list">
            {datas?.length === 0 ? (
              <p className="text-slate-400 font-semibold text-xl w-full">
                No blog available
              </p>
            ) : (
              datas?.map((title) => {
                return (
                  <p
                    key={title.id}
                    className="blog-title-style"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    {title.title}{" "}
                    <Stack direction={"row"} alignItems={"center"} gap={2}>
                      <IconButton
                        onClick={() => {
                          deleteBlog(title?.id);
                        }}
                      >
                        <MdDelete className="edit-button-style" size={"30px"} />
                      </IconButton>
                      <FiEdit
                        size={"30px"}
                        onClick={() => navigate(`/blog/${title?.id}`)}
                        className="edit-button-style"
                      />
                    </Stack>
                  </p>
                );
              })
            )}

            {/* <p className="blog-title-style">
                            Lorem ipsum dolor sit amet consectetur, adipisicing elit. <FiEdit size={"30px"} className='edit-button-style' />
                        </p>
                        <p className="blog-title-style">
                            Lorem ipsum dolor sit amet consectetur, adipisicing elit. <FiEdit size={"30px"} className='edit-button-style' />
                        </p>
                        <p className="blog-title-style">
                            Lorem ipsum dolor sit amet consectetur, adipisicing elit. <FiEdit size={"30px"} className='edit-button-style' />
                        </p> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Blog;
