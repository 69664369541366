import React, { useState, useEffect } from "react";
import Navbar from "../components/Navbar";
import "../css/navbar.css";
import "../css/forms.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import ReloadModal from "../components/Modal";
import useRazorpay from "react-razorpay";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { apiCall, getSellerId } from "../helper";
import { updateHelper } from "../features/helper/helperSlice";

const SelectPlan = () => {
  let { info } = useParams();
  const dispatch = useDispatch();
  const user = useSelector((state) => state?.auth?.user);

  const [showModal, setShowModal] = useState(false);

  // useEffect(() => {
  //   // Show the modal when the component mounts
  //   setTimeout(() => {
  //     !info && setShowModal(true);
  //   }, 3000);
  // }, []);

  const navigate = useNavigate();

  const handleRedirect = () => {
    navigate("/facts");
    setShowModal(false);
  };
  // const [Razorpay] = useRazorpay();

  const handlePayment = async ({ duration, amount }) => {
    try {
      let sellerId = getSellerId();
      console.log(sellerId, user);
      let { sellerName, phoneNumber, email } = user || {};
      let paymentRes = await apiCall({
        endpoint: "order",
        method: "POST",
        data: {
          // duration,
          amount,
          // sellerId,
        },
      });
      console.log(paymentRes);

      // const { data: order } = await axios({
      //   method: "POST",
      //   data: {
      //     amount: 500, // amount in smallest currency unit
      //     currency: "INR",
      //     receipt: "receipt_order_74395",
      //   },
      //   url: "http://localhost:5000/orders",
      // });

      //  Create order on your backend

      // console.log(order);
      const options = {
        key: "rzp_live_fLnvgJdH58GH0J", // Enter the Key ID generated from the Dashboard
        amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
        currency: "INR",
        // name: "Acme Corp",
        // description: "Test Transaction",
        // image: "https://example.com/your_logo",
        order_id: paymentRes?.data?.id, //This is a sample Order ID. Pass the `id` obtained in the response of createOrder().
        handler: async function (response) {
          await apiCall({
            endpoint: "confirmOrder",
            method: "POST",
            data: {
              duration,
              sellerId,
            },
          });
          dispatch(
            updateHelper({
              globalToast: {
                show: true,
                message: "Payment done Successfully!",
                type: "success",
              },
            })
          );
          // dispatch()
          navigate("/facts");

          // alert(response.razorpay_payment_id);
          // alert(response.razorpay_order_id);
          // alert(response.razorpay_signature);
        },
        // prefill: {
        //   name: sellerName,
        //   email: email,
        //   contact: phoneNumber,
        // },
        // notes: {
        //   address: "Razorpay Corporate Office",
        // },
        // theme: {
        //   color: "#3399cc",
        // },
      };

      const rzp1 = new window.Razorpay(options);

      rzp1.on("payment.failed", function (response) {
        console.log(response);
        // alert(response.error.code);
        alert(response.error.description);
        // alert(response.error.source);
        // alert(response.error.step);
        // alert(response.error.reason);
        // alert(response.error.metadata.order_id);
        // alert(response.error.metadata.payment_id);
      });

      rzp1.open();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div
        className="
    
      min-h-[100vh]"
      >
        {/*   relative text-white z-10  backdrop-brightness-50   */}
        <Navbar pages="information" navText="Packages and Pricing" />
        <body className="info-body bgi ">
          <div className="info-container select-plan">
            <div className="child radio-column">
              <div className="radio-body">
                <div className="radio-container">
                  <label
                    className="radio-label"
                    onClick={() =>
                      info && navigate("/setting/company-information")
                    }
                  >
                    <input
                      type="radio"
                      name="option"
                      className="custom-radio-btn"
                      disabled
                    />
                    Company Information
                  </label>
                  <div className="vertical-line"></div>
                  <label
                    className="radio-label"
                    onClick={() =>
                      info && navigate("/setting/basic-information")
                    }
                  >
                    <input
                      type="radio"
                      name="option"
                      className="custom-radio-btn"
                      disabled
                    />
                    Basic Information
                  </label>
                  <div className="vertical-line"></div>
                  <label
                    className="radio-label"
                    onClick={() => info && navigate("/setting/select-plan")}
                  >
                    <input
                      type="radio"
                      name="option"
                      className="custom-radio-btn"
                      checked
                      style={{ accentColor: "#f66b12" }}
                    />
                    {info ? "Active" : "Select"} Plan
                  </label>
                  <div className="vertical-line"></div>
                  <label
                    className="radio-label"
                    onClick={() => info && navigate("/setting/facts")}
                  >
                    <input
                      type="radio"
                      name="option"
                      className="custom-radio-btn"
                      disabled
                    />
                    Facts
                  </label>
                </div>
              </div>
            </div>
            <div className="child form-child select-plan-center">
              <div class="pricing">
                <div class="plan">
                  <h2>6 Months</h2>
                  <hr />
                  <div class="price">
                    ₹2199*/<span className="months">month</span>
                  </div>
                  <div className="offers">
                    <p>
                      {" "}
                      <span className="offers-text">
                        Introductory Offer: Double the time
                      </span>{" "}
                      <br />
                      Pay for 3 Months get 6 months
                    </p>
                  </div>
                  <h4>
                    Billed 6 months <br />
                    <span className="offers-price"> ₹ 13194 =</span>{" "}
                    <span className="offers-price-actual">₹6597*</span>
                  </h4>
                  <ul class="features">
                    <li>
                      <i class="fas fa-check"></i> Categorised Brand Listing{" "}
                    </li>
                    <li>
                      <i class="fas fa-check"></i> Direct User Connect. No
                      Mediator
                    </li>
                    <li>
                      <i class="fas fa-check"></i> Targeted Audience{" "}
                    </li>
                    <li>
                      <i class="fas fa-check"></i> Backlinking for SEO{" "}
                    </li>
                    <li>
                      <i class="fas fa-check"></i> UB Verified{" "}
                    </li>
                    <li>
                      <i class="fas fa-check"></i> Increased Brand Visibility{" "}
                    </li>
                    <li>
                      <i class="fas fa-check"></i> Unique brand positioning{" "}
                    </li>
                    <li>
                      <i class="fas fa-check"></i> Data-driven insights{" "}
                    </li>
                    <li>
                      <i class="fas fa-check"></i> Access to new customers{" "}
                    </li>
                    <li>
                      <i class="fas fa-check"></i> UB Customer Support{" "}
                    </li>
                    <li>
                      <i class="fas fa-check"></i> 0% Commission = Higher
                      Margins{" "}
                    </li>
                  </ul>
                  {info ? null : (
                    // <Link to="/facts">
                    <button
                      className="input-button1"
                      onClick={() =>
                        handlePayment({
                          amount: 659700,
                          duration: 6,
                        })
                      }
                    >
                      Get Started
                    </button>
                    // </Link>
                  )}
                </div>
                {info ? null : (
                  <>
                    <div class="plan">
                      <h2>9 Months</h2>
                      <hr />
                      <div class="price">
                        ₹1899*/<span className="months">month</span>
                      </div>
                      <div className="offers">
                        <p>
                          {" "}
                          <span className="offers-text">
                            Introductory Offer: Double the time{" "}
                          </span>{" "}
                          <br />
                          Pay for 4.5 Months get 9 months
                        </p>
                      </div>
                      <h4>
                        Billed 9 months <br />
                        <span className="offers-price"> ₹ 17091 =</span>{" "}
                        <span className="offers-price-actual">₹8546*</span>
                      </h4>
                      <ul class="features">
                        <li>
                          <i class="fas fa-check"></i> Categorised Brand Listing{" "}
                        </li>
                        <li>
                          <i class="fas fa-check"></i> Direct User Connect. No
                          Mediator
                        </li>
                        <li>
                          <i class="fas fa-check"></i> Targeted Audience{" "}
                        </li>
                        <li>
                          <i class="fas fa-check"></i> Backlinking for SEO{" "}
                        </li>
                        <li>
                          <i class="fas fa-check"></i> UB Verified{" "}
                        </li>
                        <li>
                          <i class="fas fa-check"></i> Increased Brand
                          Visibility{" "}
                        </li>
                        <li>
                          <i class="fas fa-check"></i> Unique brand positioning{" "}
                        </li>
                        <li>
                          <i class="fas fa-check"></i> Data-driven insights{" "}
                        </li>
                        <li>
                          <i class="fas fa-check"></i> Access to new customers{" "}
                        </li>
                        <li>
                          <i class="fas fa-check"></i> UB Customer Support{" "}
                        </li>
                        <li>
                          <i class="fas fa-check"></i> Featured in new letters{" "}
                        </li>
                        <li>
                          <i class="fas fa-check"></i> 0% Commission = Higher
                          Margins{" "}
                        </li>
                      </ul>
                      <button
                        className="input-button1"
                        onClick={() =>
                          handlePayment({
                            amount: 854600,
                            duration: 9,
                          })
                        }
                      >
                        Get Started
                      </button>
                    </div>

                    <div class="plan popular ">
                      <span className="popular-span">Most Popular</span>
                      <h2>12 Months</h2>
                      <hr />
                      <div class="price">
                        ₹1499*/<span className="months">month</span>
                      </div>
                      <div className="offers">
                        <p>
                          {" "}
                          <span className="offers-text">
                            Introductory Offer: Double the time
                          </span>{" "}
                          <br />
                          Pay for 6 Months get 12 months
                        </p>
                      </div>
                      <h4>
                        Billed 12 months <br />
                        <span className="offers-price"> ₹ 17988 =</span>{" "}
                        <span className="offers-price-actual">₹8994*</span>
                      </h4>
                      <ul class="features">
                        <li>
                          <i class="fas fa-check"></i> Categorised Brand Listing{" "}
                        </li>
                        <li>
                          <i class="fas fa-check"></i> Direct User Connect. No
                          Mediator
                        </li>
                        <li>
                          <i class="fas fa-check"></i> Targeted Audience{" "}
                        </li>
                        <li>
                          <i class="fas fa-check"></i> Backlinking for SEO{" "}
                        </li>
                        <li>
                          <i class="fas fa-check"></i> UB Verified{" "}
                        </li>
                        <li>
                          <i class="fas fa-check"></i> Increased Brand
                          Visibility{" "}
                        </li>
                        <li>
                          <i class="fas fa-check"></i> Unique brand positioning{" "}
                        </li>
                        <li>
                          <i class="fas fa-check"></i> Data-driven insights{" "}
                        </li>
                        <li>
                          <i class="fas fa-check"></i> Access to new customers{" "}
                        </li>
                        <li>
                          <i class="fas fa-check"></i> UB Customer Support{" "}
                        </li>
                        <li>
                          <i class="fas fa-check"></i> Featured in new letters{" "}
                        </li>
                        <li>
                          <i class="fas fa-check"></i> Marketing Guidance{" "}
                        </li>
                        <li>
                          <i class="fas fa-check"></i> Feature in UB Blogs: SEO
                          support{" "}
                        </li>
                        <li>
                          <i class="fas fa-check"></i> 0% Commission = Higher
                          Margins{" "}
                        </li>
                        <li>
                          <i class="fas fa-check"></i> Benefit from UB's Social:{" "}
                          <br /> Organic & paid marketing campaigns{" "}
                        </li>
                      </ul>
                      <button
                        className="input-button1"
                        onClick={() =>
                          handlePayment({
                            amount: 899400,
                            duration: 12,
                          })
                        }
                      >
                        Get Started
                      </button>
                    </div>
                  </>
                )}
              </div>
              <h2 className="form-heading">
                To Upgrade/ change your plan please reach out{" "}
                <a
                  style={{ color: "#f66b12" }}
                  href={`mailto:${"connect@unknownbazaar.com"}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  // href="connect@unknownbazaar.com"
                >
                  connect@unknownbazaar.com
                </a>
              </h2>
            </div>
          </div>
        </body>
      </div>
      {/* Display the modal */}
      <ReloadModal
        open={showModal}
        onRedirect={handleRedirect}
        onClose={() => setShowModal(false)}
      />
    </>
  );
};

export default SelectPlan;
