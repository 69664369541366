import React from "react";
import Sidebar from "../components/Sidebar";
import Review from "../components/Review";
import "../css/Phone.css";
import Blog from "../components/Blog";
import Banner from "../components/Banner";

const Phone = () => {
  return (
    <div className="relative text-white z-10   min-h-[100vh] backdrop-brightness-50">
      <div>
        <Sidebar page="phone" />
      </div>
      <div>
        <Review />
        <hr />
      </div>
      <div className="phone-blog-banners ml-[50px] md:ml-[90px] p-2 md:p-5 gap-2 px-auto ">
        <div className="child w-[45%]">
          <Blog />
        </div>
        <hr className="show-tab-mob" />
        <div className="child">
          <Banner />
        </div>
      </div>
    </div>
  );
};

export default Phone;
