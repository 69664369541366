import React, { useState } from "react";
import logo from "../images/logopng.png";
import { BiSolidDashboard } from "react-icons/bi";
import { HiDevicePhoneMobile } from "react-icons/hi2";
import { VscGraph } from "react-icons/vsc";
import { TbMessage } from "react-icons/tb";
import { IoSettingsSharp } from "react-icons/io5";
import { BsFillQuestionCircleFill } from "react-icons/bs";
import { ImSwitch } from "react-icons/im";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { updateUser } from "../features/auth/userSlice";
import { spreadUserData } from "../features/auth/authSlice";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
const Sidebar = (props) => {
  const [activeIcon, setActiveIcon] = useState(props.page);
  const [openPopup, setOpenPopup] = useState(false);
  const handleClose = () => {
    setOpenPopup(false);
  };
  const handleIconClick = (iconName) => {
    setActiveIcon(iconName);
  };
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isIconActive = (iconName) => {
    return activeIcon === iconName ? "#f66b12" : "white";
  };
  return (
    <div className="w-[6%] w-max-[10%] h-[100%] md:h-full fixed bg-gray-600 z-1 flex flex-col text-white  items-center p-5 justify-between">
      <div className="flex flex-col gap-8">
        <Link to="/home">
          <img src={logo} alt="" width={28} className="cursor-pointer" />
        </Link>
        <Link to="/dashboard">
          <BiSolidDashboard
            onClick={() => handleIconClick("dashboard")}
            size={22}
            style={{ color: isIconActive("dashboard") }}
          />
        </Link>

        <Link to="/phone">
          <HiDevicePhoneMobile
            onClick={() => handleIconClick("phone")}
            size={22}
            style={{ color: isIconActive("phone") }}
          />
        </Link>

        <Link to="/graph">
          <VscGraph
            onClick={() => handleIconClick("graph")}
            size={22}
            style={{ color: isIconActive("graph") }}
          />
        </Link>
      </div>

      <div className="flex flex-col gap-8">
        <Link to="/setting/company-information">
          <IoSettingsSharp
            onClick={() => handleIconClick("settings")}
            size={22}
            style={{ color: isIconActive("settings") }}
          />
        </Link>

        <Link to="/question">
          <BsFillQuestionCircleFill
            onClick={() => handleIconClick("question")}
            size={22}
            style={{ color: isIconActive("question") }}
          />
        </Link>

        <Link>
          <ImSwitch
            onClick={() => {
              handleIconClick("switch");
              setOpenPopup(true);
            }}
            size={22}
            className={`text-[${isIconActive("switch")}] cursor-pointer`}
          />
        </Link>
      </div>
      <Dialog
        open={openPopup}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Are you Sure you want to log out ?
        </DialogTitle>
        <DialogContent>
          {/* <DialogContentText id="alert-dialog-description">
            Let Google help apps determine location. This means sending anonymous
            location data to Google, even when no apps are running.
          </DialogContentText> */}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              handleClose();
              dispatch(updateUser({ user: {} }));
              dispatch(spreadUserData({ user: {} }));
              navigate("/join-us");
            }}
            autoFocus
          >
            Log out
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Sidebar;
