import React from "react";

const Download = () => {
    return (
        <>
            <h4 className="text-white text-[20px] font-semibold mb-[10px]">
                Content Specification Reference Document : 
                <a
                    class="faq-link"
                    href="https://drive.google.com/file/d/1-C4OIYsJGkPhrhKA60o5M3vNo_g6rWXU/view"
                    download="Content-Checklist"
                    target="_blank"
                    rel="noreferrer"
                >
                   <span className="p-1" >Download here</span> 
                </a>
            </h4>
        </>
    );
};

export default Download;
