import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import Cookies from "js-cookie";
import { getToken, getUrl } from "../../../helper";

// Define the async thunk for fetching banner
export const fetchBannerImages = createAsyncThunk(
  "banner/fetchBannerImages",
  async (sellerId, { rejectWithValue }) => {
    // const cookieData = JSON.parse(Cookies.get("userData"));
    // console.log(cookieData.token);
    // const token = cookieData.token;
    const token = getToken();
    const baseURL = getUrl();

    try {
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      const response = await axios.post(
        `${baseURL}/banner/getBannerBySellerId`,
        { sellerId },
        {
          headers: headers,
        }
      );

      console.log(response);
      if (response.status !== 200) {
        return rejectWithValue("Failed to fetch reviews");
      }
      return response.data.data;
    } catch (error) {
      // Handle errors here
      console.error("Error fetching banner images:", error);
      return rejectWithValue(error.message);
    }
  }
);

// Async Thunk action for uploading the banner image
export const uploadBannerImage = createAsyncThunk(
  "banner/uploadBannerImage",
  async ({ sellerId, imageFile }, { dispatch, rejectWithValue }) => {
    const cookieData = JSON.parse(Cookies.get("userData"));
    // console.log(cookieData.token);
    const token = cookieData.token;
    let baseURL = getUrl();
    try {
      // Create a FormData object to send the image file
      const formData = new FormData();
      formData.append("sellerId", sellerId);
      formData.append("bannerImage", imageFile);

      // Make the API request to upload the image
      const response = await axios.post(
        `${baseURL}/api/uploadBanner`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      console.log(response.data);

      // Dispatch a success action
      dispatch(setBlogData(response.data));
      console.log(response.data);

      return response.data;
    } catch (error) {
      // Handle errors and dispatch a failure action if needed
      console.error("Error uploading banner image:", error);
      return rejectWithValue(error.message);
    }
  }
);

const bannerSlice = createSlice({
  name: "banner",
  initialState: {
    // Your initial state here
    bannerData: null,
    error: null,
    loading: false,
    bannerUploaded: false,
  },
  reducers: {
    setBlogData: (state, action) => {
      state.blogData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(uploadBannerImage.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(uploadBannerImage.fulfilled, (state, action) => {
        state.loading = false;
        state.bannerUploaded = true; // Set a flag to indicate successful upload
        state.error = false;
      })
      .addCase(uploadBannerImage.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload; // Set an error message
      });
  },
});

export const { setBlogData } = bannerSlice.actions;
export default bannerSlice.reducer;
