import React from "react";
import "../css/navbar.css";
import "../css/home.css";
import Navbar from "../components/Navbar";
import { Link } from "react-router-dom";

const Introduction = () => {
  return (
    <>
      <Navbar pages="home" navText="" />
      <div className="min-h-[80vh] backdrop-brightness-50  ">
        <div className="intro-body ">
          <div className="intro-container"></div>
          <div className="intro-ub">
            <p className="main-heading">Showcase the power of homegrown.</p>
            <p className="intro-subheading">
              Maximise your reach |
              <span className="span-style"> Increase your visibilty</span> | Reach
              more consumers directly
            </p>
            <p className="intro-subheading style1">
              <span className="span-style">Pay Zero Commission</span> | Build your
              business | Focus on what actually matters
            </p>
            <p className="intro-last max-md:">
              Embrace the power of Desi, join the UB clan & experience effortless
              growth.
            </p>
            <div className="input-button">
              <Link to="/login">
                {/* <Link to="/register"> */}
                <button type="button" className="buttonStyle1">
                  Register Now
                  {/* Sign in */}
                </button>
              </Link>
              <p className="user" style={{ fontSize: "20px" }}>
                {" "}
                <Link to="/login">/ Existing User Log In</Link>{" "}
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Introduction;
