import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";

const FAQ = () => {
    const faqData = [
        {
            question:
                "How can I submit my homegrown brand to be included on Unknown Bazaar?",
            answer:
                "You can check our seller’s portal <a class='faq-link text-orange-500 hover:text-[#F46A11] underline' href='https://www.seller.unknownbazaar/landing.com'>https://www.unknownbazaarseller/landing.com</a> and sign up on the platform directly.",
        },
        {
            question:
                "Is there a fee associated with listing my brand on Unknown Bazaar?",
            answer:
                "Yes, we have fees associated with listing your brand on our directory. We offer different pricing options based on the plan chosen. Each plan has respective inclusions in it. For More details you can visit <a class='faq-link' href='https://www.seller.unknownbazaar/landing.com'>here</a>.",
        },
        {
            question: "How often is the brand directory updated with new brands?",
            answer:
                " We update the brand directory on a daily basis to ensure that users have access to the latest and most diverse selection of homegrown brands.",
        },
        {
            question:
                "Are there any verified badges or certifications for the brands in the directory?",
            answer:
                'Yes, we encourage brands to provide any relevant badges or certifications they have received. Look for the "Verified" badges on brand profiles.',
        },
        {
            question:
                "How does your brand directory help homegrown brands reach a wider audience? ",
            answer:
                " We reach out to targeted audiences with a fresh advertising approach with a mix of online & offline channels. Which helps the brand with a diverse array of consumers under one roof at a fraction of a price.",
        },
        {
            question: "Why do I list myself on your platform?What are the benefits?",
            answer:
                "Listing on Unknown Bazaar means more reach, increased traffic, more brand visibility, improved brand recognition, networking opportunities. Once listed on UB, it allows you to showcase your USP’s prominently in return differentiating your brand from other competitors.",
        },
        {
            question: "How are you different?        ",
            answer:
                "Unknown Bazaar simplifies the browsing & window shopping experience. It provides a user friendly & unique platform that promotes homegrown brands exclusively. It is commission-free, more visibility in a less competitive environment.        ",
        },
        {
            question:
                "Can I request specific categories or tags to be associated with my brand in the directory?",
            answer:
                "Certainly! You have the flexibility to showcase your brand in up to two categories on Unknown Bazaar brand directory",
        },
        {
            question: "Can I join on a trial basis?",
            answer:
                " We are offering sign-ups for a very pocket-friendly price as low as ₹24 per day, we have 6-month, 9-month and 12-month subscription plans in which we provide you with branding, marketing and advertising under one umbrella.",
        },
        {
            question: "Can you provide statistics or insights on the directory's audience or user base?",
            answer:
                "Yes. Audience insight will be shared on your seller account dashboard. The same will help you monitor your growth. Marketing specialists are available when you list for 12 months, they can help you with any questions or queries.",
        },
        {
            question: "Are there any restrictions or limitations on the types of brands accepted in the directory?",
            answer:
                " Yes, the brand needs to be homegrown/ made in India or assembled in India. ",
        },
        {
            question: "Can I include promotional offers or discounts in my brand listing?",
            answer:
                " Yes, you can include & create a promotional code for the users. ",
        },
        {
            question: "Are there any restrictions on the number of categories the brand can be listed under?",
            answer:
                " Yes, one brand can be registered under 2 categories.",
        },
    ];
    return (
        <>
            {faqData.map((faqItem, index) => (
                <Accordion key={index}>
                    <AccordionSummary
                        aria-controls={`panel${index + 1}-content`}
                        id={`panel${index + 1}-header`}
                    >
                        <Typography>{faqItem.question}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography dangerouslySetInnerHTML={{ __html: faqItem.answer }} />
                    </AccordionDetails>
                </Accordion>
            ))}
        </>
    );
};

export default FAQ;
