import React, { useEffect, useState } from "react";
import Hero from "../components/LandingPage/Hero";
import About from "../components/LandingPage/about";
import Reason from "../components/LandingPage/Reason";
import Pricing from "../components/LandingPage/Pricing";
import ContactForm from "../components/LandingPage/ContactForm";
import Benefit from "../components/LandingPage/Benefit";
import ReloadModal from "../components/Modal";

const LandingPage = () => {
  const [showModal, setShowModal] = useState(false);
  useEffect(() => {
    // Show the modal when the component mounts
    setTimeout(() => {
      setShowModal(true);
    }, 5000);
  }, []);
  const handleRedirect = () => {
    // navigate("/facts");
    setShowModal(false);
  };
  return (
    <>
      <div className=" min-h-[100vh] backdrop-brightness-75 ">
        <Hero />
        <About />
        <Benefit />
        <Reason />
        <Pricing />
        <ContactForm />
        {/* <ReloadModal
          link={"/landing-page"}
          open={showModal}
          onRedirect={handleRedirect}
          onClose={handleRedirect}
        /> */}
      </div>
    </>
  );
};

export default LandingPage;
