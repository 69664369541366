import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import Cookies from "js-cookie";
import { getSellerId, getToken, getUrl } from "../../../helper";

// const cookieData = JSON.parse(Cookies.get("userData") || null);
// console.log(cookieData.token);
// const token = cookieData?.token;

export const createBlog = createAsyncThunk(
  "blog/createBlog",
  async (data, { rejectWithValue }) => {
    let token = getToken();
    let baseURL = getUrl();
    try {
      const headers = {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      };
      console.log(data);
      const formData = new FormData();
      formData.append("title", data?.title);
      formData.append("description", data?.description);
      formData.append("sellerId", getSellerId());
      formData.append("image", data?.image);
      console.log(formData);
      const response = await axios.post(`${baseURL}/blog/addBlog`, formData, {
        headers: headers,
      });
      console.log(response.data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// show Blog title

// export const fetchBlogTitles = createAsyncThunk(
//   "blogTitles/fetchBlogTitles",
//   async (sellerId, { rejectWithValue }) => {
//     let token = getToken()
//     try {
//       const headers = {
//         "Content-Type": "application/json",
//         Authorization: `Bearer ${token}`,
//       };
//       // Make an API request to fetch data based on the sellerId in the request body
//       const response = await axios.post(
//         `${baseURL}/blog/getBlogBySellerId`,
//         { sellerId },
//         {
//           headers: headers,
//         }
//       );
//       if (response.status !== 200) {
//         return rejectWithValue("Failed to fetch reviews");
//       }

//       return response.data;
//     } catch (error) {
//       // Handle any error that occurs during the API request
//       return rejectWithValue(error.message);
//     }
//   }
// );

export const fetchBlogTitles = createAsyncThunk(
  "blog/fetchBlogTitles",
  async (sellerId, { dispatch, rejectWithValue }) => {
    // const cookieData = JSON.parse(Cookies.get("userData"));
    // console.log(cookieData.token);
    // const token = cookieData.token;
    let token = getToken();
    let baseURL = getUrl();
    try {
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      const response = await axios.post(
        `${baseURL}/blog/getBlogBySellerId`,
        { sellerId },
        {
          headers: headers,
        }
      );
      if (response.status !== 200) {
        return rejectWithValue("Failed to fetch reviews");
      } // Send sellerId in the request body
      console.log(response.data.data);
      return response.data.data; // Assuming that the blog titles are in response.data.data
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
