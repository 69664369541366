import React from "react";
import { Link } from "react-router-dom";

const Pricing = () => {
  return (
    <>
      <div>
        <h3 className="pricing-heading-text py-6 text-center ">Pricing</h3>
        <div className="child select-plan-center">
          <div class="pricing">
            <div class="plan popular">
              <span className="popular-span1">Per day cost ₹36.65*</span>
              <h2>6 Months</h2>
              <hr />
              <div class="price">
                ₹2199*/<span className="months">month</span>
              </div>
              <div className="offers">
                <p>
                  {" "}
                  <span className="offers-text">
                    Introductory Offer: Double the time
                  </span>{" "}
                  <br />
                  Pay for 3 Months get 6 months
                </p>
              </div>
              <h4>
                Billed 6 months <br />
                <span className="offers-price"> ₹ 13194 =</span>{" "}
                <span className="offers-price-actual">₹6597*</span>
              </h4>
              <ul class="features">
                <li>
                  <i class="fas fa-check"></i> Categorised Brand Listing{" "}
                </li>
                <li>
                  <i class="fas fa-check"></i> Direct User Connect. No Mediator
                </li>
                <li>
                  <i class="fas fa-check"></i> Targeted Audience{" "}
                </li>
                <li>
                  <i class="fas fa-check"></i> Backlinking for SEO{" "}
                </li>
                <li>
                  <i class="fas fa-check"></i> UB Verified{" "}
                </li>
                <li>
                  <i class="fas fa-check"></i> Increased Brand Visibility{" "}
                </li>
                <li>
                  <i class="fas fa-check"></i> Unique brand positioning{" "}
                </li>
                <li>
                  <i class="fas fa-check"></i> Data-driven insights{" "}
                </li>
                <li>
                  <i class="fas fa-check"></i> Access to new customers{" "}
                </li>
                <li>
                  <i class="fas fa-check"></i> UB Customer Support{" "}
                </li>
                <li>
                  <i class="fas fa-check"></i> 0% Commission = Higher Margins{" "}
                </li>
              </ul>
              <Link to="/">
                <button className="input-button1">Get Started</button>
              </Link>
            </div>
            <div class="plan popular">
              <span className="popular-span1">Per day cost ₹31.65*</span>
              <h2>9 Months</h2>
              <hr />
              <div class="price">
                ₹1899*/<span className="months">month</span>
              </div>
              <div className="offers">
                <p>
                  {" "}
                  <span className="offers-text">
                    Introductory Offer: Double the time{" "}
                  </span>{" "}
                  <br />
                  Pay for 4.5 Months get 9 months
                </p>
              </div>
              <h4>
                Billed 9 months <br />
                <span className="offers-price"> ₹ 17091 =</span>{" "}
                <span className="offers-price-actual">₹8546*</span>
              </h4>
              <ul class="features">
                <li>
                  <i class="fas fa-check"></i> Categorised Brand Listing{" "}
                </li>
                <li>
                  <i class="fas fa-check"></i> Direct User Connect. No Mediator
                </li>
                <li>
                  <i class="fas fa-check"></i> Targeted Audience{" "}
                </li>
                <li>
                  <i class="fas fa-check"></i> Backlinking for SEO{" "}
                </li>
                <li>
                  <i class="fas fa-check"></i> UB Verified{" "}
                </li>
                <li>
                  <i class="fas fa-check"></i> Increased Brand Visibility{" "}
                </li>
                <li>
                  <i class="fas fa-check"></i> Unique brand positioning{" "}
                </li>
                <li>
                  <i class="fas fa-check"></i> Data-driven insights{" "}
                </li>
                <li>
                  <i class="fas fa-check"></i> Access to new customers{" "}
                </li>
                <li>
                  <i class="fas fa-check"></i> UB Customer Support{" "}
                </li>
                <li>
                  <i class="fas fa-check"></i> Featured in new letters{" "}
                </li>
                <li>
                  <i class="fas fa-check"></i> 0% Commission = Higher Margins{" "}
                </li>
              </ul>
              <Link to="/">
                <button className="input-button1">Get Started</button>
              </Link>
            </div>

            <div class="plan popular ">
              <span className="popular-span1">Per day cost ₹24.64*</span>
              <h2>12 Months</h2>
              <hr />
              <div class="price">
                ₹1499*/<span className="months">month</span>
              </div>
              <div className="offers">
                <p>
                  {" "}
                  <span className="offers-text">
                    Introductory Offer: Double the time
                  </span>{" "}
                  <br />
                  Pay for 6 Months get 12 months
                </p>
              </div>
              <h4>
                Billed 12 months <br />
                <span className="offers-price"> ₹ 17988 =</span>{" "}
                <span className="offers-price-actual">₹8994*</span>
              </h4>
              <ul class="features">
                <li>
                  <i class="fas fa-check"></i> Categorised Brand Listing{" "}
                </li>
                <li>
                  <i class="fas fa-check"></i> Direct User Connect. No Mediator
                </li>
                <li>
                  <i class="fas fa-check"></i> Targeted Audience{" "}
                </li>
                <li>
                  <i class="fas fa-check"></i> Backlinking for SEO{" "}
                </li>
                <li>
                  <i class="fas fa-check"></i> UB Verified{" "}
                </li>
                <li>
                  <i class="fas fa-check"></i> Increased Brand Visibility{" "}
                </li>
                <li>
                  <i class="fas fa-check"></i> Unique brand positioning{" "}
                </li>
                <li>
                  <i class="fas fa-check"></i> Data-driven insights{" "}
                </li>
                <li>
                  <i class="fas fa-check"></i> Access to new customers{" "}
                </li>
                <li>
                  <i class="fas fa-check"></i> UB Customer Support{" "}
                </li>
                <li>
                  <i class="fas fa-check"></i> Featured in new letters{" "}
                </li>
                <li>
                  <i class="fas fa-check"></i> Marketing Guidance{" "}
                </li>
                <li>
                  <i class="fas fa-check"></i> Feature in UB Blogs: SEO support{" "}
                </li>
                <li>
                  <i class="fas fa-check"></i> 0% Commission = Higher Margins{" "}
                </li>
                <li>
                  <i class="fas fa-check"></i> Benefit from UB's Social: <br />{" "}
                  Organic & paid marketing campaigns{" "}
                </li>
              </ul>
              <Link to="/">
                <button className="input-button1">Get Started</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Pricing;
